import React, { useState } from 'react';
import NovaAnalise from './NovaAnalise';
import NovoItemAnalise from './NovoItemAnalise';
import HistoricoAmostras from './HistoricoAmostras';

function PainelAnalises({role}) {
  // Estado para controlar qual componente deve ser exibido
  const [selectedTab, setSelectedTab] = useState('novaAnalise');

  // Função para lidar com a troca de abas
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <h3 className='text-[14pt]'>Painel de Análises</h3>
      <p className='text-[9pt] -mt-2'>Gerencie as análises do laboratório</p>
      
      {/* Abas */}
      <div className='flex border-b'>
        <button
          className={`py-2 px-4 ${selectedTab === 'novaAnalise' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('novaAnalise')}
        >
          Nova Amostra
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'novoItemAnalise' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('novoItemAnalise')}
        >
          Nova Análise
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'historicoAmostras' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('historicoAmostras')}
        >
          Histórico de amostras
        </button>
      </div>

      {/* Componente exibido */}
      <div className='mt-2'>
        {selectedTab === 'novaAnalise' && <NovaAnalise role={role}/>}
        {selectedTab === 'novoItemAnalise' && <NovoItemAnalise role={role}/>}
        {selectedTab === 'historicoAmostras' && <HistoricoAmostras role={role}/>}
      </div>
    </div>
  );
}

export default PainelAnalises;
