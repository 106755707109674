import React from 'react'
import Img1 from 'Loja/assets/suporte_imagens/img_suporte01.jpg'
import Img2 from 'Loja/assets/suporte_imagens/img_suporte02.jpg'
import Img3 from 'Loja/assets/suporte_imagens/img_suporte03.jpg'
import Img4 from 'Loja/assets/suporte_imagens/img_suporte04.jpg'
function Tecnologia() {
  const Card = ({ content }) => (
    <div className='max-w-[400px] flex flex-col p-2 rounded-md shadow-sm bg-white'>
      <img src={content.img} className='w-full rounded-md' />
      <p className='text-[12pt] font-semibold mt-1'>{content.titulo}</p>
      <p className='text-[10pt] '>{content.conteudo}</p>
    </div>    
  );

  const data = {
    title: 'Suporte e Tecnología.',
    content: [
      {
        titulo: 'MEIO AMBIENTE',
        img:Img1,
        conteudo: 'Produtos concentrados e sólidos. Embalagens otimizadas e retornáveis, evitando poluir o meio ambiente e ocupando menor espaço de armazenagem.'
      },
      {
        titulo: 'GERENCIAMENTO',
        img:Img2,
        conteudo: 'Software excepcional, para atualização de balanço material, que combinado a tecnologia dos produtos de ultima geração propicia operação com ciclos mais elevados, obtendo a relação do melhor custo beneficio.'
      },
      {
        titulo: 'MONITORAMENTO E CONTROLE',
        img:Img3,
        conteudo: 'Nosso moderno laboratório, somado á nossa rede de laboratórios credenciados, garante maior confiabilidade e metodologia adequada para resultados precisos. Com uma equipe multidisciplinar de Engenheiros, químicos, Biólogos e Técnicos Ambientais, oferece maior abrangência para diagnosticar interferência ou fatores específicos de cada sistema.',
      },
      {
        titulo: 'SUPORTE EM CAMPO',
        img:Img4,
        conteudo: 'Profissionais equipados e preparados para correções, se necessário, dispõem de equipamentos para monitoramento local, visando maior aproveitamento e eficiência da visita técnica.'
      }
    ]
  };

  return (
    <div className='flex flex-col md:flex-row p-4 justify-center w-[100%] border rounded-md bg-gray-200 bg-opacity-45'>
    <div className='flex flex-col p-2'>
      <p className='font-semibold text-gray-800 text-[12pt]'>{data.title}</p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 p-2'>
        {data.content.map((item, index) => (
          <Card content={item} key={index} />
        ))}
      </div>
    </div>
  </div>
  )
}

export default Tecnologia