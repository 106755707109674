import React from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import NovoUsuario from 'Admin/scenes/forms/usuarios/NovoUsuario';
import Clientes from 'Admin/scenes/clientes/Index';
import Usuarios from 'Admin/scenes/usuarios/Index';
import Mensagens from 'Admin/scenes/mensagens/Index';
import Avisos from 'Admin/scenes/avisos/Index';
import NewPannel from 'Admin/scenes/newPannel/NewPannel';
import ClienteDetail from 'Admin/scenes/clientes/ClienteDetail';
import CarrocelLoja from 'Admin/scenes/painel-loja/CarrosselLoja';
import ParametrosGerais from 'Admin/scenes/painel-loja/ParametrosGerais';
import EmailTemplates from 'Admin/scenes/painel-email/EmailTemplates';
import EmailsAvulso from 'Admin/scenes/painel-email/EmailsAvulso';
import EmailsEnviados from 'Admin/scenes/painel-email/EmailsEnviados';
import NovoCliente from 'Admin/scenes/clientes/NovoCliente';
import NovaAnalise from 'Admin/scenes/analises/NovaAnalise';
import PainelAnalises from 'Admin/scenes/analises/PainelAnalises';
import PainelLaudos from 'Admin/scenes/laudos/PainelLaudos';
import ParametrosQuimicos from 'Admin/scenes/parametros-quimicos/ParametrosQuimicos';
import PainelSistemas from 'Admin/scenes/parametros-quimicos/PainelSistemas';
import PainelClientes from 'Admin/scenes/clientes/PainelClientes';
import Laudo from 'Admin/scenes/laudos/Laudo';
import Relatorio from 'Admin/scenes/laudos/Relatorio';

function AdminRoot() {

  return (
        <div>
          <Routes>
            <Route element={<NewPannel />}>
              <Route exact path="/" element={<Admin />} />              
              <Route path="/admin" element={<Navigate to="/admin" replace />} />              
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/mensagens" element={<Mensagens />} />
              <Route path="/perfil" element={<Mensagens />} />

              <Route path="/usuarios" element={<Usuarios role={'admin'} />} />
                <Route path="/usuario/novo" element={<NovoUsuario role={'admin'} />} />
              
              <Route path="/clientes" element={<Clientes role={'admin'}/>} />
              <Route path="/clientes/painel" element={<PainelClientes role={'admin'}/>} />
                <Route path="/cliente/novo" element={<NovoCliente role={'admin'} />} />

              <Route path="/painel-loja" element={<ParametrosGerais role={'admin'} />} />

              <Route path="/carrossel" element={<CarrocelLoja />} />
    

              <Route path="/analises" element={<PainelAnalises role={'admin'} />} />
              <Route path="/analise/nova" element={<NovaAnalise role={'admin'} />} />

              <Route path="/laudos" element={<PainelLaudos role={'admin'}/>} />
              <Route path="/laudos/:cliente" element={<PainelLaudos role={'admin'}/>} />

              <Route path="/parametrosquimicos" element={<PainelSistemas role={'admin'} />} />

              <Route path="/email/templates" element={<EmailTemplates />} />
              <Route path="/email/:id" element={<EmailsAvulso />} />
              <Route path="/email/avulso" element={<EmailsAvulso />} />
              <Route path="/emails" element={<EmailsEnviados />} />
              <Route path="/*" element={<Navigate to="/admin" replace />} />
            </Route>
          </Routes>
    </div>
  );
}

export default AdminRoot;