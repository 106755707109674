import React, {useState, useEffect} from 'react';
import { Alert, CircularProgress} from "@mui/material";
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';

const NovoItemAnalise = ({role}) => {
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] =useState([]);
  const [analises, setAnalises] = useState([]);
  const [itemForm, setItemForm] = useState([]);
  const [analise, setAnalise] = useState(null);
  const [clienteLookup, setClienteLookup] = useState({});
  const [analiselookup, setAnaliseLookup] = useState({});
  const [cliente, setCliente] = useState(null);
  const [formData, setFormData] = useState({
    itens: [{
      analise_amostra_item_id : '',
      valor:'',
    }],
    analises_id : '',
    cliente_id : '',
  });

  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }

  useEffect(() => {
    async function getAnalises(id) {
        try {
          const response = await GenGet(`${roleMapping[role]}/client/analysis/${id}`);
          setAnalises(response.analises)
          setAnaliseLookup(response.analise_lookup)
        } catch (err) {
    
        }
      }

      if (cliente && cliente.id){
        getAnalises(cliente.id);
      }

  }, [cliente]);

  useEffect(() => {
    async function getRequisitosAnalise() {
        try {
          const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
          setClienteLookup(response.clientes_lookup)
          setData(response);
          setIsLoading(false);
        } catch (err) {
    
        }
      }
    getRequisitosAnalise();
  }, []);

  useEffect(() => {
    async function getAnaliseItens(id) {
        try {
          const response = await GenGet(`${roleMapping[role]}/analysistemplate/fields/${id}`);
          console.log(response)
          setItemForm(response);
          setFormData(prevFormData => ({
            ...prevFormData,
            itens: response.map(item => ({
              analise_amostra_item_id: item.analise_amostra_item_id,
              valor: ''
            }))
          }));
          console.log(formData);
        } catch (err) {
        }
      }

      if(analise && analise.id){
        getAnaliseItens(analise.id);
      }
    
  }, [analise]);

  const handleChangeCliente = (id, nome) =>{
    let registro = data.clientes.find(item => item.id === id);
    setCliente(registro ? registro : {})
    setFormData({
      ...formData,
      cliente_id: id
    });
  }

  const handleChangeItemValue = (e, id) => {
    const { value } = e.target;
  
    setFormData(prevFormData => ({
      ...prevFormData,
      itens: prevFormData.itens.map(item => 
        item.analise_amostra_item_id === id 
          ? { ...item, valor: value }
          : item
      )
    }));
    console.log(formData)
  };

  const handleChangeAnalise = (id, nome) =>{
    let registro = analises.find(item => item.id === id);
    setAnalise({
      id: registro.id,
      cod_id : registro.cod_id,
      nome : registro.nome,
      origem_coleta : registro.origem_coleta,
      responsavel_coleta : registro.responsavel_coleta,
      data_inclusao : registro.data_inclusao,
      telefone_responsavel : registro.telefone_responsavel,
      tipo_amostra : registro.tipo_amostra,
      cliente_id : registro.cliente_id,
    })
    setFormData({
      ...formData,
      analises_id: id
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const validateForm = () => {
    for (let key in formData) {
      if (formData[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    {/*
    if (!validateForm()) {
      setMsg("Preencha todos os campos");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }      
      */}


    async function PostForm() {
      try {

        const response = await GenPost(`${roleMapping[role]}/analysisdata/create`, formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);

        setFormData({    
          itens: [{
            analise_amostra_item_id : '',
            valor:'',
          }],
          analises_id : '',
          cliente_id : ''});

      } catch (err) {

        setMsg('Erro ao cadastrar Análise.');
        setTimeout(() => {
            setMsg(null);
          }, 3000);
      }
    }
    PostForm();
  };

  return ( 
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <p className='text-[9pt] -mt-2'>Cadastre uma nova análise</p>
        
        {!isLoading ? (
          <div>
            
        <div className='flex flex-row gap-2 w-full'>
        {clienteLookup && !isLoading && (<CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente}/>)}
        {analiselookup && !isLoading && (<CustomSelect search={true} label={'Amostras'} data={analiselookup} onChange={handleChangeAnalise}/>)}
        </div>
        
        {cliente && cliente.id && (
            <div className='p-4 rounded-md border-[1px]'>
                <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p> 
                <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
                <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
                <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && ( `ramal:${cliente.ramal}`)}</p>
                <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
                <p className='p-0 m-0'>Cidade: {cliente.matriz_cidade} - Estado:{cliente.matriz_estado} - País: {cliente.pais_matriz}</p>
            </div>
            
        )}
       
        {cliente && analise &&(
            <div className='flex flex-col'>
            <hr/>
            <p className='font-semibold'>Dados da análise:</p>
              <div className='flex flex-row justify-between w-full'>
                      <div className='flex flex-col'>
                          <label>Data de Coleta</label>
                          <input 
                              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                              id="data_coleta"
                              name="data_coleta"
                              type={'datetime-local'}
                              value={formData.data_coleta} 
                              onChange={handleChange}  
                          />
                      </div>
                      <div className='flex flex-col'>
                          <label>Data de Entrada</label>
                          <input 
                              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                              id="data_entrada"
                              name="data_entrada"
                              type={'datetime-local'}
                              value={formData.data_entrada} 
                              onChange={handleChange}  
                          />
                      </div>
                      <div className='flex flex-col'>
                          <label>Data da Análise</label>
                          <input 
                              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                              id="data_amostra"
                              name="data_amostra"
                              type={'datetime-local'}
                              value={formData.data_amostra} 
                              onChange={handleChange}  
                              />
                      </div>
              </div>
              <div className='flex flex-row w-full mt-2'>
                <div className='flex flex-col'>
                  <label>Nº L.A.</label>
                  <input 
                    className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                    id="n_amostra_la"
                    name="n_amostra_la"
                    type={'string'}
                    value={formData.n_amostra_la} 
                    onChange={handleChange}  
                    />
                </div>
              </div>
              <br/>
              {itemForm && itemForm.length > 0 && (
                <div className='flex flex-col justify-between w-full gap-2'>
                  <p className='font-semibold'>Dados de coleta:</p>
                  {itemForm.map(item => (
                    <div key={item.id} className='flex flex-row justify-between p-2 rounded-md border-[1px]'>
                      <div className='flex flex-col'>
                        <p className='p-0 m-0 font-semibold'>{item.amostra_item.nome}</p>
                        <p className='p-0 m-0 text-sm'>{item.amostra_item.descricao}</p>
                      </div>
                      <div className='flex flex-col'>
                        <input
                          className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                          id={item.id}
                          name={item.id}
                          type='number'
                          value={formData.itens.find(i => i.analise_amostra_item_id === item.analise_amostra_item_id)?.valor || ''} // Acessa o valor do item específico
                          onChange={(e) => handleChangeItemValue(e, item.analise_amostra_item_id)} // Passe item.id para identificar o item correto
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}


              <div className='w-full flex flex-row-reverse pt-2'>
              <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
              {msg ? 
                <div className='flex justify-end p-1' >
                  <Alert severity="info" sx={{width:320}} > {msg} </Alert>
                </div>
              : null}
              </div>
            </div>
        )}

          </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}



          
    </div> 
  )
}

export default NovoItemAnalise;