import React, { useState, useEffect } from 'react';
import PainelSistema from './PainelSistema';
import ParametrosQuimicos from './ParametrosQuimicos';

function PainelSistemas({role}) {
    const [selectedTab, setSelectedTab] = useState('parametros');

    // Função para lidar com a troca de abas
    const handleTabClick = (tab) => {
      setSelectedTab(tab);
    };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <h3 className='text-[14pt]'>Painel De Sistema</h3>
      <p className='text-[9pt] -mt-2'>gerencíe as configurações de sistemas.</p>
       {/* Abas */}
      <div className='flex border-b'>
        <button
          className={`py-2 px-4 ${selectedTab === 'parametros' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('parametros')}
        >
          Parâmetros de sistemas
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'sistema' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('sistema')}
        >
          Configurar um sistema
        </button>
      </div>

      {/* Componente exibido */}
      <div className='mt-2'>
        {selectedTab === 'sistema' && <PainelSistema role={role}/>}
        {selectedTab === 'parametros' && <ParametrosQuimicos role={role}/>}
      </div>

    </div>
  )
}

export default PainelSistemas
