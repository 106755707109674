import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import { Alert, CircularProgress } from '@mui/material';

function NovoParametroQuimico({role}) {
  const [requirements, setRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sistemaId, setSistemaId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [sistema, setSistema] = useState(null);
  const [msg, setMsg] = useState(null);
  const [itemMsg, setItemMsg] = useState(null);
  const [formData, setFormData] = useState({});
  const [refresh, setRefresh] = useState(null);
  const [editingItem, setEditingItem] = useState(null); 

  const roleMapping = {
    eng: 'eng',
    lab: 'lab',
    admin: 'admin',
  };

  const [tipoFormData, setTipoFormData] = useState({
    valor: '',
    analise_amostra_item_id: '',
    tipos_amostras_id: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTipoFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingItem(prevItem => ({
      ...prevItem,
      [name]: value,
    }));
  };

  useEffect(() => {
    async function getRequiriments() {
      try {
        const response = await GenGet(`${roleMapping[role]}/analysistemplate/requirements`);
        setRequirements(response);
        setIsLoading(false)
      } catch (err) {
        console.error(err);
        setIsLoading(false)
      }
    }
    getRequiriments();

  }, []);

  async function getSistemaInfo(id) {
    try {
      const response = await GenGet(`${roleMapping[role]}/sistemindices/${id}`);
      console.log(response)
      setSistema(response);
    } catch (err) {
      console.error(err);
    }
    setRefresh(false);
  }

  useEffect(() => {
    if(sistemaId){
      getSistemaInfo(sistemaId);
    }
  }, [sistemaId]);

  useEffect(() => {
    if(sistemaId && refresh){
      getSistemaInfo(sistemaId);
    }
  }, [refresh]);

  const handleChangeSistema = (id, nome) => {
    let registro = requirements.sistemas.find(item => item.id === id);

    setSistemaId(id ? id : null);
    
    setTipoFormData(prevFormData => ({
      ...prevFormData,
      tipos_amostras_id: registro ? id : '',
    }));
  };

  const handleChangeItem = (id, nome) => {
    let registro = requirements.items.find(item => item.id === id);

    setItemId(id ? id : null);
    setTipoFormData(prevFormData => ({
      ...prevFormData,
      analise_amostra_item_id: registro ? id : '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    async function PostForm() {
      try {
        const response = await GenPost(`${roleMapping[role]}/analysistypeindice/create`, tipoFormData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setRefresh(true);
        setFormData({
          valor: '',
          analise_amostra_item_id: '',
          tipos_amostras_id: '',
        });
        
      } catch (err) {
        setMsg('Erro ao incluir índice.');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
    
  };

  const handleUpdateItem = async () => {
    if (!editingItem || !editingItem.id) return;

    try {
      const response = await GenPost(`${roleMapping[role]}/analysistypeindice/update/${editingItem.id}`, { valor: editingItem.valor });
      setItemMsg(response.message);
      setTimeout(() => {
        setItemMsg(null);
      }, 3000);

      setEditingItem(null);
      setRefresh(true)
      
    } catch (err) {
      setItemMsg('Erro ao atualizar índice.');
      setTimeout(() => {
        setItemMsg(null);
      }, 3000);
    }
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      
      <p className='font-semibold m-0 p-0'>Novo índice de sistema</p>
      <p className='text-[9pt] -mt-2 font-medium'>Inclua novos índices de acordo com o sistema.</p>
      
      {!isLoading ? (      
        <div>
          <div className='flex flex-row justify-between'>
            <div className='flex w-full'>
              {requirements.sistema_lookup && (
                <CustomSelect search={true} label={'Sistemas'} data={requirements.sistema_lookup} onChange={handleChangeSistema} />
              )}
            </div>
            <div className='flex w-full'>
              {requirements.item_lookup && tipoFormData.tipos_amostras_id && (
                <CustomSelect search={true} label={'Componente Químico'} data={requirements.item_lookup} onChange={handleChangeItem} />
              )}
            </div>
            <div className='flex w-full'>
              {tipoFormData.analise_amostra_item_id && (
                <div className='flex flex-col gap-1'>
                <input
                  className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                  id="valor"
                  name="valor"
                  placeholder="Índice"
                  value={tipoFormData.valor}
                  onChange={handleChange}
                  />    
                </div>
                
              )}
            </div>
          </div>
          <div className='w-full flex flex-row-reverse pt-2'>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
            {msg ? (
              <div className='flex justify-end p-1'>
                <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
              </div>
            ) : null}
          </div>

          <hr/>
          <div>
            <div className='flex flex-row justify-between'>
              <p className='font-semibold'>Índices do sistema:</p>
              {itemMsg ? (
                    <div className='flex justify-end p-1'>
                      <Alert severity="info" sx={{ width: 320 }}>{itemMsg}</Alert>
                    </div>
              ) : null}
            </div>
      
            {sistema && sistema.map(item => (
              <div key={item.id} className='flex flex-row gap-2 p-2 border-[1px] rounded-md m-1'>
                <div className='flex flex-col min-w-[200px]'>
                  <p className='p-0 m-0'><b>Nome:</b> {item.amostra_item.nome}</p>
                  <p className='p-0 m-0 text-sm'>{item.amostra_item.descricao}</p>
                </div>
                <div className='flex flex-col gap-2 min-w-[340px]'>
                  <p className='p-0 m-0 text-sm'><b>un:</b> {item.amostra_item.un}</p>
                  <p className='p-0 m-0 text-sm'><b>Método:</b> {item.amostra_item.metodo}</p>
                </div>
                <div className='flex flex-row min-w-[150px]'>
                  {editingItem && editingItem.id === item.id ? (
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none'
                      name="valor"
                      value={editingItem.valor}
                      onChange={handleEditChange}
                    />
                  ) : (
                    <p className='p-0 m-0'><b>índice:</b> {item.valor}</p>
                  )}
                </div>
                <div className='w-full flex flex-row-reverse pt-2'>
                  {editingItem && editingItem.id === item.id ? (
                    <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleUpdateItem}>Atualizar</button>
                  ) : (
                    <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={() => setEditingItem({ id: item.id, valor: item.valor })}>Editar</button>
                  )}

                </div>
              </div>
            ))}
          </div>
        </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}
    </div>
  )
}

export default NovoParametroQuimico
