import React, { useState } from 'react';
import NovoLaudo from './NovoLaudo';
import Laudos from './Laudos';
import Relatorios from './Relatorios';

function PainelLaudos({role}) {
  const [selectedTab, setSelectedTab] = useState('novoLaudo');

  // Função para lidar com a troca de abas
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Painel de Laudos</h3>
        <p className='text-[9pt] -mt-2'>gerencie os laudos dos clientes</p>
      
      
      {/* Abas */}
      <div className='flex border-b'>
        <button
          className={`py-2 px-4 ${selectedTab === 'novoLaudo' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('novoLaudo')}
        >
          Novo Relatório
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'relatorios' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('relatorios')}
        >
          Relatórios
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'laudos' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('laudos')}
        >
          Laudos
        </button>
      </div>

      {/* Componente exibido */}
      <div className='mt-2'>
        {selectedTab === 'novoLaudo' && <NovoLaudo role={role}/>}
        {selectedTab === 'laudos' && <Laudos role={role}/>}
        {selectedTab === 'relatorios' && <Relatorios role={role}/>}

      </div>
    </div>
  )
}

export default PainelLaudos
