import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import Relatorio from '../laudos/Relatorio';


function LaudosClientes({role}) {
    const [isLoading, setIsLoading] = useState(true);
    const [relatorios, setRelatorios] = useState(null);
    const [relatorio, setRelatorio] = useState(null);
    const [relatoriosLookup, setRelatoriosLookup] = useState({});
  
    const roleMapping = {
      customer: 'customer',
    };
  
    useEffect(() => {
      async function getReports() {
        try {
          const response = await GenGet(`${roleMapping[role]}/closedreports/complete`);
          console.log(response)
          setRelatorios(response.laudos);
          setRelatoriosLookup(response.laudo_lookup);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
  
        getReports();

  
    }, []);

    const handleChangeRelatorio = (id, nome) =>{
        let registro = relatorios.find(item => item.id === id);
        setRelatorio(registro);
      }

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Painel de Laudos</h3>
        <p className='text-[9pt] -mt-2'>gerencie os laudos dos clientes</p>

      {relatoriosLookup && !isLoading && ( 
        <div className='flex flex-col w-full'>
          <p className='p-0 m-0 font-semibold'>Escolha um relatório:</p>
          <CustomSelect search={true} label={'Relatórios'} data={relatoriosLookup} onChange={handleChangeRelatorio}/> 
        </div>
      )}
      {relatorio && (
        <Relatorio role={role} id={relatorio.id}/>
      )}       
    </div>
  )
}

export default LaudosClientes
