import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LinkedIn } from '@mui/icons-material';

const Contato = () => {
  return (
    <div className={'flex flex-col p-4 sm:py-10 sm:px-20 justify-between align-middle items-center min-h-[75vh]'}>
        
        <h3 className='font-semibold self-start p-2'>Entre em contato!</h3>
        <div className='flex md:flex-row flex-col border-1 border-blue-600 rounded-md p-4 shadow-md text-[11pt]'>
        {/* Seção: Atendimento ao Cliente */}
        <div className="mb-6 border-r p-2">
            <h3 className="text-xl font-bold mb-2">Nossos contatos</h3>
            <p className='px-4'>
            <span className='font-semibold'>Tem alguma dúvida ou precisa de suporte?</span> <br/>Entre em contato com nossa equipe de atendimento ao cliente.
            </p>

            {/* Informações de Contato */}
            <div className="flex items-center mb-2 px-8 gap-1">
            <EmailIcon color="primary" fontSize="small"/>
            <p className="text-base p-0 m-0">E-mail: atendimento@iguacontrol.com.br</p>
            </div>

            <div className="flex items-center px-8 gap-1">
            <WhatsAppIcon color="primary" fontSize="small" />
            <p className="text-base p-0 m-0">WhatsApp: (47) 99117-7022</p>
            </div>
            <p className="text-base p-4">Atendimento de Seg - Sex das 8:00 as 18:00</p>
        </div>

        {/* Seção: Revenda Nossa Marca */}
        <div className='p-2'>
            <h3 className="text-xl font-bold mb-2 ">Nossas redes sociais:</h3>
            <span className='font-semibold'>Visite ou Entre em contato conosco através de nossas redes sociais!</span>

            <div className='flex flex-col gap-1 w-full p-4 '>
              <div className='flex flex-row justify-between'>
                <p className='p-0 m-0'>Whatsapp: </p>
                <p className='p-0 m-0'> - conteudo aqui - </p>
                <WhatsAppIcon color="success" fontSize="small"/> 
              </div>

              <div className='flex flex-row justify-between'>
                <p className='p-0 m-0'>Facebook: </p>
                <p className='p-0 m-0'> - conteudo aqui - </p>
                <FacebookIcon color="primary" fontSize="small"/>                
              </div>

              <div className='flex flex-row justify-between'>
                <p className='p-0 m-0'>Instagram: </p>
                <p className='p-0 m-0'> - conteudo aqui - </p>
                <InstagramIcon color="error" fontSize="small"/>                 
              </div>

              <div className='flex flex-row justify-between'>
                <p className='p-0 m-0'>LinkedIn: </p>
                <p className='p-0 m-0'> - conteudo aqui - </p>
                <LinkedIn color={"primary"} size={"small"} />                
              </div>
            </div>

        </div>
    </div>
    </div>
  );
};

export default Contato;