import React, { useState, useEffect } from 'react';
import { Alert, CircularProgress } from "@mui/material";
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';

const NovaAnalise = ({ role }) => {
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [analises, setAnalises] = useState([]);
  const [clienteLookup, setClienteLookup] = useState({});
  const [userslookup, setUsersLookup] = useState({});
  const [analiselookup, setAnaliseLookup] = useState({});
  const [cliente, setCliente] = useState({});
  const [formData, setFormData] = useState({
    nome: '',
    origem_coleta: 'ICBR',
    responsavel_coleta: '',
    telefone_responsavel: '',
    tipo_amostra: '',
    cliente_id: '',
  });

  const roleMapping = {
    eng: 'eng',
    lab: 'lab',
    admin: 'admin',
  };

  useEffect(() => {
    async function getAnalises(id) {
      try {
        const response = await GenGet(`${roleMapping[role]}/client/analysis/${id}`);
        setAnalises(response.analises);
        setAnaliseLookup(response.analise_lookup);
      } catch (err) {
        console.error(err);
      }
    }

    if (cliente.id) {
      getAnalises(cliente.id);
    }
  }, [cliente]);

  useEffect(() => {
    async function getRequisitosAnalise() {
      try {
        const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
        setClienteLookup(response.clientes_lookup);
        setUsersLookup(response.users_lookup);
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    getRequisitosAnalise();
  }, [role]);

  const handleChangeCliente = (id, nome) => {
    let registro = data.clientes.find(item => item.id === id);
    setCliente(registro ? registro : {});
    setFormData(prevFormData => ({
      ...prevFormData,
      cliente_id: registro ? id : '',
    }));
  };

  const handleChangeResponsavel = (id, nome) => {
    let registro = data.usuarios.find(item => item.id === id);
    setFormData(prevFormData => ({
      ...prevFormData,
      responsavel_coleta: `${registro.name} ${registro.name}`,
      telefone_responsavel: registro.telefone,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const validateForm = () => {
    for (let key in formData) {
      if (formData[key] === "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setMsg("Preencha todos os campos");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    } 

    async function PostForm() {
      try {
        const response = await GenPost(`${roleMapping[role]}/create/analysis`, formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);

        setFormData({
          nome: '',
          origem_coleta: 'ICBR',
          responsavel_coleta: '',
          telefone_responsavel: '',
          tipo_amostra: '',
          cliente_id: '',
        });
        setCliente([]);
        setAnalises([]);

      } catch (err) {
        setMsg('Erro ao incluir análise.');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
    
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <p className='text-[9pt] -mt-2'>Cadastre uma nova amostra</p>

      {!isLoading ? (
        <div>
              {clienteLookup && !isLoading && (
                <CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente} />
              )}
              {cliente.id && (
                <div>
                  <div className='p-4 rounded-md border-[1px]'>
                    <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p>
                    <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
                    <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
                    <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && (`ramal:${cliente.ramal}`)}</p>
                    <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
                    <p className='p-0 m-0'>Cidade: {cliente.matriz_cidade} - Estado:{cliente.matriz_estado} - País: {cliente.pais_matriz}</p>
                  </div>
                  <p className='font-semibold pt-2'>Dados da amostra:</p>
                  <input
                    className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                    id="nome"
                    name="nome"
                    placeholder="Nome"
                    value={formData.nome}
                    onChange={handleChange}
                  />

                  <p className='font-semibold pt-2'>Dados de coleta:</p>
                  <div className='flex flex-row justify-between w-full gap-2'>
                    <div className='flex flex-col w-full'>
                      <label className='mb-4'>Responsavel por coleta</label>
                      <select
                        className={`px-4 py-2 rounded border bg-white border-gray-300 focus:outline-none`}
                        aria-label='origem_coleta'
                        onChange={handleChange}
                        id='origem_coleta'
                        name='origem_coleta'
                        value={formData.origem_coleta}
                      >
                        <option value='Cliente'>Cliente</option>
                        <option value='ICBR'>Igua Control</option>
                      </select>
                    </div>
                    <div className='flex flex-col w-full'>
                      {formData.origem_coleta === 'Cliente' ? (
                        <div className='flex flex-col gap-1'>
                          <p>Responsável</p>
                          <div className='flex flex-row gap-1'>
                            <input
                              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                              id="responsavel_coleta"
                              name="responsavel_coleta"
                              placeholder="Nome do Responsavel"
                              type='text'
                              value={formData.responsavel_coleta}
                              onChange={handleChange}
                            />
                            <input
                              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                              id="telefone_responsavel"
                              name="telefone_responsavel"
                              placeholder="Tel. do Responsavel"
                              type='text'
                              value={formData.telefone_responsavel}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <label className='mb-4'>Responsavel</label>
                          {userslookup && !isLoading && (
                            <CustomSelect search={true} label={'Responsável'} data={userslookup} onChange={handleChangeResponsavel} />
                          )}
                        </div>
                      )}
                    </div>
                    {!isLoading && data.tipos_amostras && (
                      <div className='flex flex-col w-full'>
                        <label className='mb-4'>Tipo de Analise</label>
                        <select
                          className={`px-4 py-2 rounded border bg-white border-gray-300 focus:outline-none`}
                          aria-label='tipo_amostra'
                          onChange={handleChange}
                          id='tipo_amostra'
                          name='tipo_amostra'
                          value={formData.tipo_amostra}
                        >
                          <option value={''}>Selecione</option>
                          {data.tipos_amostras.map(item => (
                            <option key={item.id} value={item.id}>{item.nome}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>

              )}
              <div className='w-full flex flex-row-reverse pt-2'>
                <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
                {msg ? (
                  <div className='flex justify-end p-1'>
                    <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
                  </div>
                ) : null}
              </div>
        </div>
      ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
      )}

    </div>
  );
};

export default NovaAnalise;
