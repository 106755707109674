import React, { useState } from 'react';
import NovoCliente from './NovoCliente';
import PainelCliente from './PainelCliente';

function PainelClientes({role}) {
  // Estado para controlar qual componente deve ser exibido
  const [selectedTab, setSelectedTab] = useState('novoCliente');

  // Função para lidar com a troca de abas
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <h3 className='text-[14pt]'>Painel de Clientes</h3>
      <p className='text-[9pt] -mt-2'>Gerencie as análises do laboratório</p>
      
      {/* Abas */}
      <div className='flex border-b'>
        <button
          className={`py-2 px-4 ${selectedTab === 'novoCliente' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('novoCliente')}
        >
          Novo Cliente
        </button>
        <button
          className={`py-2 px-4 ${selectedTab === 'painelCliente' ? 'border-b-2 border-blue-500 font-semibold' : 'text-gray-500'}`}
          onClick={() => handleTabClick('painelCliente')}
        >
          Painel do cliente
        </button>
      </div>

      {/* Componente exibido */}
      <div className='mt-2'>
        {selectedTab === 'novoCliente' && <NovoCliente role={role}/>}
        {selectedTab === 'painelCliente' && <PainelCliente role={role}/>}
      </div>
    </div>
  );
}

export default PainelClientes
