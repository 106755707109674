import React, { useEffect, useState } from 'react';
import ParametrosEmail from './ParametrosEmail'
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import { CircularProgress } from '@mui/material';

function ParametrosGerais({role}) {
  const [refresh, setRefresh] = useState(false);
  const [msg, setMsg] = useState(null);
  const [ usuariosLookup, setUsuariosLookup] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }
  const [paramsFormData, setParamsFormData] = useState({
    nome_empresa: '',
    razao_social: '',
    nome_fantasia: '',
    endereco:'',
    CNPJ: '',
    CEP: '',
    cidade: '',
    estado: '',
    coordenadas: '',
    telefone_comercial: '',
    telefone_atendimento: '',
    email_comercial: '',
    email_atendimento: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
    responsavel_tecnico: '',
    doc_profissional: '',
    supervisor:''
  });


  const [validationErrors, setValidationErrors] = useState({
    nome_empresa: '',
    razao_social: '',
    nome_fantasia: '',
    endereco:'',
    CNPJ: '',
    CEP: '',
    cidade: '',
    estado: '',
    coordenadas: '',
    telefone_comercial: '',
    telefone_atendimento: '',
    email_comercial: '',
    email_atendimento: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
    responsavel_tecnico: '',
    doc_profissional: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
    supervisor:''
  });

  const handleRespTecnico = (id, nome) => {
    setParamsFormData({
      ...paramsFormData, 
      responsavel_tecnico: id
    });
  };

  const handleSupervisor = (id, nome) => {
    setParamsFormData({
      ...paramsFormData, 
      supervisor: id
    });
  };
  const handleParamsChange = (e) => {
    setParamsFormData({
      ...paramsFormData,
      [e.target.name]: e.target.value,
    });
    console.log(paramsFormData)
  };

  const handleParamPost = async () => {
    try {
  
      const response = await GenPost(`${roleMapping[role]}/params/store`, paramsFormData);
      setRefresh(true); 
      setMsg(response.message);
    } catch (err) {
      console.error('Error posting data:', err);
      setMsg(err.message);
    } finally {
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        //
        const responseGeral = await GenGet(`${roleMapping[role]}/params/get`);
        const lookup = await GenGet(`${roleMapping[role]}/userlookup`);
        setUsuariosLookup(lookup);
        setParamsFormData({
          nome_empresa: responseGeral.nome_empresa,
          razao_social: responseGeral.razao_social,
          nome_fantasia: responseGeral.nome_fantasia,
          endereco: responseGeral.endereco,
          CNPJ: responseGeral.CNPJ,
          CEP: responseGeral.CEP,
          cidade: responseGeral.cidade,
          estado: responseGeral.estado,
          coordenadas: responseGeral.coordenadas,
          telefone_comercial: responseGeral.telefone_comercial,
          telefone_atendimento: responseGeral.telefone_atendimento,
          email_comercial: responseGeral.email_comercial,
          email_atendimento: responseGeral.email_atendimento,
          facebook: responseGeral.facebook,
          instagram: responseGeral.instagram,
          whatsapp: responseGeral.whatsapp,
          responsavel_tecnico: responseGeral.responsavel_tecnico,
          supervisor: responseGeral.supervisor,
          doc_profissional: responseGeral.doc_profissional,
          facebook: responseGeral.facebook,
          instagram: responseGeral.instagram,
          whatsapp: responseGeral.whatsapp,
        });
  
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching fretes:', err);
      }
    };
  
    fetchData();
  }, [refresh]);
  
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className='flex flex-col w-full text-[10pt] p-4'>
      <h3 className='text-[14pt]'>Parâmetros Gerais</h3>
      <p className='text-[9pt] -mt-2'>configurações gerais de sua empresa</p>
      {!isLoading ? (      
        <div>
            <div className='flex flex-col gap-2 w-full'>
            <h3 className='input-classes text-[12pt]'>Informações da empresa</h3>
                <label className='font-semibold'>Sigla:</label>
                <div className='flex flex-row justify-start gap-2 mr-4'>              
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='nome_empresa'
                      placeholder='Sigla'
                      name='nome_empresa'
                      value={paramsFormData.nome_empresa}
                      onChange={handleParamsChange}
                    />
                </div>

                <label className='font-semibold'>Razão Social:</label>
                <div className='flex flex-row justify-start gap-2 mr-4'>
                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='razao_social'
                      placeholder='Razão Social'
                      name='razao_social'
                      
                      value={paramsFormData.razao_social}
                      onChange={handleParamsChange}
                    />
                </div>

                <label className='font-semibold'>Nome Fantasia:</label>
                <div className='flex flex-row justify-start gap-2 mr-4'>                
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='nome_fantasia'
                      placeholder='Nome Fantasia'
                      name='nome_fantasia'
                      
                      value={paramsFormData.nome_fantasia}
                      onChange={handleParamsChange}
                    />
                </div>
                
                <label className='font-semibold'>CNPJ:</label>
                <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='CNPJ'
                      placeholder='CNPJ'
                      name='CNPJ'
                      
                      value={paramsFormData.CNPJ}
                      onChange={handleParamsChange}
                    />
                </div>

            <h3 className='input-classes text-[12pt]'>Localização</h3>
                  <label className='font-semibold'>Endereço:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>  
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='endereco'
                      placeholder='Endereço'
                      name='endereco'
                      
                      value={paramsFormData.endereco}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>CEP:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='CEP'
                      placeholder='CEP'
                      name='CEP'
                      
                      value={paramsFormData.CEP}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Cidade:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='cidade'
                      placeholder='Cidade'
                      name='cidade'
                      
                      value={paramsFormData.cidade}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Estado:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='estado'
                      placeholder='Estado'
                      name='estado'
                      
                      value={paramsFormData.estado}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Coordenadas:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='coordenadas'
                      placeholder='Coordenadas'
                      name='coordenadas'
                      
                      value={paramsFormData.coordenadas}
                      onChange={handleParamsChange}
                    />
                  </div>


                  <div className='flex flex-row justify-start gap-2 mr-4'>
                    {usuariosLookup && !isLoading && (
                      <div className='flex flex-col gap-4 w-full p-4 border rounded-md'>
                        <p className='p-0 m-0 font-semibold text-gray-700'>Responsável técnico</p>
                        <CustomSelect search={true} className={'min-w-[280px]'}  prevalue={paramsFormData.responsavel_tecnico} label={'Responsável técnico'} data={usuariosLookup} onChange={handleRespTecnico} />
                      </div>
                      
                    )}

                    <div className='flex flex-col gap-4 w-full p-4 border rounded-md'>
                      <p className='p-0 m-0 font-semibold text-gray-700'>Orgão de Classe / N° Registro</p>
                      <input
                        className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                        id='doc_profissional'
                        placeholder='Orgão de Classe / N° Registro'
                        name='doc_profissional'
                        
                        value={paramsFormData.doc_profissional}
                        onChange={handleParamsChange}
                      />
                    </div>
                  </div>


                  <div className='flex flex-row justify-start gap-2 mr-4'>
                    {usuariosLookup && !isLoading && (
                      <div className='flex flex-col gap-4 w-full p-4 border rounded-md'>
                        <p className='p-0 m-0 font-semibold text-gray-700'>Supervisor</p>
                        <CustomSelect className={'min-w-[280px]'} search={true} prevalue={paramsFormData.supervisor} label={'Supervisor'} data={usuariosLookup} onChange={handleSupervisor} />
                      </div>
                      
                    )} 

                  </div>
                  
                <h3 className='input-classes text-[12pt]'>Contato</h3>
                  <label className='font-semibold'>Tel. Comercial:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='telefone_comercial'
                      placeholder='Tel. Comercial'
                      name='telefone_comercial'
                      
                      value={paramsFormData.telefone_comercial}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Tel. Atendimento:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='telefone_atendimento'
                      placeholder='Tel. Atendimento'
                      name='telefone_atendimento'                      
                      value={paramsFormData.telefone_atendimento}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Email Comercial:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='email_comercial'
                      placeholder='Email Comercial'
                      name='email_comercial'
                      
                      value={paramsFormData.email_comercial}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Email Atendimento:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='email_atendimento'
                      placeholder='Email Atendimento'
                      name='email_atendimento'
                      
                      value={paramsFormData.email_atendimento}
                      onChange={handleParamsChange}
                    />
                  </div>


            <h3 className='input-classes text-[12pt]'>Redes Sociais</h3>
                  
                  <label className='font-semibold'>Facebook:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='facebook'
                      placeholder='Facebook'
                      name='facebook'
                      
                      value={paramsFormData.facebook}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Instagram:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='instagram'
                      placeholder='Instagram'
                      name='instagram'
                      
                      value={paramsFormData.instagram}
                      onChange={handleParamsChange}
                    />
                  </div>

                  <label className='font-semibold'>Whatsapp:</label>
                  <div className='flex flex-row justify-start gap-2 mr-4'>                    
                    <input
                      className='px-4 py-2 rounded border border-gray-300 focus:outline-none w-full'
                      id='whatsapp'
                      placeholder='Whatsapp'
                      name='whatsapp'
                      
                      value={paramsFormData.whatsapp}
                      onChange={handleParamsChange}
                    />
                  </div>
            </div>
                

            <div className='flex w-full flex-row-reverse pt-4'>
                    <button
                      className='bg-blue-500 hover:bg-blue-400 admin-button'
                      onClick={handleParamPost}
                    >
                      Atualizar
                    </button>
            </div>
        </div>
          ) : (
            <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
              <CircularProgress color="success"/>
            </div>
          )}    

        {msg && (
            <div className='flex justify-end w-full text-right text-gray-800 text-[10pt] p-2'>
              <p className='text-right'>{msg}</p>
            </div>
          )}
      <hr/>
      <ParametrosEmail />
    </div>
  )
}

export default ParametrosGerais