import React, {useState} from 'react';
import { GenPost } from 'Admin/state/api';
import { Alert, CircularProgress } from '@mui/material';

function NovoEndereco({role, cliente_id}) {
    const [msg, setMsg] = useState(null);
    const [postIsLoading, setPostIsLoading] = useState(false);
    const roleMapping ={
      eng:'eng',
      lab:'lab',
      admin:'admin',
    }

    const [formData, setFormData] = useState({
        nome_unidade: "",
        email: "",
        telefone: "",
        ramal: "",
        numero: "",
        endereco: "",
        bairro: "",
        cep: "",
        cidade: "",
        estado: "",
        pais: "",
        cliente_id: cliente_id,
    });
  
    const newAddress = {
        nome_unidade: "",
        email: "",
        telefone: "",
        ramal: "",
        numero: "",
        endereco: "",
        bairro: "",
        cep: "",
        cidade: "",
        estado: "",
        pais: "",
        cliente_id: cliente_id,
    }
  
    const handleChange = (e) => {
          setFormData({
            ...formData,
            [e.target.name]: e.target.value
          });
      };
  
    const formatarTelefone = (valor) => {
      // Remove todos os caracteres não numéricos
      const cleaned = valor.replace(/\D/g, '');
      // Aplica a máscara para 10 ou 11 dígitos
      if (cleaned.length === 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
      } else if (cleaned.length === 11) {
        return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
      }
      return valor;
    };
  
    const formatarCep = (valor) => {
      // Remove todos os caracteres não numéricos
      const cleaned = valor.replace(/\D/g, '');
    
      // Aplica a máscara se o valor tiver 8 dígitos
      if (cleaned.length === 8) {
        return cleaned.replace(/(\d{5})(\d{3})/, '$1-$2');
      }
    
      // Retorna o valor original se não houver 8 dígitos
      return valor;
    };

    const validateForm = () => {
      for (let key in formData) {
        if (formData[key] === "") {
          return false;
        }
      }
      return true;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
    {/*
      if (!validateForm()) {
        setMsg("Preencha todos os campos");
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        return;
      }  
    
    */}
  
  
      async function PostForm() {
        setPostIsLoading(true);
        try {
          const response = await GenPost(`${roleMapping[role]}/new/clientaddress`, formData);
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
          setFormData(newAddress);
          setPostIsLoading(false);

        } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
          setPostIsLoading(false);  
        }
      }
      PostForm();
    };
  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
    <p className='text-[9pt] -mt-2'>Cadastre um novo endereço no cadastro do cliente.</p>
    <hr />
    
    <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
        <h3 className='text-[12pt]'>Dados da Unidade</h3>

        <label className='mt-2 text-[10pt]'  >Nome da Unidade *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="nome_unidade"
        name="nome_unidade"
        placeholder="Nome da Unidade"
        value={formData.nome_unidade} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Email *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="email"
        name="email"
        placeholder="Email"
        value={formData.email} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Telefone *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="telefone"
        name="telefone"
        maxLength={14}
        placeholder="Telefone (com DDD) digite apenas números."
        value={formatarTelefone(formData.telefone)}
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Ramal:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="ramal"
        name="ramal"
        placeholder="Ramal"
        value={formData.ramal} 
        onChange={handleChange}  
        />


        <label className='mt-2 text-[10pt]'  >Endereço *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="endereco"
        name="endereco"
        placeholder="Endereço"
        value={formData.endereco} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Número *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="numero"
        name="numero"
        placeholder="Número"
        value={formData.numero} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Bairro *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="bairro"
        name="bairro"
        placeholder="Bairro"
        value={formData.bairro} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >CEP *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="cep"
        name="cep"
        placeholder="Cep"
        value={formatarCep(formData.cep)} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Cidade *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="cidade"
        name="cidade"
        placeholder="Cidade"
        value={formData.cidade} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Estado *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="estado"
        name="estado"
        placeholder="Estado"
        value={formData.estado} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >País *:</label>         
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="pais"
        name="pais"
        placeholder="País"
        value={formData.pais} 
        onChange={handleChange}  
        />
    </div>

    <div className='w-full flex flex-row-reverse pt-2'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
        {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
        {msg ? 
        <div className='flex justify-end p-1'>
            <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
        </div>
        : null}
    </div>
    </div>

  )
}

export default NovoEndereco
