import React, { useState, useEffect } from 'react';
import { GenGet, GenPost, GenPostMultiform } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import Analise from './Analise';
import VisualizarRelatorio from './VisualizarRelatório';

function Relatorios({role}) {
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [postIsLoading, setPostIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [analises, setAnalises] = useState([]);
    const [clienteLookup, setClienteLookup] = useState({});
    const [analiselookup, setAnaliseLookup] = useState([]);
    const [analise, setAnalise] = useState(null);
    const [amostras, setAmostras] = useState(null);
    const [analisesInclusas, setAnalisesInclusas] = useState([]);
    const [relatorio, setRelatorio] = useState(null);
    const [relatorios, setRelatorios] = useState(null);
    const [relatorioPointer, setRelatorioPointer] = useState(null);
    const [verRelatorio, setVerRelatorio] = useState(false);
    const [relatoriosLookup, setRelatoriosLookup] = useState({});
    const [inserirAnalise, setinserirAnalise] = useState(false);
    const [inserirImagem, setinserirImagem] = useState(false);
    const [cliente, setCliente] = useState({});
    const [verAnalise, setVerAnalise] = useState(false);
    const [analisePointer, setAnalisePointer] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [antesImage, setAntesImage] = useState({imagem:null})
    const [depoisImage, setDepoisImage] = useState({imagem:null})

    const [formData, setFormData] = useState({
      conclusao:'',
      laudo_id:'',
      analise_id:'',
      cliente_id:'',
      anexo: 0,
      comentario: '',
      titulo_imagem_antes: '',
      titulo_imagem_depois: '',
      imagem_antes: '',
      imagem_depois: '',

    });
  
    const roleMapping = {
      eng: 'eng',
      lab: 'lab',
      admin: 'admin',
    };

    useEffect(() => {
      setFormData({
        ...formData,
        imagem_antes : antesImage.imagem
      });
    }, [antesImage]);

    useEffect(() => {
      setFormData({
        ...formData,
        imagem_depois : depoisImage.imagem
      });
    }, [depoisImage]);

    const filteredAnaliselookup = analiselookup ? analiselookup.filter(lookupItem => {
      return !analisesInclusas.some(inclusa => inclusa.analise.id === lookupItem.id);
    }) : [];

    async function getReports(id) {
      try {
        const response = await GenGet(`${roleMapping[role]}/openreports/complete/${id}`);
        console.log(response)
        setRelatorios(response.laudos);
        setRelatoriosLookup(response.laudo_lookup);
        
        const responseAnalise = await GenGet(`${roleMapping[role]}/client/analysis/all/${id}`);
        setAnalises(responseAnalise.analises);
        setAnaliseLookup(responseAnalise.analise_lookup);

      } catch (err) {
        console.error(err);
      }
    }
    
    async function getRequisitosAnalise() {
      try {
        const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
        setClienteLookup(response.clientes_lookup);
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    useEffect(() => {  
      if (cliente.id) {
        getReports(cliente.id);
      }

    }, [cliente]);

    useEffect(() => {
      getRequisitosAnalise();
    }, [role]);
 
    useEffect(() => {
      if (refresh){
        getRequisitosAnalise();
        if (cliente && cliente.id){
          getReports(cliente.id);
        }
        setRefresh(false);
      }
    }, [refresh]); 

    useEffect(() => {
      async function getAnaliseItens(id) {
          try {
            const response = await GenGet(`${roleMapping[role]}/analysis/data/${id}`);
            setAmostras(response);
          } catch (err) {
            //
          }
        }
  
        if(analise && analise.id){
          getAnaliseItens(analise.id);
        }
      
    }, [analise]);

    async function getAnalisesInclusas(id) {
      try {
        const response = await GenGet(`${roleMapping[role]}/report/analyses/${id}`);
        setAnalisesInclusas(response);
        
      } catch (err) {
      }
    }

    useEffect(() => { 
        if(relatorio && relatorio.id){
          getAnalisesInclusas(relatorio.id);
        }
      
    }, [relatorio]);
    
    const handleChangeCliente = (id, nome) => {
      let registro = data.clientes.find(item => item.id === id);
      setCliente(registro ? registro : {});
  
      setFormData(prevFormData => ({
        ...prevFormData,
        cliente_id: registro ? id : '',
      }));
    };
  
    const handleField = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
  
    const handleChangeAnalise = (id, nome) =>{
      let registro = analises.find(item => item.id === id);
      setFormData({
        ...formData,
        analise_id : registro.id
      });
      
      setAnalise({
        id: registro.id,
        cod_id : registro.cod_id,
        nome : registro.nome,
        origem_coleta : registro.origem_coleta,
        responsavel_coleta : registro.responsavel_coleta,
        data_inclusao : registro.data_inclusao,
        telefone_responsavel : registro.telefone_responsavel,
        tipo_amostra : registro.tipo_amostra,
        cliente_id : registro.cliente_id,
      });
    }

    const handleChangeRelatorio = (id, nome) =>{
      let registro = relatorios.find(item => item.id === id);
      setFormData({
        ...formData,
        laudo_id : registro.id
      });
      setRelatorio(registro);
    }

    const handleInserirImagem = () => {

      if (!inserirImagem){
        setinserirImagem(true);
        setFormData({
          ...formData,
          anexo : 1
        });

      }  else {
        setinserirImagem(false);
        setFormData({
          ...formData,
          anexo : 0,
          comentario: '',
          titulo_imagem_antes: '',
          titulo_imagem_depois: '',
        });
        setAntesImage({imagem:null});
        setDepoisImage({imagem:null});
      }
      
    };

    const handleInserirAnalise = () => {
      if (inserirAnalise) {
        setAmostras(null);
      }
      setinserirAnalise(!inserirAnalise);
    };
  


    const handlePost = () =>{
        async function postData() {
          setPostIsLoading(true)
          try {
            const response = await GenPostMultiform(`${roleMapping[role]}/addreport`, formData);
            console.log(response)
            setAmostras(null);
            setFormData({
              conclusao:'',
              laudo_id:'',
              analise_id:'',
              cliente_id:'',
              anexo: 0,
              comentario: '',
              titulo_imagem_antes: '',
              titulo_imagem_depois: '',
              imagem_antes: '',
              imagem_depois: '',
            })
            setMsg(response.data.message);
            setTimeout(() => {
                setMsg(null);
              }, 3000);
            setRefresh(true);
            setPostIsLoading(false)

          } catch (err) {
           // console.log(err)
            setMsg(err.message);
            setTimeout(() => {
                setMsg(null);
              }, 3000);
              setPostIsLoading(false)
          }
        }
        postData();
      }

    const handleEmitirLaudo = (id) =>{
      async function saveLaudo(id) {
        setIsLoading(true);
        try {
          const response = await GenPostMultiform(`${roleMapping[role]}/report/save/${id}`, formData);

          setMsg(response.message);
          setTimeout(() => {
              setMsg(null);
            }, 3000);
          setRelatorio(null);
          setAnalisesInclusas([]);
          setRefresh(true);

        } catch (err) {
          setMsg(err.message);
          setTimeout(() => {
              setMsg(null);
            }, 3000);
        }
      }
      saveLaudo(id);
    }

      const handleCloseMenu = () => {
        setVerAnalise(false);
        setAnalisePointer(null);
        setEditMode(false);
      };

      const handleOpenMenu = (id, tipo) => {
        setAnalisePointer(id);
        setVerAnalise(true);
        if (tipo === 'editar'){
          setEditMode(true);
        } else {
          setEditMode(false);
        }
        
      };

      const handleCloseRelatorio = () => {
        setVerRelatorio(false);
        setRelatorioPointer(null);
      };

      const handleOpenRelatorio = (id) => {
        setRelatorioPointer(id);
        setVerRelatorio(true);
      };

      const format_date =(item, tipo) => {
        const obj = new Date(item);
        const dia = String(obj.getDate()).padStart(2, '0'); // Dia
        const mes = String(obj.getMonth() + 1).padStart(2, '0'); // Mês (0-11, por isso +1)
        const ano = String(obj.getFullYear()).slice(-2); 
        const dataFormatada = `${dia}/${mes}/${ano}`;
        
        const hora = obj.toTimeString().split(' ')[0];
        if (tipo === 'data' && item !== '---'){
          return dataFormatada;
        } else if (item == '---') {
          return item;
        } else {
          return hora;
        } 
      }

    return (
      <div className='flex flex-col gap-2 mt-1 p-2'>
        <p className='text-[9pt] -mt-2'>Gerencíe os relatórios.</p>
        {!isLoading ? ( 
          <div>
            {clienteLookup && !isLoading && (
              <CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente} />
            )}
            {cliente.id && (
              <div className='flex flex-col'>
                <div className='p-4 rounded-md border-[1px]'>
                <p className='p-0 m-0 font-semibold'>Identificação:</p>
                  <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p>
                  <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
                  <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
                  <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && (`ramal:${cliente.ramal}`)}</p>
                  <br/>
                  <p className='p-0 m-0 font-semibold'>Localização:</p>
                  <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
                  <p className='p-0 m-0'>Cidade: {cliente.cidade_matriz} - Estado:{cliente.estado_matriz} - País: {cliente.pais_matriz}</p>
                </div>
                <hr/>
      
                <div className='flex flex-col p-4'>


                  {relatoriosLookup && !isLoading && (
                  <div className='flex flex-row justify-between w-full'>
                    <div className='flex flex-col w-full'>
                      <p className='p-0 m-0 font-semibold'>Escolha um relatório:</p>
                      <CustomSelect search={true} label={'Relatórios'} data={relatoriosLookup} onChange={handleChangeRelatorio}/> 
                    </div>
                    {relatorio && (
                      <div className='flex flex-row gap-2'>
                        <div className='flex flex-row-reverse w-full'>
                          <button className='bg-blue-400 hover:bg-blue-500 w-[120px] admin-button' onClick={handleInserirAnalise}>Inserir Amostra</button>
                        </div>
                        <div className='flex flex-row-reverse w-full'>
                          <button className='bg-blue-400 hover:bg-blue-500 w-[120px] admin-button' onClick={()=>handleOpenRelatorio(relatorio.id)}>Ver Relatório</button>
                        </div>
                        <div className='flex flex-row-reverse w-full'>
                          <button className='bg-blue-400 hover:bg-blue-500 w-[220px] admin-button' onClick={()=>handleEmitirLaudo(relatorio.id)}>Emitir e Finalizar Laudo</button>
                        </div>
                      </div>

                    )}
                    <Dialog open={verRelatorio} onClose={handleCloseRelatorio} PaperProps={{
                      sx: {
                        width: '70%', 
                        maxWidth: 'none',
                      },
                    }}>
                      <DialogContent >
                        <VisualizarRelatorio role={role} id={relatorioPointer} />
                      </DialogContent>
                    </Dialog>
                  </div>
                  )}
                  
                    {analisesInclusas && (
                      <div className='max-w-[75vw] text-[9pt]'>
                        <hr/>
                        <p className='font-semibold'>Análises inseridas: </p>
                        {analisesInclusas.length <= 0 ? (
                          <p className='ml-4 font-semibold'>nenhuma análise foi inserida no relatório. </p>
                        ) : (
                          <div className='p-2 flex flex-col gap-2'>
                          {analisesInclusas.map(item => (
                            <div key={item.id} className='flex flex-row border rounded-sm p-1 justify-between'>
                              <p className='p-2 m-0'>{item.analise.cod_id} - {item.analise.nome}</p> 
                              <div className='flex flex-row gap-2'>
                                
                                <button className='px-2 hover:bg-slate-100 w-[100px] rounded-lg border' onClick={()=>handleOpenMenu(item.id, 'editar')}>Editar</button>
                                <button className='px-2 hover:bg-slate-100 w-[100px] rounded-lg border' onClick={()=>handleOpenMenu(item.id, 'visualizar')}>visualizar</button>

                              </div>
                              
                              <Dialog 
                                open={verAnalise} 
                                onClose={() => handleCloseMenu()}   
                                fullWidth
                                maxWidth={false} // Para permitir largura personalizada
                                PaperProps={{
                                  style: {
                                    width: '90%', // Largura de 90% da tela
                                    height: '98%', // Altura de 90% da tela
                                    maxWidth: 'none', // Remove o limite de largura padrão
                                  },
                                }}>
                                  <DialogContent>
                                      <Analise role={role} editMode={editMode} id={analisePointer} />
                                  </DialogContent>
                              </Dialog>                        
                            </div>
                            
                          ))}
                          </div>
                        )}
                        {analiselookup && relatorio && inserirAnalise && !isLoading && (
                          <div className='flex flex-col mt-4'>
                            <p className='p-0 m-0 font-semibold'>Selecione uma análise para o relatório:</p>
                            {filteredAnaliselookup.length > 0 ? (<CustomSelect search={true} label={'Análises'} data={filteredAnaliselookup} onChange={handleChangeAnalise}/>) : (<p>Nenhuma análise disponível</p>)}
                            
                          </div>)}

                          {amostras && (
                            <div className='max-w-[75vw] text-[9pt]'>
                                <hr/>
                                <p className='font-semibold'>Identificação da amostra: </p>
                                <p className=' text-sm m-0 p-0'><b>COD.ID: </b>{amostras.cod_id} | <b>Nome:</b> {amostras.nome} | <b>Data de inclusão:</b> {amostras.data_inclusao}</p>
                                <hr/>
                                <div className='flex flex-row w-[100%] overflow-y-scroll'>
                                <div className='flex flex-col gap-2'>
                                        <div className='flex flex-row gap-4 border-b-[1px] h-[160px] bg-slate-50'>
                                            <div className='flex flex-col min-w-[230px] border-r justify-center'>
                                                <p className='m-0 p-1 font-semibold  text-[12pt] self-center'>ITEM</p>
                                            </div>
                                            <div className='flex flex-col min-w-[60px] border-r justify-center'>
                                                <p className='m-0 p-1 font-semibold text-[12pt]'>UN</p>
                                            </div>
                                            <div className='flex flex-col min-w-[60px] border-r justify-center'>
                                                <p className='m-0 p-1 font-semibold text-[12pt]'>L.Q</p>
                                            </div>
                                            <div className='flex flex-col min-w-[240px] border-r justify-center'>
                                                <p className='text-sm m-0 p-1 font-semibold self-center text-[12pt]'>METODO</p>
                                            </div>
                                        </div>   
                                    <div className='flex flex-col gap-2'>
                                    {amostras.amostras[0].amostra_items.map( item =>(
              
                                        <div className='flex flex-row gap-4 border-b-[1px] h-[60px] ' key={item.id}>
                                            <div className='flex flex-col min-w-[230px] border-r'>
                                                <p className='m-0 pl-2 font-semibold'>{item.elemento.nome}</p>
                                                <p className='text-sm m-0  pl-2 '>{item.elemento.descricao}</p>
                                            </div>
                                            <div className='flex flex-col min-w-[60px] border-r'>
                                                <p className='m-0 p-0'>{item.elemento.un}</p>
                                            </div>
                                            <div className='flex flex-col min-w-[60px] border-r'>
                                                <p className='m-0 p-0'>{item.elemento.LQ}</p>
                                            </div>
                                            <div className='flex flex-col min-w-[240px] border-r'>
                                                <p className='m-0 p-0'>{item.elemento.metodo}</p>
                                            </div>
                                        </div>                    
                                    ))}
                                    </div>
                                    </div>
                                    <div className='flex flex-col gap-2 bg-yellow-50'>
                                        <div className='flex flex-row gap-4 border-b-[1px] h-[160px]'>
                                            <div className='flex flex-col min-w-[230px] border-r justify-center text-[12pt]'>
                                                <p className='m-0 pl-2 font-semibold self-center'>Controle /Parametro</p>
                                                <p className='m-0 pl-2 self-center'>{amostras.sistema.nome}</p>
                                            </div>
                                        </div>   
                                    <div className='flex flex-col gap-2'>
                                    {amostras.amostras[0].amostra_items.map( item =>(
                                        <div className='flex flex-row gap-4 border-b-[1px] h-[60px]' key={item.id}>     
                                            <div className='flex flex-col min-w-[230px] border-r'>
                                                <p className='text-sm m-0 pl-2 text-center'>{amostras.indices.find(indice => indice.analise_amostra_item_id === item.elemento.id)?.valor || '-'}</p>
                                            </div>
                                        </div>                    
                                    
                                    ))}
                                    </div>
                                  </div>  
                                    {amostras.amostras.map( amostra =>(
                                        <div className='flex flex-col gap-2 min-w-[100px] border-r'  key={amostra.id}>
                                            <div className='flex flex-row gap-4 border-b-[1px] h-[160px] bg-slate-50'>
                                                <div className='flex flex-col w-full justify-center text-center'>
                                                    <p className='m-0 p-0 font-semibold'>Data Amostra</p>
                                                    <p className='m-0 p-0 border-b'>{format_date(amostra.data_amostra, 'data')}</p>
                                                    <p className='m-0 p-0 font-semibold'>Hora Amostra</p>
                                                    <p className='m-0 p-0 border-b'>{format_date(amostra.data_amostra, 'hora')}</p>
                                                    <p className='m-0 p-0 font-semibold'>nº amostra(LA)</p>
                                                    <p className='m-0 p-0 border-b'>{amostra.sequencia}</p>
                                                    <p className='m-0 p-0 font-semibold'>nº amostra</p>
                                                    <p className='m-0 p-0 border-b'>{amostra.n_amostra_la ? amostra.n_amostra_la : '----'}</p>
                                                </div>
                                            </div>    
                                            {amostra.amostra_items.map( item =>(
                                                <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]' key={item.id}>
                                                    <div className='flex flex-col min-w-[60px]'>
                                                        <p className='m-0 pl-2 text-center'>{item.valor === null ? '-' : item.valor.toFixed(2)}</p>
                                                    </div>
                                                </div>                    
                                            ))}
                                        </div> 
                                    ))}                         
              
                                </div>
                                <div className='flex flex-col mt-4'>
                                  <hr/>
                                  <div className='flex flex-row justify-between'>
                                  <p className='font-semibold'>Conclusão / Comentário / Recomendações</p> 
                                  <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleInserirImagem}>Analise de imagem</button>
                                  </div>
              
                                  <textarea
                                    className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                                    id="conclusao"
                                    name="conclusao"
                                    onChange={handleField}
                                    rows={8}
                                    placeholder="Conclusões"
                                    value={formData.conclusao}
                                  />

                                  <hr/>
                                  {inserirImagem && (
                                    <div className='flex flex-col mt-4'>
                                      <p className='p-o m-0 font-semibold text-[12pt]'>Análise Material:</p>
                                      <br/>
                                      <div className='flex flex-row justify-between'>
                                      <div className='flex flex-row w-full'>
                                        <PhotoWidgetSingle setData={setAntesImage} data={antesImage} semcapa={true} title={'Imagem Antes'}/>
                                        <div>
                                        <input
                                            name="titulo_imagem_antes"
                                            onChange={handleField}
                                            id="titulo_imagem_antes"
                                            value={formData.titulo_imagem_antes}
                                            placeholder='Título - Antes'
                                            className="px-4 py-2 mt-10 rounded border border-gray-300 focus:outline-none"
                                          />
                                        </div>
              
                                      </div>
              
                                      <div className='flex flex-row w-full'>
                                        <PhotoWidgetSingle setData={setDepoisImage} data={depoisImage} semcapa={true} title={'Imagem Depois'}/>
                                        <div>
                                        <input
                                            name="titulo_imagem_depois"
                                            onChange={handleField}
                                            id="titulo_imagem_depois"
                                            value={formData.titulo_imagem_depois}
                                            placeholder='Título - Depois'
                                            className="px-4 py-2 mt-10 rounded border border-gray-300 focus:outline-none"
                                          />
                                        </div>
                                      </div>
                                      </div>
              
                                      <textarea
                                        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                                        id="comentario"
                                        name="comentario"
                                        onChange={handleField}
                                        rows={8}
                                        placeholder="Comentário sobre amostragem sólida"
                                        value={formData.comentario}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className='w-full flex flex-row-reverse gap-2'>
                                      <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
                                      {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
                                      
                                      {msg && (
                                        <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
                                          <p className='m-0 p-0 text-gray-800'>{msg}</p>
                                        </div>
                                      )}
                                </div>
                                
                            </div>
                          )}



                      </div>
                    )}


                </div>
              </div>
            )} 
          </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}        
      </div>
    )
  }
  

export default Relatorios
{/* 
  

  
  */}