import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';

// Estilos para o layout
const styles = StyleSheet.create({
  pageVertical: {
    flexDirection: 'column',
    padding: 20,
    height: '100%',
  },
  pageHorizontal: {
    flexDirection: 'column',
    padding: 20,
    height: '100%',
  },
  header: {
    fontSize:'10pt',
    display:'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  mainLogoContainer: {    
    display:'flex',
    width: 70,
    height: 70,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  headerTextContainer: {    
    display:'flex',
    textAlign:'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow:1
  },
  headerText: {
    display:'flex',
    textAlign:'center',
    alignSelf:'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paginationContainer: {
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    zIndex:10
  },
  footer: {
    fontSize:'9pt',
    marginTop: 'auto',
    display:'flex',
    flexDirection: 'column',
  },
  rubricas: {
    display:'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  rubrica: {
    textAlign: 'center',
    width: '30%',
    borderTop: '1px solid #000',
  },
  tableContainer: {
    display:'flex',
    width:'100%',
    flexDirection: 'column',
    marginBottom: 10,
    padding: 10,
    border: '1px solid #000',
  },
  tableRow: {
    display:'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    border: '1px solid #000'
  },
  footerBottom: {
    display:'flex',
    width:'100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2,
  },
  miniLogo: {
    height: 14,
    objectFit: 'contain',
  },
});



// Componente PaginasLaudo
export default function PaginasLaudo({ type = 'vertical', children, logo, miniLogo, miniLogo2, analise, page, totalPages }) {
  const isVertical = type === 'vertical';

  return (
    <Page size={isVertical ? 'A4' : 'A4'} style={isVertical ? styles.pageVertical : styles.pageHorizontal} orientation={isVertical ? 'portrait': 'landscape'}>
      {/* Cabeçalho */}
      <View style={styles.header}>
        {/* Logo */}
        <View style={styles.mainLogoContainer}>
            <Image style={styles.logo} src={logo} />
        </View>
        

        {/* Texto no cabeçalho */}
        <View style={styles.headerTextContainer}>
          <Text style={styles.headerText}>(RAT)</Text>
          <Text style={styles.headerText}>(Relatório E Assistência Técnica)</Text>
        </View>

        {/* Paginação */}
        <View style={styles.paginationContainer}>
          <Text className={'text-[9pt] font-semibold'}>Página {page} de {totalPages}</Text>
          <Text className={'text-[9pt] font-semibold'}>03/09/2024</Text>
        </View>
      </View>

      {/* Conteúdo da página */}
        <View style={{ flexGrow: 1 }}>
            {children}
        </View>



      {/* Rodapé */}
      {!analise ? (
      <View style={styles.footer}>
      {/* Rubricas */}
      <View style={styles.rubricas}>
        <Text style={styles.rubrica}>RUBRICA CONTRATADA</Text>
        <Text style={styles.rubrica}>RUBRICA CONTRATANTE</Text>
        <Text style={styles.rubrica}>RUBRICA CLIENTE</Text>
      </View>

      {/* Tabela no rodapé */}
      <View style={styles.tableContainer}>
        <View style={styles.tableRow}>
          <Text>Código: DCR-000010-0000002 (DCR = Documento de comunicação e Registro</Text>
          <Text>Versão: 001</Text>
          <Text>Página: {page} de {totalPages}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text>Unn: IGUA CONTROL --</Text>
          <Text>Fone: (47) 99171-7022</Text>
          <Text>Data Emissão: -------</Text>
          <Text>Data Vigência: -------</Text>
          <Text>Próxima Revisão: -------</Text>
        </View>
      </View>

      {/* Dados da empresa no rodapé */}
      <View style={styles.footerBottom}>
          {/* Mini logo 1 */}
          <Image style={styles.miniLogo} src={miniLogo} />
          
          {/* Dados da empresa */}
          <View style={{display:'flex', flexDirection:'row', color:'blue', gap:10, fontSize:5, justifyContent:'center', alignItems:'center', textAlign:'center'}}>
              <Text style={{margin:0, padding:0}}>www.iguacontrol.com.br</Text>         
              <Text style={{margin:0, padding:0}}>IGUA CONTROL BRASIL / SIMPLIFICANDO SUA OPERAÇÃO</Text>
              <Text style={{margin:0, padding:0}}>sac.iguacontrol.com.br</Text>
          </View>
          {/* Mini logo 2 */}
          <Image style={styles.miniLogo} src={miniLogo2} />
        </View>
      </View>
      ) : (
        <View style={styles.footer}>
        {/* Rubricas */}
        <View style={{width:'100%', border:0.2, borderColor:'#000'}}>
          <Text style={{fontSize:5}}>LEGENDA:   SMEWW = Standard Methods for the Examination of Water and Wastewater, 22º. Edition – 2012. | RE-09 = Resolução RN 09 da ANVISA / MIIC = Metodologia Interna IGUA CONTROL | ABNT-NBR = Associação Brasileira de Normas Técnicas                NT = Norma Técnica  |  °Bx = Escala numérica de índice de refração ( Grau Brix ) | NMP = Numero Mais Provável | UFC = Unidades Formadoras de Colônias | NTU = Unidade de Turbidez | ND = Não detectado ou interferência | A.L = Acima limite de leitura | L.Q. = Limite de quantificação | VN3= Vide nota 3 | VN4= Vide nota 4 | VMR = Valor Máximo Recomendável | VP = Valores Permitidos | NA = Não Aplicável | NE = Não Estabelecido  | SRA = Sistema de Resfriamento Aberto, e ou  semi-aberto  | SRF =  Sistema Resfriamento Fechado   SGV =  Sistema Gerador de Vapor | SAP =  Sistema de Água Potável  | SRI = Sistema de Reuso Industrial  |  SAI = Sistema Água Industrial e ou Emulsão |  DXE = Desengraxante | SFO = Sistema de Filtração | C-001 = CONAMA 357 – Art.15 |  ALIM = Alimentação     REP = Reposição  |  ASGV = Alimentação SGV | ASRA = Alimentação SRA | INT.RTC = Vide Interpretação do Representante Técnico Comercial | CCi = Ciclo Concentração indicado | (*) Em Manutenção | (**) Em Regeneração | (***) Observações no relatório.</Text>
        </View>
    
        {/* Dados da empresa no rodapé */}
        <View style={styles.footerBottom}>
          {/* Mini logo 1 */}
          <Image style={styles.miniLogo} src={miniLogo} />
          
          {/* Dados da empresa */}
          <View style={{display:'flex', flexDirection:'row', color:'blue', gap:10, fontSize:5, justifyContent:'center', alignItems:'center', textAlign:'center'}}>
              <Text style={{margin:0, padding:0}}>www.iguacontrol.com.br</Text>         
              <Text style={{margin:0, padding:0}}>IGUA CONTROL BRASIL / SIMPLIFICANDO SUA OPERAÇÃO</Text>
              <Text style={{margin:0, padding:0}}>sac.iguacontrol.com.br</Text>
          </View>
          {/* Mini logo 2 */}
          <Image style={styles.miniLogo} src={miniLogo2} />
        </View>
        
        </View>
        
      )}

    </Page>
  );
}
