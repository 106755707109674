import axios from "Loja/state/axios";

async function AdminAuth() {
  try {
    const res = await axios.get('api/isadmin');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}
async function UserAuth() {
  try {
    const res = await axios.get('api/isauthenticated');

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    return false;
  }
}

async function CustomerAuth() {
  try {
    const res = await axios.get('api/iscustomer');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function LabAuth() {
  try {
    const res = await axios.get('api/islabusr');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function EngineerAuth() {
  try {
    const res = await axios.get('api/isengineer');  

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function CustomerCheckAuth() {
  let usrLog = false;
  try {
    usrLog = await CustomerAuth();
    
  } catch (error) {
    usrLog = false;
  }

  return usrLog;
}

async function AdminCheckAuth() {
  let usrLog = false;
  try {
    usrLog = await AdminAuth();
    
  } catch (error) {
    usrLog = false;
  }

  return usrLog;

}

async function EngineerCheckAuth() {
  let usrLog = false;
  try {
    usrLog = await EngineerAuth();
    
  } catch (error) {
    usrLog = false;
  }

  return usrLog;
}

async function LabCheckAuth() {
  let usrLog = false;
  try {
    usrLog = await LabAuth();
    
  } catch (error) {
    usrLog = false;
  }

  return usrLog;
}

async function UserIsAuth() {
  try {
    const usrLog = await UserAuth();
    return usrLog; 
  } catch (error) {

    return false;
  }
}

export { UserIsAuth ,AdminAuth, CustomerCheckAuth, AdminCheckAuth, EngineerCheckAuth, LabCheckAuth };
