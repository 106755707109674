import React, {useState, useEffect} from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import { CircularProgress } from '@mui/material';

function HistoricoAmostras({role}) {
    const [isLoading, setIsLoading] = useState(true);
    const [clienteLookup, setClienteLookup] = useState({});
    const [analiselookup, setAnaliseLookup] = useState({});
    const [data, setData] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [analise, setAnalise] = useState(null);
    const [amostras, setAmostras] = useState(null);
    const [analises, setAnalises] = useState([]);

    const roleMapping ={
        eng:'eng',
        lab:'lab',
        admin:'admin',
      }

      const handleChangeCliente = (id, nome) =>{
        let registro = data.clientes.find(item => item.id === id);
        setCliente(registro ? registro : {})
      }

      const handleChangeAnalise = (id, nome) =>{
        let registro = analises.find(item => item.id === id);
        setAnalise({
          id: registro.id,
          cod_id : registro.cod_id,
          nome : registro.nome,
          origem_coleta : registro.origem_coleta,
          responsavel_coleta : registro.responsavel_coleta,
          data_inclusao : registro.data_inclusao,
          telefone_responsavel : registro.telefone_responsavel,
          tipo_amostra : registro.tipo_amostra,
          cliente_id : registro.cliente_id,
        })
      }

      useEffect(() => {
        async function getRequisitosAnalise() {
            try {
              const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
              setClienteLookup(response.clientes_lookup)
              setData(response);
              setIsLoading(false);
            } catch (err) {
        
            }
          }
        getRequisitosAnalise();
      }, []);

      useEffect(() => {
        async function getAnalises(id) {
            try {
              const response = await GenGet(`${roleMapping[role]}/client/analysis/${id}`);
              setAnalises(response.analises)
              setAnaliseLookup(response.analise_lookup)
            } catch (err) {
        
            }
          }
    
          if (cliente && cliente.id){
            getAnalises(cliente.id);
          }
    
      }, [cliente]);

      useEffect(() => {
        async function getAnaliseItens(id) {
            try {
              const response = await GenGet(`${roleMapping[role]}/analysis/data/${id}`);
              console.log(response)
              setAmostras(response);
            } catch (err) {
            }
          }
    
          if(analise && analise.id){
            getAnaliseItens(analise.id);
          }
        
      }, [analise]);
      
  return (
    <div className='flex flex-col gap-2 mt-1 p-2 max-w-[80vw]'>
      <p className='text-[9pt] -mt-2'>Visualize as amostras e analises realizadas</p>
      {!isLoading ? ( 
        <div>
          <div className='flex flex-row gap-2'>
            {clienteLookup && !isLoading && (<CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente}/>)}
            {analiselookup && !isLoading && (<CustomSelect search={true} label={'Amostras'} data={analiselookup} onChange={handleChangeAnalise}/>)}
          </div>
          {amostras && (      
            <div>
                <hr/>
                <p className='font-semibold'>Identificação da amostra: </p>
                <p className=' text-sm m-0 p-0'><b>COD.ID: </b>{amostras.cod_id} | <b>Nome:</b> {amostras.nome} | <b>Data de inclusão:</b> {amostras.data_inclusao}</p>
                <hr/>
                <div className='flex flex-row w-[100%] overflow-y-scroll'>
                <div className='flex flex-col gap-2'>
                        <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]'>
                            <div className='flex flex-col min-w-[230px] border-r'>
                                <p className='m-0 p-0 font-semibold'>ITEM</p>
                            </div>
                            <div className='flex flex-col min-w-[60px] border-r'>
                                <p className='m-0 p-0 font-semibold'>UN</p>
                            </div>
                            <div className='flex flex-col min-w-[100px] border-r'>
                                <p className='m-0 p-0 font-semibold'>L.Q</p>
                            </div>
                            <div className='flex flex-col min-w-[300px] border-r'>
                                <p className='text-sm m-0 p-0 font-semibold'>METODO</p>
                            </div>
                        </div>   
                    <div className='flex flex-col gap-2'>
                    {amostras.amostras[0].amostra_items.map( item =>(

                        <div className='flex flex-row gap-4 border-b-[1px] h-[60px]'>
                            <div className='flex flex-col min-w-[230px]'>
                                <p className='m-0 p-0'>{item.elemento.nome}</p>
                                <p className='text-sm m-0 p-0'>{item.elemento.descricao}</p>
                            </div>
                            <div className='flex flex-col min-w-[60px]'>
                                <p className='m-0 p-0'>{item.elemento.un}</p>
                            </div>
                            <div className='flex flex-col min-w-[100px]'>
                                <p className='m-0 p-0'>{item.elemento.LQ}</p>
                            </div>
                            <div className='flex flex-col min-w-[300px] border-r'>
                                <p className='text-sm m-0 p-0'>{item.elemento.metodo}</p>
                            </div>
                        </div>                    
                    ))}
                    </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]'>
                            <div className='flex flex-col min-w-[230px] border-r'>
                                <p className='m-0 pl-2 font-semibold'>Controle /Parametro</p>
                                <p className='m-0 pl-2 '>{amostras.sistema.nome}</p>
                            </div>
                        </div>   
                    <div className='flex flex-col gap-2'>
                    {amostras.amostras[0].amostra_items.map( item =>(
                        <div className='flex flex-row gap-4 border-b-[1px] h-[60px]'>     
                            <div className='flex flex-col min-w-[230px] border-r'>
                                <p className='text-sm m-0 pl-2'>{amostras.indices.find(indice => indice.analise_amostra_item_id === item.elemento.id)?.valor || '-'}</p>
                            </div>
                        </div>                    
                    
                    ))}
                    </div>
                  </div>  
                    {amostras.amostras.map( amostra =>(
                        <div className='flex flex-col gap-2 min-w-[150px] border-r'>
                            <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]'>
                                <div className='flex flex-col min-w-[60px]'>
                                    <p className='m-0 pl-2 font-semibold'>Data Amostra</p>
                                    <p className='m-0 pl-2'>{amostra.data_amostra}</p>
                                </div>
                            </div>    
                            {amostra.amostra_items.map( item =>(
                                <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]'>
                                    <div className='flex flex-col min-w-[60px]'>
                                        <p className='m-0 pl-2'>{item.valor === null ? '-' : item.valor.toFixed(2)}</p>
                                    </div>
                                </div>                    
                            ))}
                        </div> 
                    ))} 
                  
                </div>

            </div>
          )}
        </div>

      ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      )}     
      
      



    </div>
  )
}

export default HistoricoAmostras
