import React from 'react'
import { CssBaseline} from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequiredAuth, { RequiredAdminAuth, RequiredCustomerAuth, RequiredEngineerAuth, RequiredLabAuth } from 'Loja/components/RequireAuth';

import SiteRoot from "./Site";
import AdminRoot from "./Admin";
import CustomerRoot from 'Customer';
import EngineerRoot from 'Engineer';
import LabRoot from 'Lab';


function App() {


  return (
    <div className="App">

        <BrowserRouter>
            <CssBaseline />
            <Routes>
              <Route path="/*" element={<SiteRoot />} />
              
              {/*Customer Routes*/} 
              <Route element={<RequiredCustomerAuth/>}>       
                <Route path="/customer/*" element={<CustomerRoot />} />
              </Route>

              {/*Lab Routes*/} 
              <Route element={<RequiredLabAuth/>}>       
                <Route path="/lab/*" element={<LabRoot />} />
              </Route>
              
              {/*Eng Routes*/}
              <Route element={<RequiredEngineerAuth/>}>       
                <Route path="/engineer/*" element={<EngineerRoot />} />
              </Route>

              {/*Adimn Routes*/} 
              <Route element={<RequiredAdminAuth/>}>       
                <Route path="/admin/*" element={<AdminRoot />} />
              </Route>
                              
            </Routes>
           
        </BrowserRouter>

    </div>
  );
}

export default App;