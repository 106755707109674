import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useEffect, useState} from 'react';
import {  AdminAuth, UserIsAuth, CustomerCheckAuth, AdminCheckAuth, EngineerCheckAuth, LabCheckAuth  } from "context/Auth";
import { Box, CircularProgress } from "@mui/material";


export function RequiredAdminAuth(){   
  const location = useLocation();
  const [adminAllowed, setAdminAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await AdminCheckAuth();
        const Allowed = authResult;
        setAdminAllowed(Allowed);      
        setIsCheckingAuth(false);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return       (<Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if ( adminAllowed ) {
    return <Outlet />;

  } else {
    localStorage.removeItem("token");
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
}

export function RequiredLabAuth(){   
  const location = useLocation();
  const [labAllowed, setLabAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await LabCheckAuth();
        const Allowed = authResult;
        setLabAllowed(Allowed);      
        setIsCheckingAuth(false);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return       (<Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if ( labAllowed ) {
    return <Outlet />;

  } else {
    localStorage.removeItem("token");
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
}


export function RequiredEngineerAuth(){   
  const location = useLocation();
  const [engineerAllowed, setEngineerAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await EngineerCheckAuth();
        const Allowed = authResult;
        setEngineerAllowed(Allowed);      
        setIsCheckingAuth(false);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return       (<Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if ( engineerAllowed ) {
    return <Outlet />;

  } else {
    localStorage.removeItem("token");
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
}

export function RequiredCustomerAuth(){   
  const location = useLocation();
  const [customerAllowed, setCustomerAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await CustomerCheckAuth();
        const Allowed = authResult;
        setCustomerAllowed(Allowed);      
        setIsCheckingAuth(false);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return       (<Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if ( customerAllowed ) {
    return <Outlet />;

  } else {
    localStorage.removeItem("token");
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
}
