import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'Loja/state/axios';

import{
  Alert} from "@mui/material";
import { GenPost} from 'Loja/state/api';

const LOGIN_URL = 'api/login';

const Registrar = ({handleFormTypes}) =>{
  const[errMsg, setErrMsg] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null); 
  const [registrarForm, setRegistrarForm] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation:"",
  });

  const handleRegistrarForm = (e) =>{
    setRegistrarForm({
      ...registrarForm,
      [e.target.name]:e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try{
      const response = await GenPost('/api/register', registrarForm);
      localStorage.setItem('token' , response?.data?.token); 
      window.location.href = '/';
    
    } catch (error) {
      console.log(error);
    
    }       
  }

  return(
    <div className='flex flex-col justify-center align-middle items-center'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Criar conta
        </h3>
        {errMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="error" sx={{width:320}} > {errMsg} </Alert>
          </div>
        : null}

        {scssMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="success" sx={{width:320}} > {scssMsg} </Alert>
          </div>
        : null}

        <div className='flex flex-col gap-2 mt-1'>
        <div className='flex flex-col justify-between'>
          <label className=''>nome:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="name"
            name="name"
            value={registrarForm.name} 
            onChange={handleRegistrarForm}  
            />
          </div>
        <div className='flex flex-col justify-between'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={registrarForm.nome} 
            onChange={handleRegistrarForm}  
            />
        </div>
        <div className='flex flex-col justify-between'>
          <label className=''>senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="password"
            type="password"
            name="password"
            value={registrarForm.password} 
            onChange={handleRegistrarForm}  
            />
        </div>
        <div className='flex flex-col justify-between'>
          <label className=''>confirmar senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            value={registrarForm.password_confirmation} 
            onChange={handleRegistrarForm}  
            />
        </div>  

          <div className='w-full flex flex-row justify-end my-2'>
            <button
              className=' w-[120px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
            >
              <p className='text-[12pt]' onClick={handleSubmit}>Registrar</p>
            </button>
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Já tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Login')}> Entrar</span></p>
          </div>
        </div>
      </div>
  )
}

const Login = ({handleFormTypes}) =>{
  const navigate = useNavigate();
  const[errMsg, setErrMsg] = useState(null); 
  const[token, setToken] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null); 
  const [loginForm, setLoginForm] = useState({
    email:'',
    password:''
  });
  const handleLoginForm = (e) =>{
    setLoginForm({
      ...loginForm,
      [e.target.name]:e.target.value
    })
  }
  useEffect(()=> {
    getUser();
  },[])

  useEffect(()=>{
    if (token){
      navigate('/');
    }
  },[token])



  async function getUser(){
    await axios.get('/sanctum/csrf-cookie');
  }

const handleSubmit = async (e) => {
    e.preventDefault();
  
    try{
      const response = await axios({
        url: LOGIN_URL,
        method: 'POST',
        data: JSON.stringify(loginForm),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',},
        withCredentials:true,     
      });
      localStorage.setItem('token' , response?.data?.token); 
      setToken(response?.data?.token)   

        setScssMsg('Login com feito sucesso');
        setTimeout(() => {
          setScssMsg(null);
        }, 2000);
        window.location.reload()

    }catch (err) {
      console.log(err)
      setErrMsg('Falha em Login.')
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  };
  
  return(
    <div className='flex flex-col justify-center align-middle items-center'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Entrar
        </h3>
        {errMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="error" sx={{width:320}} > {errMsg} </Alert>
          </div>
        : null}

        {scssMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="success" sx={{width:320}} > {scssMsg} </Alert>
          </div>
        : null}

        <div className='flex flex-col gap-2 mt-1'>
        <div className='flex flex-col justify-between'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={loginForm.email} 
            onChange={handleLoginForm}  
            />
          </div>
        <div className='flex flex-col justify-between'>
          <label className=''>senha:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="password"
            name="password"
            type="password"
            value={loginForm.password} 
            onChange={handleLoginForm}  
            />
        </div>
          <div className='w-full flex flex-row justify-end'>
            <button
              className=' w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
            >
              <p className='text-[12pt]' onClick={handleSubmit}>Login</p>
            </button>
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Recuperar')}>esqueceu a senha?</p>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Não tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Registrar')}>Registrar</span></p>
          </div>
        </div>
      </div>
  )
}

const RecuperarSenha = ({handleFormTypes}) =>{
  const[errMsg, setErrMsg] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null);
  const [isLoading, setisLoading ] = useState(false); 
  const [recuperarForm, setRecuperarForm] = useState({
    email: "",
  });
  const handleRecuperarForm = (e) =>{
    setRecuperarForm({
      ...recuperarForm,
      [e.target.name]:e.target.value
    })
  }

  async function handleSubmit() { 
    try {
      setisLoading(true);
      const response = await GenPost(`api/recuperar-senha`, recuperarForm);
      setScssMsg(response.message);
        setisLoading(false);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
    } catch (err) {
        setErrMsg('Ops, Algo deu errado! Verifique o email que foi submetido.');
        setisLoading(false);
        setTimeout(() => {
          setErrMsg(null);
        }, 3000);
        
    }

  }

  return(
    <div className='flex flex-col'
      >
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Esqueceu a senha?
        </h3>
        <p className='font-semibold -mt-2 ml-2 text-[11pt]'>Vamos enviar um email de recuperação!</p>
        {errMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="error" sx={{width:320}} > {errMsg} </Alert>
          </div>
        : null}

        {scssMsg ? 
          <div  classname={'flex justify-end p-1'}>
            <Alert severity="success" sx={{width:320}} > {scssMsg} </Alert>
          </div>
        : null}

        <div className='flex flex-col gap-2 mt-1'>
        <div className='flex flex-col justify-between'>
          <label className=''>Digite seu email:</label>
          <input className={`min-w-[98%] bg-slate-300 rounded flex p-1.5`}                 
            id="nome"
            name="nome"
            value={recuperarForm.email} 
            onChange={handleRecuperarForm}  
            />
          </div>
        
          <div className='w-full flex flex-row justify-end mt-2 mb-2'>
            <button
              className=' h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
            >
              <p className='text-[12pt]'>Recuperar senha</p>
            </button>
          </div>
          <div className='flex flex-col w-full justify-end items-end'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Login')}>voltar para login</p>
            <p className='p-0 m-0 text-[10pt] font-semibold text-slate-700 '>Não tem uma conta?<span className='text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Registrar')}> Registrar</span></p>
          </div>
        </div>
      </div>
  )
}

export default function LoginForm() {
  const [formType, setFormType] = useState('Login')
  const handleFormTypes = (type) =>{
    setFormType(type)
  }

return (
  <div className='p-2 max-w-[400px]'>
    {formType === 'Login' ? (
      <Login handleFormTypes={handleFormTypes}/>
    ) : formType === 'Registrar' ? (
      <Registrar handleFormTypes={handleFormTypes}/>
    ) : (
      <RecuperarSenha handleFormTypes={handleFormTypes}/>
    )}
  </div>
);
}
