import React from 'react'
import foto2 from '../../assets/sobre2.jpg';
import foto1 from '../../assets/artigo1.jpg';
const StyledText = ({ text }) => {
    const hasBold = text.includes('!-t');
    const cleanedText = text.replace('!-t', '');
  
    return (
      <p className={`w-full text-wrap text-gray-600 text-[11pt] ${hasBold ? 'font-semibold' : ''}`}>
        {cleanedText}
      </p>
    );
  };

function Empresa() {
    const data =   { 
        title: 'Bem vindos à Igua Control.',
        content: [
          'Atuando fortemente no mercado de automação, equipamentos, produtos e serviços para tratamento de águas industriais e consumo, monitoramento do Ar e Água, através de seu quadro técnico altamente especializado e voltado à pesquisa e aplicação de tecnologia de ponta, vem desenvolvendo serviços cujas características mais importantes são :',
          'A grande estabilidade, a perfeita adaptação às variações operacionais, a preservação do meio ambiente e o alto índice de confiabilidade dos produtos e técnicas aplicadas em cada necessidade.',
          'Nosso compromisso inclui oferecer aos nossos clientes um modo funcional com o melhor custo benefício, nos seguimentos Industrial e Institucional sem violar nossos alicerces constituídos por cinco pilares.',
          '1º Preservar o meio ambiente e promover o crescimento sustentável.',
          '2º Manter serviços de qualidade com melhor custo.',
          '3º Fornecer produtos químicos com eficiência comprovada.',
          '4º Viabilizar a automação de dosagem, purgas, entre outros.',
          '5º Oferecer monitoramento analítico reconhecido.'
        ],
        fotos: [
          foto1,
          foto2
        ]
      }
      
  return (
    <div className='flex flex-col md:flex-row p-4 justify-center w-[100%] border rounded-md bg-gray-200 bg-opacity-45'>
        <div className='flex flex-col max-w-[450px] p-2'>
        <p className='font-semibold text-gray-800'>{data.title}</p>
        {data.content.map((item, index) => (
            <StyledText key={index} text={item} />
        ))}
        </div>
        <div className='flex flex-col gap-2 p-2 w-full'>
        {data.fotos.map((item, index) => (
            <img key={index} src={item} className='w-full rounded-md shadow-sm' />
        ))}
        </div>
    </div>
    )
}

export default Empresa