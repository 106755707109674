import React from 'react';
import { Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PaginasLaudo from './PaginasLaudo';
import Logo from 'Loja/assets/logo.png'
import Sustentavel from 'Admin/assets/igua_sustentavel.png';
import Idade from 'Admin/assets/selo_idade.png';

const appUrl = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem('token');

function Laudo({data}) {
  const totalPages = (data.analises.length * 2 ) + 2;
  console.log(data)
  const tableStyles = StyleSheet.create({
    container: {
        fontSize: 7,
        marginBottom: 10,
        width:'100%'
      },
      hr: {
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        marginVertical: 2,
      },
      boldText: {
        fontWeight: 'bold',
      },
      underlined: {
        fontWeight: 'bold',
        borderBottom:0.1,
      },
      smallText: {
        fontSize: 8,
      },
      section: {
        flexDirection: 'row',
        width: '100%',
      },
      column: {
        flexDirection: 'column',
        gap: 1,
      },
      row: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        backgroundColor: '#f1f5f9',
      },
      leg_data_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#e6d39d',
      },
      leg_hora_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#dbc78c',
      },
      leg_nrla_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#d0ba79',
      },
      leg_nr_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#c6af6a',
      },
      data_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#e6d39d',
      },
      hora_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#dbc78c',
      },
      nrla_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#d0ba79',
      },
      nr_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#c6af6a',
      },
      headerCell: {
        width: 80,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        alignContent:'center',
        textAlign: 'center',
      },
      headerCellSmall: {
        width: 35,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerCellMedium: {
        width: 70,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerItemCell: {
        width: 40,
        height:50,
        justifyContent: 'center',
        textAlign: 'center',
      },
      bodyItemCell: {
        width: 40,
        height:22,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerCellLarge: {
        width: 100,
        height:50,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCell: {
        width: 80,
        height:22,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        alignContent:'center',
        textAlign: 'center',
      },
      itemCellMedium: {
        width: 70,
        height:22,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCellSmall: {
        width: 35,
        height:22,
        display:'flex',
        borderWidth: 0.1,
        borderColor: '#000',
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCellLarge: {
        width: 100,
        height:22,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      yellowBackground: {
        backgroundColor: '#ffffcc',
      },
      footerCell: {
        width: 45,
        height:22,
        display:'flex',
        textAlign: 'center',
      },
      textCenter: {
        textAlign: 'center',
      },
      headerContainer: {
        width: '100%',
        display: 'flex',
        gap:1,
        flexDirection:'row'
      },
      headerContainerChildLeft: {
        width: '68%',
        display: 'flex',
        borderLeft:0.1,
        padding:1,
        borderColor: '#000',
        flexDirection:'column'
      },
      headerContainerChildRight: {
        width: '30%',
        padding:1,
        display: 'flex',
        flexDirection:'column'
      },
      headerRow: {
        width: '100%',
        display: 'flex',
        borderBottom:0.1,
        borderColor: '#000',
        flexDirection:'row',
        gap:4
      },
  })

  const comentsStyles = StyleSheet.create({
    container: {
        fontSize: 7,
        marginBottom: 10,
        width:'100%'
      },
      hr: {
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        marginVertical: 5,
      },
      headerTextContainer: {    
        display:'flex',
        textAlign:'center',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow:1
      },
      boldText: {
        fontWeight: 'bold',
      },
      underlined: {
        fontWeight: 'bold',
        borderBottom:0.1,
      },
      smallText: {
        fontSize: 8,
      },
      section: {
        flexDirection: 'row',
        width: '100%',
      },
      column: {
        flexDirection: 'column',
        gap: 1,
      },
      row: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        backgroundColor: '#f1f5f9',
      },
      leg_data_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#e6d39d',
      },
      leg_hora_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#dbc78c',
      },
      leg_nrla_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#d0ba79',
      },
      leg_nr_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        fontWeight: 'bold',
        borderBottom:0.1,
        backgroundColor: '#c6af6a',
      },
      data_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#e6d39d',
      },
      hora_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#dbc78c',
      },
      nrla_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#d0ba79',
      },
      nr_amostra_cell: {
        flexDirection: 'row',
        borderWidth: 0.1,
        borderColor: '#000',
        borderBottom:0.1,
        backgroundColor: '#c6af6a',
      },
      headerCell: {
        width: 80,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        alignContent:'center',
        textAlign: 'center',
      },
      headerCellSmall: {
        width: 35,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerCellMedium: {
        width: 70,
        height:50,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerItemCell: {
        width: 40,
        height:50,
        justifyContent: 'center',
        textAlign: 'center',
      },
      bodyItemCell: {
        width: 45,
        height:35,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      headerCellLarge: {
        width: 100,
        height:50,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCell: {
        width: 80,
        height:35,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        alignContent:'center',
        textAlign: 'center',
      },
      itemCellMedium: {
        width: 70,
        height:35,
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCellSmall: {
        width: 35,
        height:35,
        display:'flex',
        borderWidth: 0.1,
        borderColor: '#000',
        justifyContent: 'center',
        textAlign: 'center',
      },
      itemCellLarge: {
        width: 100,
        height:35,
        display:'flex',
        borderWidth: 0.1,
        justifyContent: 'center',
        textAlign: 'center',
      },
      yellowBackground: {
        backgroundColor: '#ffffcc',
      },
      footerCell: {
        width: 45,
        height:35,
        display:'flex',
        textAlign: 'center',
      },
      textCenter: {
        textAlign: 'center',
      },
      headerContainer: {
        width: '100%',
        display: 'flex',
        gap:1,
        flexDirection:'row'
      },
      headerContainerChildLeft: {
        width: '68%',
        display: 'flex',
        borderLeft:0.1,
        padding:1,
        borderColor: '#000',
        flexDirection:'column'
      },
      headerContainerChildRight: {
        width: '30%',
        padding:1,
        display: 'flex',
        flexDirection:'column'
      },
      headerRow: {
        width: '100%',
        display: 'flex',
        borderBottom:0.1,
        borderColor: '#000',
        flexDirection:'row',
        gap:4
      },
  })

  const format_date =(item, tipo) => {
    const obj = new Date(item);
    const dia = String(obj.getDate()).padStart(2, '0'); // Dia
    const mes = String(obj.getMonth() + 1).padStart(2, '0'); // Mês (0-11, por isso +1)
    const ano = String(obj.getFullYear()).slice(-2); 
    const dataFormatada = `${dia}/${mes}/${ano}`;
    
    const hora = obj.toTimeString().split(' ')[0];
    if (tipo === 'data' && item !== '---'){
      return dataFormatada;
    } else if (item === '---') {
      return item;
    } else {
      return hora;
    } 
  }

const Analise = ({ data, identidade }) => (
        <View style={tableStyles.container}>
          <View style={tableStyles.hr} />

          <View style={tableStyles.headerContainer}>
          <View style={tableStyles.headerContainerChildLeft}>
            <View style={tableStyles.headerRow}>
              <Text>CLIENTE: {identidade.cliente.razao_social}</Text>
              <Text>SIGLA: {identidade.cliente.nome}</Text>
              <Text>CÓD: {identidade.cliente.codigo_ref}</Text>
              <Text>CONTA: {identidade.cliente.codigo_ref}</Text>
            </View>
            <View style={tableStyles.headerRow}>
              <Text>ENDEREÇO: {identidade.cliente.endereco_matriz}</Text>
              <Text>CIDADE: {identidade.cliente.cidade_matriz}</Text>
            </View>
            <View style={tableStyles.headerRow}>
              
              <Text>CEP: {identidade.cliente.cep_matriz}</Text>
              <Text>ESTADO: {identidade.cliente.cidade_matriz}</Text>
              <Text>NAÇÃO: {identidade.cliente.pais_matriz}</Text>
            </View>
            <View style={tableStyles.headerRow}>
              <Text>CNPJ: {identidade.cliente.CNPJ}</Text>
              <Text>CONTATO: {identidade.responsavel_destino.name}</Text>
              <Text>DEP/FUNÇÃO: {identidade.responsavel_destino.cargo}</Text>
            </View>
            <View style={tableStyles.headerRow}>
            <Text>FONE: {identidade.cliente.telefone}</Text>
            <Text>EMAIL: {identidade.cliente.email}</Text>
            </View>
          </View>
          <View style={tableStyles.headerContainerChildRight}>
            <View style={tableStyles.headerRow}>
              <Text>RESP.ENSAIO: {identidade.parametros.nome_empresa}</Text>
            </View>
            <View style={tableStyles.headerRow}>
              <Text>CNPJ: {identidade.parametros.CNPJ}</Text>
              <Text>INS.MUN: -</Text>
            </View>
            <View style={{display:'flex', flexDirection:'column'}}>
              <View style={{width:'100%'}}>
                <Text>RESP.TÉCNICO: -</Text>
              </View>
              <View style={tableStyles.headerRow}>
                <Text>DOC.PROFISSIONAL: -</Text>
              </View>
            </View>
            <View style={tableStyles.headerRow}>
              <Text>RESP.PELA COLETA: {identidade.colaborador_responsavel.cargo}</Text>
            </View>
            <View style={tableStyles.headerRow}>
              <Text>NOME: {identidade.colaborador_responsavel.nome}</Text>
              <Text>FONE: {identidade.colaborador_responsavel.telefone}</Text>
            </View>
          </View>
          </View>


          <Text style={tableStyles.boldText}>Identificação da amostra: </Text>
          <Text style={[tableStyles.smallText, tableStyles.boldText]}>
            <Text style={tableStyles.boldText}>COD.ID: </Text>{data.analise.cod_id} | 
            <Text style={tableStyles.boldText}>Nome:</Text> {data.analise.nome} | 
            <Text style={tableStyles.boldText}>Data de inclusão:</Text> {data.analise.data_inclusao}
          </Text>
          <View style={tableStyles.row} />
          <View style={tableStyles.section}>
            <View style={tableStyles.column}>
              <View style={tableStyles.row}>
                <View style={tableStyles.headerCell}>
                  <Text style={tableStyles.boldText}>ITEM</Text>
                </View>
                <View style={tableStyles.headerCellSmall}>
                  <Text style={tableStyles.boldText}>UN</Text>
                </View>
                <View style={tableStyles.headerCellSmall}>
                  <Text style={tableStyles.boldText}>L.Q</Text>
                </View>
                <View style={tableStyles.headerCellMedium}>

                    <Text style={[tableStyles.boldText, styles.smallText]}>METODO</Text>
                </View>
                
              </View>
  
              {data.analise.amostras[0].amostra_items.map(item => (
                <View style={tableStyles.row} key={item.elemento.nome}>
                  <View style={tableStyles.itemCell}>
                    <Text style={{fontSize:7}}>{item.elemento.nome}</Text>
                    <Text style={{fontSize:6}}>{item.elemento.descricao}</Text>
                  </View>
                  <View style={tableStyles.itemCellSmall}>
                    <Text>{item.elemento.un}</Text>
                  </View>
                  <View style={tableStyles.itemCellSmall}>
                    <Text>{item.elemento.LQ}</Text>
                  </View>
                  <View style={tableStyles.itemCellMedium}>
                  

                  <Text style={{fontSize:6}}>{item.elemento.metodo}</Text>
                  
                    
                  </View>
                </View>
              ))}
            </View>

            <View style={[tableStyles.column, tableStyles.yellowBackground]}>
                <View style={tableStyles.row}>
                  <View style={[tableStyles.headerCell, tableStyles.textCenter]}>
                    <View style={{
                      flexDirection: 'column',
                      gap: 1,
                      display:'flex', 
                      justifyContent:'space-between'
                    }}>
                      <Text style={tableStyles.leg_data_amostra_cell}>Data Amostra</Text>
                      <Text style={tableStyles.leg_hora_amostra_cell}>Hora Amostra</Text>
                      <Text style={tableStyles.leg_nr_amostra_cell}>nº amostra</Text>
                      <Text style={tableStyles.leg_nrla_amostra_cell}>nº amostra(LA)</Text>
                      <Text style={[tableStyles.boldText, tableStyles.smallText]}>Controle /Parametro</Text>
                    </View>
                  </View>
                </View>
  
                {data.analise.amostras[0].amostra_items.map(item => (
                  <View style={tableStyles.row} key={item.elemento.id}>
                    <View style={tableStyles.itemCell}>
                      <Text style={tableStyles.textCenter}>
                        {data.analise.indices.find(indice => indice.analise_amostra_item_id === item.elemento.id)?.valor || '-'}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            <View style={tableStyles.row}>
          {data.analise.amostras.length < 12
            ? [...data.analise.amostras, ...Array(12 - data.analise.amostras.length).fill({
                data_amostra: '---',
                sequencia: '---',
                n_amostra_la: '---',
                amostra_items: [{ elemento: { id: Math.random() }, valor: '---' }]
              })].map((amostra, index) => (
                <View style={tableStyles.column} key={index}>
                  <View style={tableStyles.headerItemCell}>
                    <View style={tableStyles.column}>
                      <Text style={tableStyles.data_amostra_cell}>{format_date(amostra.data_amostra, 'data')}</Text>
                      <Text style={tableStyles.hora_amostra_cell}>{format_date(amostra.data_amostra, 'hora')}</Text>
                      <Text style={tableStyles.nrla_amostra_cell}>{amostra.sequencia}</Text>
                      <Text style={tableStyles.nr_amostra_cell}>{amostra.n_amostra_la ? amostra.n_amostra_la : '----'}</Text>
                      <Text>{data.analise.sistema.nome}</Text>
                    </View>
                  </View>
                  {amostra.amostra_items && amostra.amostra_items.map(item => (
                    <View style={tableStyles.row} key={item.elemento.id}>
                      <View style={tableStyles.bodyItemCell}>
                      <Text>{item.valor !== '---' ? (item.valor === null ? '-' : item.valor.toFixed(2)) : '---'}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              ))
            : data.analise.amostras.map((amostra, index) => (
                <View style={tableStyles.column} key={index}>
                  <View style={tableStyles.headerItemCell}>
                    <View style={tableStyles.column}>
                      <Text style={tableStyles.data_amostra_cell}>{format_date(amostra.data_amostra, 'data')}</Text>
                      <Text style={tableStyles.hora_amostra_cell}>{format_date(amostra.data_amostra, 'hora')}</Text>
                      <Text style={tableStyles.nrla_amostra_cell}>{amostra.sequencia}</Text>
                      <Text style={tableStyles.nr_amostra_cell}>{amostra.n_amostra_la ? amostra.n_amostra_la : '----'}</Text>
                      <Text>{data.analise.sistema.nome}</Text>
                    </View>
                  </View>
                  {amostra.amostra_items && amostra.amostra_items.map(item => (
                    <View style={tableStyles.row} key={item.elemento.id}>
                      <View style={tableStyles.bodyItemCell}>
                        <Text>{item.valor ? item.valor.toFixed(2) : '-'}</Text>
                      </View>
                    </View>
                  ))}
                </View>
            ))}

            </View>

          </View>
          </View>
  );

const Parecer = ({ data, identidade }) => (
        <View style={comentsStyles.container}>
          
          <View style={{marginBottom:4}}>
            <Text style={[comentsStyles.headerTextContainer, { backgroundColor: '#f1f5f9', padding: 5 }]}>CONCLUSÃO / COMENTÁRIOS / RECOMENDAÇÕES </Text>
            <View style={{padding:2, marginTop:5}}>
              <Text style={comentsStyles.boldText}>Identificação da amostra: </Text>
              <Text style={[comentsStyles.smallText, tableStyles.boldText]}>
                <Text style={comentsStyles.boldText}>COD.ID: </Text>{data.analise.cod_id} | 
                <Text style={tableStyles.boldText}>Nome:</Text> {data.analise.nome} | 
                <Text style={tableStyles.boldText}>Data de inclusão:</Text> {data.analise.data_inclusao}
              </Text>
            </View>

            <View style={tableStyles.hr} />
            <Text style={[comentsStyles.smallText, {padding:2, marginTop:5}]}>
              {data.conclusao}
            </Text>
          </View>
          {data.analise.anexos && (
            <View style={{marginBottom:4}}>
              <Text style={[comentsStyles.headerTextContainer, { backgroundColor: '#f1f5f9', padding: 5 }]}>ANÁLISE DE IMAGEM</Text>
              <View style={{display:'flex', border:0.1, flexDirection:'row', marginTop:5, width:'100%'}}>
                <View style={{display:'flex', border:0.1, flexDirection:'col', width:'30%'}}>
                  <View style={{display:'flex', justifyContent:'center', alignContent:'center', textAlign:'center'}}><Text style={[comentsStyles.boldText, {padding:2}]}>Análise material</Text></View>
                  <View style={{display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                    <Text>{data.analise.anexos.titulo_imagem_antes}</Text>
                    <Image src={`${appUrl}/api/files/private/amostras/${token}/${data.analise.anexos.imagem_antes}`} style={{width:'80%'}} />
                  </View>
                  <View style={{display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                    <Text>{data.analise.anexos.titulo_imagem_depois}</Text>
                    <Image src={`${appUrl}/api/files/private/amostras/${token}/${data.analise.anexos.imagem_depois}`} style={{width:'80%'}} />
                  </View>
                </View>
                <View style={{display:'flex', border:0.1, flexDirection:'col', width:'70%'}}>
                  <View style={{display:'flex', justifyContent:'center', alignContent:'center', textAlign:'center'}}><Text style={[comentsStyles.boldText, {padding:2}]}>Comentário sobre amostragem sólida</Text></View>
                  <View style={[comentsStyles.smallText, {padding:2, marginTop:5}]}>
                    <Text style={{padding:4, marginTop:5}}>{data.analise.anexos.comentario}</Text>
                  </View>
                </View>
              </View>

            </View>

          )}
        </View>
  );

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      padding: 10,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'center',
      backgroundColor: '#f1f5f9',
      width: '100%',
      marginBottom: 20,
    },
    title: {
      fontWeight: 600,
      fontSize: 14,
      margin: 0,
      padding: 0,
    },
    section: {
      flexDirection: 'column',
      fontSize: 13,
      gap: 5,
    },
    textSmall: {
      fontSize: 10,
    },
    textBold: {
      fontWeight: 'bold',
    },
    flexRow: {
      flexDirection: 'row',
    },
    flexCol: {
      flexDirection: 'column',
      width:'100%'
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    borderBottom: {
      borderBottom: '1px solid #e5e7eb',
    },
    fullWidth: {
      width: '100%',
    },
    marginTop4: {
      marginTop: 10,
    },
    padding0: {
      padding: 0,
    },
    padding1: {
      padding: 2,
    },
    padding2: {
      padding: 5,
    },
    pageBreak: {
      pageBreakBefore: 'always',
      marginVertical:8,
      borderBottom:1
    },
    selfCenter: {
      alignSelf: 'center',
    },
    bodyText : {
      fontSize:'12',
      marginBottom:4
    }
  });

  const identidade = {
    cliente : data.cliente,
    parametros : data.parametros,
    responsavel_destino: data.responsavel_destino,
    responsavel_origem: data.responsavel_origem,
    colaborador_responsavel: data.colaborador_responsavel
  }

  if ( data ){
    return (
      <Document>
        
        <PaginasLaudo type={'vertical'} children={
          <View style={styles.container}>
            <View style={[styles.flexRow, styles.justifyBetween]}>
              <Text style={[styles.textSmall, styles.textBold, styles.bodyText]}>A(o) {data.cliente.razao_social}</Text>
              <Text style={styles.textSmall}>
                <Text style={styles.textBold}>CNPJ: </Text>{data.cliente.CNPJ}
              </Text>
            </View>
            <View style={styles.borderBottom}></View>
            <View style={styles.flexRow}>
              <Text style={styles.textSmall}>
                <Text style={styles.textBold}>CÓD/SIGLA:</Text> {data.cliente.nome} <Text style={styles.textBold}>FONE:</Text> {data.cliente.telefone} - <Text style={styles.textBold}>RAMAL:</Text> {data.cliente.ramal}
              </Text>
            </View>
            <View style={styles.pageBreak}></View>

            <View style={styles.flexCol}>
              <View style={[styles.flexRow, styles.justifyBetween]}>
                <View style={styles.flexCol}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.textBold}>AT:</Text> {data.destinatario_cliente.name} {data.destinatario_cliente.sobrenome}
                  </Text>
                  <Text style={styles.textSmall}>
                    <Text style={styles.textBold}>E-mail:</Text> {data.destinatario_cliente.email}
                  </Text>
                </View>
                <View style={styles.flexCol}>
                  <Text style={styles.textSmall}>
                    <Text style={styles.textBold}>CARGO/DPTO:</Text> {data.destinatario_cliente.cargo}
                  </Text>
                  <Text style={styles.textSmall}>
                    <Text style={styles.textBold}>FONE:</Text> {data.destinatario_cliente.telefone}
                  </Text>
                </View>
              </View>
              <View style={styles.borderBottom}></View>

              {data.primeira_copia_destinatario && data.primeira_copia_destinatario.name && (
                <View style={[styles.flexRow, styles.justifyBetween]}>
                  <View style={styles.flexCol}>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>CC:</Text> {data.primeira_copia_destinatario.name} {data.primeira_copia_destinatario.sobrenome}
                    </Text>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>E-mail:</Text> {data.primeira_copia_destinatario.email}
                    </Text>
                  </View>
                  <View style={styles.flexCol}>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>CARGO/DPTO:</Text> {data.primeira_copia_destinatario.cargo}
                    </Text>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>FONE:</Text> {data.primeira_copia_destinatario.telefone}
                    </Text>
                  </View>
                </View>
              )}
              <View style={styles.borderBottom}></View>

              {data.segunda_copia_destinatario && data.segunda_copia_destinatario.name && (
                <View style={[styles.flexRow, styles.justifyBetween]}>
                  <View style={styles.flexCol}>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>CC:</Text> {data.segunda_copia_destinatario.name} {data.segunda_copia_destinatario.sobrenome}
                    </Text>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>E-mail:</Text> {data.segunda_copia_destinatario.email}
                    </Text>
                  </View>
                  <View style={styles.flexCol}>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>CARGO/DPTO:</Text> {data.segunda_copia_destinatario.cargo}
                    </Text>
                    <Text style={styles.textSmall}>
                      <Text style={styles.textBold}>FONE:</Text> {data.segunda_copia_destinatario.telefone}
                    </Text>
                  </View>
                </View>                
              )}
              <View style={styles.borderBottom}></View>

            </View>

            <View style={[styles.marginTop4, styles.flexRow, { backgroundColor: '#f1f5f9', padding: 5 }, styles.justifyBetween]}>
              <Text style={[styles.textSmall, styles.textBold]}>
                <Text style={[ styles.selfCenter]}>REF: {data.cod_ref}</Text> (RELATÓRIO ASSISTÊNCIA TÉCNICA - COD.CLIENTE - ANO - SEQUÊNCIA)
              </Text>
            </View>

            <View style={[styles.marginTop4, styles.flexCol, { padding: 2 }]}>
              <Text style={styles.selfCenter}>
                <Text style={[styles.textBold, styles.textSmall]}>SUMÁRIO</Text>
              </Text>
              <View style={[styles.padding2, styles.flexCol]}>
                <View style={[styles.flexRow, styles.justifyBetween, styles.borderBottom]}>
                  <Text style={styles.textSmall}>RESULTADO DE ANÁLISE</Text>
                  <Text style={styles.textSmall}>página 2 a {data.analises.length + 1}</Text>
                </View>
                <View style={[styles.flexRow, styles.justifyBetween, styles.borderBottom]}>
                  <Text style={styles.textSmall}>CONCLUSÃO / COMENTÁRIOS / RECOMENDAÇÕES</Text>
                  <Text style={styles.textSmall}>página {data.analises.length + 2} a {(data.analises.length * 2) + 1}</Text>
                </View>

                <View style={[styles.flexRow, styles.justifyBetween, styles.borderBottom]}>
                  <Text style={styles.textSmall}>CONSIDERAÇÕES FINAIS</Text>
                  <Text style={styles.textSmall}>página {totalPages} a {totalPages}</Text>
                </View>
              </View>
            </View>

            <View style={[styles.marginTop4, styles.flexCol, { padding: 2 }]}>
              <Text style={styles.bodyText}>
                <Text style={styles.textBold}>OBS:</Text> A reprodução deste documento só será permitida de forma integral, sem alterações e com a autorização formal da IGUA CONTROL.
              </Text>
              <Text style={styles.bodyText}>
                Sem mais para o momento, colocamo-nos à sua disposição para quaisquer esclarecimentos adicionais que se fizerem necessários e despedimo-nos.
              </Text>
            </View>

            <View style={[styles.marginTop4, styles.flexRow, { padding: 2, gap:1, marginTop:20, fontSize:10}, styles.justifyBetween]}>
              <View style={[styles.flexCol, { width: '50%'}]}>
                <Text style={[styles.textBold, styles.textSmall]}>Atenciosamente:</Text>
                <View style={[styles.borderBottom, styles.flexCol, {border:'1px'}]}>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex', minHeight:30 }]}>
                      <Text style={[styles.textBold, styles.textSmall]}>PARTE DO CONTRATADO:</Text>
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex', flexDirection:'row' }]}>
                      <View style={{ padding: 2, display:'flex', width:'20%' }}>
                        <Text style={[styles.textBold, styles.textSmall]}>CIA:</Text>
                      </View>
                      <View style={{ padding: 2, display:'flex', flexDirection:'column' }}>
                        <Text style={[styles.textBold, styles.textSmall]}>{data.parametros.nome_empresa}</Text>
                        <Text style={[styles.textBold, styles.textSmall]}>COD.UNN: 01 CNPJ:{data.parametros.CNPJ}</Text>
                      </View>
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>Nome:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.colaborador_responsavel.name}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>Cargo:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.colaborador_responsavel.cargo}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>DOCTO:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.colaborador_responsavel.valor_licenca}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>E-MAIL:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.colaborador_responsavel.email}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>FONE:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.colaborador_responsavel.telefone} Ramal:{data.colaborador_responsavel.ramal} </Text>
                    </View>                                              
                  </View>
                </View>
              </View>
              <View style={[styles.flexCol, { width: '50%'}]}>
                <Text style={[styles.textBold, styles.textSmall]}>Recebido:</Text>
                <View style={[styles.borderBottom, styles.flexCol, {border:'1px'}]}>
                  <Text style={[styles.borderBottom,{ padding: 2, display:'flex', minHeight:30 }]}>
                      <Text style={[styles.textBold, styles.textSmall]}>PARTE DO CONTRATANTE:</Text>
                  </Text>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex', flexDirection:'row', minHeight:30 }]}>
                      <View style={{ padding: 2, display:'flex', width:'20%' }}>
                        <Text style={[styles.textBold, styles.textSmall]}>CIA:</Text>
                      </View>
                      <View style={{ padding: 2, display:'flex', flexDirection:'column' }}>
                        <Text style={[styles.textBold, styles.textSmall]}>{data.cliente.nome}</Text>
                        <Text style={[styles.textBold, styles.textSmall]}>COD.UNN: 01 CNPJ:{data.cliente.CNPJ}</Text>
                      </View>
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>Nome:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.responsavel_origem.name}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row'}]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>Cargo:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.responsavel_origem.cargo}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row' }]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>DOCTO:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.responsavel_origem.valor_licenca}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row'}]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>E-MAIL:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.responsavel_origem.email}</Text>
                    </View>                                              
                  </View>
                  <View  style={[styles.borderBottom, { padding: 2, display:'flex',flexDirection:'row'}]}>
                    <View style={{ padding: 2, display:'flex', width:'20%'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>FONE:</Text>
                    </View>
                    <View style={{ padding: 2, display:'flex'}}>
                      <Text style={[styles.textBold, styles.textSmall]}>{data.responsavel_origem.telefone} Ramal:{data.responsavel_origem.ramal} </Text>
                    </View>                                              
                  </View>
                </View>
              </View>
            </View>
      </View>  
        } logo={Logo} page={1} totalPages={totalPages} miniLogo={Sustentavel} miniLogo2={Idade} />

        {/* Análises Amostras */}
        {data.analises.map((item, index) =>(
          <PaginasLaudo totalPages={totalPages} page={index + 2 } type={'horizontal'} children={<Analise data={item} identidade={identidade}/>} analise={true} logo={Logo} miniLogo={Sustentavel} miniLogo2={Idade} key={index}/>
        ))}
        
        
        {/* Análises Imagens */}
        {data.analises.map((item , index)=>(
          <PaginasLaudo totalPages={totalPages} page={data.analises.length + 2 + index} type={'vertical'} children={<Parecer data={item} identidade={identidade}/>} logo={Logo} miniLogo={Sustentavel} miniLogo2={Idade} key={index}/>
        ))}

        <PaginasLaudo type={'vertical'} children={
          <View style={styles.container}>
            <View style={styles.header}>
            <Text style={[styles.textSmall, styles.textBold]}>CONSIDERAÇÕES FINAIS</Text>
            </View>

          {/* Corpo do texto */}
          <View style={styles.body}>
            <Text style={[styles.bodyText, styles.marginTop4]}>
              O conteúdo deste documento e seus anexos são destinados exclusivamente às pessoas indicadas neste, vide página 01, e contém informações confidenciais e/ou legalmente protegidas. Este instrumento não representa necessariamente as políticas, práticas, intenções ou conclusões da IGUA CONTROL BRASIL LTDA. É expressamente vedado a qualquer pessoa, sem prévia e expressa autorização, ler, revelar, distribuir, divulgar, alterar, copiar, reproduzir ou, sob qualquer forma, utilizar o todo ou parte deste documento ou anexos a ele vinculado. Caso tenha recebido este por engano, queira, por gentileza, avisar imediatamente seu remetente e eliminar completamente do seu arquivo e ou sistema.
            </Text>
            <Text style={[styles.bodyText, styles.marginTop4]}>
              Os resultados referem-se tão somente às características próprias das respectivas amostras analisadas e não substituem ou invalidam resultados de amostras coletadas anteriormente.
            </Text>
            <Text style={[styles.bodyText, styles.marginTop4]}>
              Sem mais para o momento, colocamo-nos à sua disposição para quaisquer esclarecimentos adicionais que se fizerem necessários.
            </Text>
            <Text style={[styles.bodyText, styles.marginTop4]}>
              Atenciosamente, equipe IGUA CONTROL
            </Text>
          </View>

          <View style={ styles.marginTop4 }></View>

          {/* Administrador da Conta e Supervisão */}
          <View style={{display:'flex', flexDirection:'row', gap:40, marginTop:40}}>
            <View style={[styles.column, {padding:2, position:'relative'}]}>
              <Text style={[styles.textBold, styles.textSmall, {marginBottom:4}]}>Administrador da Conta:</Text>
              <Image src={`${appUrl}/api/files/private/assinaturas/${token}/${data.administrador_da_conta.signature.nome}`} style={{width:'40px', position: 'absolute', top:10, right:-20}}/>
              <View style={styles.column}>
                <Text style={{fontSize:'9pt'}}>{data.administrador_da_conta.name} {data.administrador_da_conta.sobrenome}</Text>
                <Text style={{fontSize:'9pt'}}>{data.administrador_da_conta.cargo}</Text>
                <Text style={{fontSize:'9pt'}}>{data.administrador_da_conta.email}</Text>
                <Text style={{fontSize:'9pt'}}>Telefone: {data.administrador_da_conta.telefone}</Text>
              </View>
            </View>
            <View style={[styles.column, {padding:2, position:'relative'}]}>
              <Text style={[styles.textBold, styles.textSmall, {marginBottom:4}]}>Supervisão:</Text>
              <Image src={`${appUrl}/api/files/private/assinaturas/${token}/${data.supervisor_da_conta.signature.nome}`} style={{width:'40px', position: 'absolute', top:10, right:-20}}/>
              <View style={styles.column}>
                <Text style={{fontSize:'9pt'}}>{data.supervisor_da_conta.name} {data.supervisor_da_conta.sobrenome}</Text>
                <Text style={{fontSize:'9pt'}}>{data.supervisor_da_conta.cargo}</Text>
                <Text style={{fontSize:'9pt'}}>{data.supervisor_da_conta.email}</Text>
                <Text style={{fontSize:'9pt'}}>Telefone: {data.supervisor_da_conta.telefone}</Text>
              </View>
            </View>
          </View>

          {/* Responsáveis Técnicos */}
          <View style={{display:'flex', flexDirection:'column', gap:4, marginTop:20}}>
            <Text style={[styles.textBold, styles.textSmall]}>Responsáveis Técnicos:</Text>
            <View style={{display:'flex', flexDirection:'row', fontSize:'9pt', gap:40}}>
              {data.tecnicos_responsaveis.map(item =>(
                <View style={[styles.column, {padding:2, position:'relative'}]}>
                  <Image src={`${appUrl}/api/files/private/assinaturas/${token}/${item.signature.nome}`} style={{width:'40px', position: 'absolute', top:5, right:-20}}/>
                  <Text>{item.name} {item.sobrenome}</Text>
                  <Text>{item.cargo}</Text>
                  <Text>{item.email}</Text>
                  <Text>Telefone: {item.telefone}</Text>
                </View>
              ))}
            </View>
          </View>
          </View>
        } page={totalPages} totalPages={totalPages} logo={Logo} miniLogo={Sustentavel} miniLogo2={Idade} />
      
      </Document>
    )
  } else {
    return (<p>Carregando...</p>)
  }

}

export default Laudo