import React, { useState, useEffect } from 'react';
import { Alert, CircularProgress} from "@mui/material";
import { GenPostMultiform } from 'Admin/state/api';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';

const NovoUsuario = ({role}) => {
  const [msg, setMsg] = useState(null);
  const [image3x4, setImage3x4] = useState({imagem:null})
  const [assinatura, setAssinatura] = useState({imagem:null})
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }
  
  const [formData, setFormData] = useState({
    name: "",
    sobrenome: "",
    email: "",
    password: "",
    password_confirmation: "",
    role:"",
    telefone: "",
    cpf:"",
    resp_tec: 0,
    cargo: "",
    sigla_licenca: "",
    valor_licenca: "",
    formacao_academica: "",
    validade_registro:"",
    validade_ART:"",
    setor_resp_tec:"",
    foto_3x4:null,
    user_signature : null
  });

  const newUser = {
    name: "",
    sobrenome: "",
    email: "",
    password: "",
    password_confirmation: "",
    role:"",
    telefone: "",
    cpf:"",
    resp_tec: 0,
    cargo: "",
    sigla_licenca: "",
    valor_licenca: "",
    formacao_academica: "",
    validade_registro:"",
    validade_ART:"",
    setor_resp_tec:"",
    foto_3x4:null,
    user_signature : null
  }

  useEffect(() =>{
    if (formData.resp_tec === 0){
      setFormData({
        ...formData,
        sigla_licenca: "",
        valor_licenca: "",
        formacao_academica: "",
        validade_registro:"",
        validade_ART:"",
        setor_resp_tec:"",
        foto_3x4:null,
        user_signature : null
      })
    }
  }, [formData.resp_tec])

  useEffect(() => {
    setFormData({
      ...formData,
      foto_3x4 : image3x4.imagem
    });
  }, [image3x4]);

  useEffect(() => {
    setFormData({
      ...formData,
      user_signature : assinatura.imagem
    });
  }, [assinatura]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    // Aplica a máscara para 10 ou 11 dígitos
    if (cleaned.length === 10) {
      return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
    } else if (cleaned.length === 11) {
      return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
    }
    return valor;
  };

  const formatarCpf = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    
    // Aplica a máscara de CPF (###.###.###-##) se o valor tiver 11 dígitos
    if (cleaned.length === 11) {
      return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    // Retorna o valor original se não houver 11 dígitos
    return valor;
  };

  const camposObrigatorios = [
    'name',
    'sobrenome',
    'email',
    'telefone',
    'cpf',
    'password',
    'password_confirmation'
  ];

  const camposObrigatoriosResponsavel = [
    'sigla_licenca',
    'valor_licenca',
    'validade_registro',
    'validade_ART',
    'setor_resp_tec',
    'foto_3x4',
    'user_signature'
  ];

  const validateForm = () => {
    return camposObrigatorios.every(key => formData[key] !== "");
  };
  
  const validateResponsavelForm = () => {
    return camposObrigatoriosResponsavel.every(key => formData[key] !== "" && formData[key] !== null);
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setMsg("Preencha todos os campos obrigatórios");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }      

    if ( formData.resp_tec === 1 ){
      
      if (!validateResponsavelForm()) {
        setMsg("Preencha todos os campos obrigatórios");
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        return;
      } 
    }

    if(formData.password !== formData.password_confirmation){
      setMsg("As senhas não conferem.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    async function PostForm() {
      try {
        setPostIsLoading(true);
        const response = await GenPostMultiform(`${roleMapping[role]}/newuser`, formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setFormData(newUser);
        setPostIsLoading(false);
      } catch (err) {
      setMsg('Erro ao incluir usuário.');
      setPostIsLoading(false);
      setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
  };



  return ( 
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Novo usuário</h3>
        <p className='text-[9pt] -mt-2'>cadastre um novo usuário no sistema</p>
        <hr/>
          <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
            <h3 className='text-[12pt]'>Dados de usuário</h3>
            <label className='mt-2 text-[10pt]'  >Nome de usuário *:</label>
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="name"
              name="name"
              placeholder="Nome de usuário *"
              value={formData.name} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Sobrenome *:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="sobrenome"
              name="sobrenome"
              placeholder="Sobrenome *"
              value={formData.sobrenome} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >CPF *:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cpf"
              name="cpf"
              placeholder="CPF *"
              value={formatarCpf(formData.cpf)} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Email Corporativo *:</label>            
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="email"
              name="email"
              placeholder="Email Corporativo *"
              value={formData.email} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Telefone Corporativo. *:</label>
            <input
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                id="telefone"
                name="telefone"
                maxLength={14}
                placeholder="Telefone (com DDD) digite apenas numeros. *"
                value={formatarTelefone(formData.telefone)}
                onChange={handleChange}
              />

            <label className='mt-2 text-[10pt]'  >Senha do usuário *:</label>
            <div className='flex relative'>
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none w-full`}                 
                id="password"
                name="password"
                placeholder="Senha do usuário *"
                type={showPassword ? "text" : "password"} 
                value={formData.password} 
                onChange={handleChange}  
                />
              <button
                type="button"
                onClick={toggleShowPassword}
                className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
              >
                {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
              </button>
            </div>

            <label className='mt-2 text-[10pt]'  >Confirme a Senha *:</label>
            <div className='flex relative'>
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none w-full`}                 
                id="password_confirmation"
                name="password_confirmation"
                type={showConfirmPassword ? "text" : "password"} 
                placeholder="Confirme a Senha *"
                value={formData.password_confirmation} 
                onChange={handleChange}  
                />
                <button
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
                >
                  {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
                </button>
            </div>

            <label className='mt-4 text-[12pt]'>Módulo de Acesso:</label>
              <select
                className={`px-4 py-2 rounded border bg-white border-gray-300 focus:outline-none cursor-pointer`}
                aria-label='role'
                placeholder='Setor do Usuário'
                onChange={handleChange}
                id='role'
                name='role'
                value={formData.role}
              >
                <option value='CustomerUsr'>Cliente</option>
                <option value='LabUsr'>Laboratório</option>
                <option value='EngineerUsr'>Engenharía</option>
              </select>

            <div className='flex flex-row gap-4 w-full p-4 border rounded-md'>
            <p className='p-0 m-0 font-semibold text-gray-700'>Responsável técnico ?</p>
            <input 
              className={`p-0 m-0 rounded border border-gray-300 focus:outline-none`}                 
              id="resp_tec"
              name="resp_tec"
              type='checkbox'
              checked={formData.resp_tec === 1} 
              onChange={(e) => handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked ? 1 : 0, // Atualiza para 1 se marcado, 0 se desmarcado
                }
              })}
            
              />
            </div>

            <hr/>

            <h3 className='text-[12pt]'>Dados Profissional</h3>

            <label className='mt-2 text-[10pt]'  >Cargo do Usuário:</label>            
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cargo"
              name="cargo"
              placeholder="Cargo do Usuário"
              value={formData.cargo} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Formação Academica:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="formacao_academica"
              name="formacao_academica"
              placeholder="Formação Academica"
              value={formData.formacao_academica} 
              onChange={handleChange}  
              />

            {formData.resp_tec === 1 && (
              <div className='flex flex-col gap-2'>
                <label className='mt-2 text-[10pt]'  >Setor de Resp.Técnica *:</label>                
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="setor_resp_tec"
                  name="setor_resp_tec"
                  placeholder="Setor de Resp.Técnica"
                  value={formData.setor_resp_tec} 
                  onChange={handleChange}  
                  />

                <label className='mt-2 text-[10pt]'>Orgão de Classe *:</label>                
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="sigla_licenca"
                  name="sigla_licenca"
                  placeholder="Orgão de Classe"
                  value={formData.sigla_licenca} 
                  onChange={handleChange}  
                  />

                <label className='mt-2 text-[10pt]'  >N° Registro *:</label>                
                <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="valor_licenca"
                  name="valor_licenca"
                  placeholder="N° Registro"
                  value={formData.valor_licenca} 
                  onChange={handleChange}  
                  />

                <div className='flex flex-row justify-between gap-4'>
                  <div className='flex flex-col w-full'>
                    <label>Data de Validade registro *:</label>
                      <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                        id="validade_registro"
                        name="validade_registro"
                        type={'date'}
                        value={formData.validade_registro} 
                        onChange={handleChange}  
                        />    
                    </div>
                    <div className='flex flex-col w-full'>
                      <label>Data de Validade da ART *:</label>
                      <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                        id="validade_ART"
                        name="validade_ART"
                        type={'date'}
                        value={formData.validade_ART} 
                        onChange={handleChange}  
                        />    
                    </div>
                  </div>
                  <h3 className='text-[14pt] font-semibold mt-4'>Imagens *:</h3>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-row w-full'>
                      <PhotoWidgetSingle setData={setImage3x4} data={image3x4} semcapa={true} title={'Foto 3x4'}/>
                    </div>              
                    <div className='flex flex-row w-full'>
                      <PhotoWidgetSingle setData={setAssinatura} data={assinatura} semcapa={true} title={'Visto Digital'}/>
                    </div>
                  </div>
              </div>
            )}

          </div>

          <div className='w-full flex flex-row-reverse pt-2'>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
          {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
          {msg ? 
            <div className='flex justify-end p-1' >
              <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </div>
          : null}
          </div>
    </div> 
  )
}

export default NovoUsuario;