import React, {useState, useEffect} from 'react'
import{
  Button,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Typography,
  } from "@mui/material";
import { Card } from 'react-bootstrap';
import { GetAvisos } from 'Admin/state/api';



function Avisos() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getAvisos() {
      try {
        const response = await GetAvisos();
        console.log(response);
        setData(response);
        setIsLoading(false);

      } catch (err) {
        console.log(err);
      }
    }
    getAvisos();
  }, []);

  return (
    <Box className='container' sx={{padding:5}}>
          {!isLoading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className='shadow'>
                <Box sx={{padding:1}} height="70vh">
                  <Typography variant='h6'>Painel de Avisos</Typography>
                  <hr/>
                  {Array.isArray(data) ? (
                    data.map(({ id, aviso, status }) => (
                      <div className='bg-slate-200 my-1 p-1'>
                        <Typography variant='body'>{aviso} - {status}</Typography>
                      </div>
                    ))
                  ) : (
                    <Typography variant='body'>{data}</Typography>
                  )}
                </Box>
              </Card>
            </Grid>
        </Grid>) : <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>}
        </Box>
  )
}

export default Avisos