import React, { useState, useEffect } from 'react';

function CustomSelect(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const values = Object.values(props.data); // Supondo que props.data é um array de objetos
  const [curVal, setCurVal] = useState('');

  useEffect(() => {
    // verifica valor de inicialização
    if (props.prevalue) {
      
      const preselectedItem = values.find(item => item.id === props.prevalue);
      if (preselectedItem) {
        setCurVal(preselectedItem.nome);
      }
    }
  }, [props.prevalue, values]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className={`relative ${props.className}`}>
      <button
        className={`bg-slate-100 px-4 py-2 rounded border border-gray-300 focus:outline-none ${props.className}`}
        onClick={toggleDropdown}
      >
        {curVal || props.label}
      </button>
      {isOpen && (
        <div className='absolute top-full left-0 bg-white border border-gray-300 shadow-lg max-h-[300px] overflow-x-hidden overflow-y-auto z-10'>
          {props.search && (
            <input
              type='text'
              id='pesquisar'
              name='pesquisar'
              placeholder='Pesquisar'
              className='px-4 py-2 border-b border-gray-300 focus:outline-none sticky-top'
              value={searchTerm}
              onChange={handleSearch}
            />
          )}
          <ul>
            {values
              .filter(item => {
                const valueString = item.nome || ''; // Garantir que `item.nome` é uma string
                return valueString.toLowerCase().includes(searchTerm.toLowerCase());
              })
              .map(item => (
                <li
                  key={item.id} // Usar `item.id` como chave
                  className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
                  onClick={() => {
                    props.onChange(item.id, item.nome); // Passar id e nome para `onChange`
                    setCurVal(item.nome);
                    setIsOpen(false);
                  }}
                >
                  {item.nome} {/* Renderizar o nome */}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomSelect;