import React from 'react'

function Software() {
  const data = {
    title: 'Área de atuação.',
    content: [
      {
        titulo: 'Tratamento de Água',
        conteudo: [
          'Sistemas de Resfriamento: (Torres, Trocadores de calor, Centrais de água gelada, etc.)',
          'Sistemas Geradores de Vapor e aquecimento: (Caldeiras, Boilers, etc.)',
          'Sistemas Tratamento Físico Químico e Biologico ( ETA, ETE, etc.) (ETA- Estação Tratamento Afluente , ETE- Estação Tratamento Efluente, etc.)',
        ]
      },
      {
        titulo: 'Produtos Químicos',
        conteudo: [
          'Linha Industrial: Anti-corrosivos, Anti-incrustantes, Dispersantes, Floculantes, Desincrustantes, Desengraxantes, Microbicidas, Microorganismos, Enzimas e bactérias.',
          'Linha Higiene e Limpeza: Detergentes, Desengordurantes, Desenfetantes, sabonetes, Impermeabilizantes, Bactericidas, Linha Automotiva, Neutralizadores de odor.',
        ]
      },
      {
        titulo: 'Serviços de Análises',
        conteudo: [
          'Análises Laboratoriais:Físico químico e microbiologia. Água industrial, Afluentes, Efluentes, Qualidade do ar.',
        ]
      },
      {
        titulo: 'Equipamentos',
        conteudo: [
          'Bombas dosadoras, Purgadores, Estações de corpo de prova, Filtros, ETA, ETE.'
        ]
      }
    ]
  };
  return (
    <div className='flex flex-col md:flex-row p-4 justify-center w-[100%] border rounded-md bg-gray-200 bg-opacity-45'>
    <div className='flex flex-col p-2'>
      <p className='font-semibold text-gray-800 text-[12pt]'>{data.title}</p>
      {data.content.map((item, index) => (
        <div key={index} className='p-2 w-full'>
          <p className='font-semibold'>{item.titulo}</p>
          {item.conteudo.map((conteudo, index) => (
            <p className='text-[10pt] text-wrap m-0 p-0' key={index}>{conteudo}</p>
          ))}
          <hr />
        </div>
      ))}
    </div>
  </div>
  )
}

export default Software