import {useEffect, useState} from 'react';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import EditarUsuario from '../forms/usuarios/EditarUsuario';
import { GenGet } from 'Admin/state/api';

function Usuarios({role}) {
  const [data, setData] = useState([]);
  const [pointer, setPointer] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }

  useEffect(() => {
    async function getUsuarios() {
      try {
        const response = await GenGet(`${roleMapping[role]}/usergrid`);
        setData(response);
        setIsLoading(false);

      } catch (err) {
        console.error('Error ao consultar usuários');
      }
    }
    getUsuarios();
  }, []);

  const handleModal = (id) =>{  
    const registro = data.find(item => item.id === id);
    if (registro) {
      setPointer(registro.id);
      setModalOpen(true);
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false);
    setPointer(null);
  };

  const columns = ['ID','Nome','Email', 'Tipo'];
  const actions = [{ action: 'Visualizar', function: handleModal }];

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
    <h3 className='text-[14pt]'>Usuários do sistema</h3>
    <p className='text-[9pt] -mt-2'>visualize e gerencie os usuários cadastrados. </p>
    <hr/>

      <Dialog open={modalOpen} onClose={handleCloseModal} PaperProps={{
        sx: {
          width: '70%', 
          maxWidth: 'none',
        },
      }}>
        <DialogContent >
          <EditarUsuario role={role} id={pointer} />
        </DialogContent>
      </Dialog>
    {!isLoading ? (
    <div className='flex flex-col w-full gap-4'>
      <GenericDataGrid rows={data} columns={columns} per_page={15} actions={actions} title="Registros"/>
    </div>
    ):(
      <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
        <CircularProgress color="success"/>
      </div>
    )}
    </div>
  );
}

export default Usuarios;