import React, { useState, useEffect } from 'react';
import { CircularProgress } from "@mui/material";
import { GetAdmin } from 'Admin/state/api';

const Admin = () => {
  const [data, setData] = useState([]);
  const [vendas, setVendas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getAdmin() {
      try {
        const response = await GetAdmin();
        setData(response);
        setVendas(response.vendas.todas);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    getAdmin();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h3 className='text-[14pt]'>Resumo Geral</h3>
      <p className='text-[9pt] -mt-2'>painel a ser implementado</p>
      {isLoading ? (
        <div className="flex justify-center mt-10 mb-10">
          <CircularProgress color="success" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
          <div className="shadow rounded bg-white p-4 col-span-1">
            <h2 className="text-[12pt] font-semibold">Vendas Hoje : {vendas.length}</h2>
          </div>
          <div className="shadow rounded bg-white p-4 col-span-1">
            <h2 className="text-[12pt] font-semibold">Total de Acessos <p className="ml-2">{data.marketing / 2}</p></h2>
          </div>

          <div className="shadow rounded bg-white px-4">
            <h2 className="text-[12pt] font-semibold">Resumo de Faturamento</h2>
            <hr />
            <div className="flex flex-col text-[10pt]">
              {Object.keys(data.faturamento).map((key) => (
                <div key={key}>
                  <h3 className="text-[12pt] mb-0">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                  <p className="ml-2">R$ {data.faturamento[key]}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="shadow rounded bg-white px-4">
            <h2 className="text-[12pt] font-semibold">Taxa de conversão %</h2>
            <hr />
            <div className="flex items-center justify-center">
              <h3 className="text-[18pt]">{data.conversao}%</h3>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default Admin;
