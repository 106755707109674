import { Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { GenPost, GenGet } from 'Admin/state/api';

function TiposSistemas({role}) {
  const [msg ,setMsg] = useState(null);
  const [sistemeMsg ,setSistemaMsg] = useState(null);

  const roleMapping = {
    eng: 'eng',
    lab: 'lab',
    admin: 'admin',
  };

  const [tipoFormData, setTipoFormData] = useState({
    nome: '',
    descricao: '',
  })

  const [itemFormData, setItemFormData] = useState({
    nome : '',
    descricao : '',
    un : '',
    LQ : '',
    metodo : '',
    custo : ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTipoFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeItem = (e) => {
    const { name, value } = e.target;
    setItemFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const handleItemSubmitItem = (e) => {
    e.preventDefault();

    async function PostForm() {
      try {
        const response = await GenPost(`${roleMapping[role]}/analysisitem/create`, itemFormData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);

        setItemFormData({
          nome: '',
          descricao: '',
          un: '',
          LQ: '',
          metodo: '',
          custo: '',
        });

      } catch (err) {
        setMsg('Erro ao incluir Item.');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
    
  };

  const handleSubmitSistema = (e) => {
    e.preventDefault();

    async function PostForm() {
      try {
        const response = await GenPost(`${roleMapping[role]}/analysistype/create`, tipoFormData);
        setSistemaMsg(response.message);
        setTimeout(() => {
          setSistemaMsg(null);
        }, 3000);

        setTipoFormData({
          nome: '',
          descricao: '',
        });

      } catch (err) {
        setSistemaMsg('Erro ao incluir análise.');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    PostForm();
    
  };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>     
      <p className='font-medium'>Novo tipo de sistema</p>
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="nome"
        name="nome"
        placeholder="Tipo do Sistema"
        value={tipoFormData.nome}
        onChange={handleChange}
        />    
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="descricao"
        name="descricao"
        placeholder="Descrição"
        value={tipoFormData.descricao}
        onChange={handleChange}
        />
      <div className='w-full flex flex-row-reverse pt-2'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmitSistema}>Cadastrar</button>
        {sistemeMsg ? (
          <div className='flex justify-end p-1'>
            <Alert severity="info" sx={{ width: 320 }}>{sistemeMsg}</Alert>
          </div>
        ) : null}
      </div>

      <hr/>
      <p className='font-medium'>Novo item de análise - (elemento químico)</p>
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="nome"
        name="nome"
        placeholder="Nome"
        value={itemFormData.nome}
        onChange={handleChangeItem}
        />    
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="descricao"
        name="descricao"
        placeholder="Descrição"
        value={itemFormData.descricao}
        onChange={handleChangeItem}
        />
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="un"
        name="un"
        placeholder="Un"
        value={itemFormData.un}
        onChange={handleChangeItem}
        />    
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="LQ"
        name="LQ"
        placeholder="L.Q"
        value={itemFormData.LQ}
        onChange={handleChangeItem}
        />
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="metodo"
        name="metodo"
        placeholder="Método"
        value={itemFormData.metodo}
        onChange={handleChangeItem}
        />
      <input
        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
        id="custo"
        name="custo"
        placeholder="Custo"
        value={itemFormData.custo}
        onChange={handleChangeItem}
        />

      <div className='w-full flex flex-row-reverse pt-2'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleItemSubmitItem}>Cadastrar</button>
        {msg ? (
          <div className='flex justify-end p-1'>
            <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default TiposSistemas
