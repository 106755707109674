import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArticlePage from 'Loja/components/compLoja/ArticlePage';
import Software from './Software';
import TratamentoAgua from './TratamentoAgua';
import Tecnologia from './Tecnologia';
import Img from 'Loja/assets/fundo_agua.png'

const leftContent = {
  title: 'Suporte',
  links: [ // Corrigir aqui para 'links'
    'SUPORTE E TECNOLOGIA',
    'TECNOLOGIA PARA TRATAMENTO DE ÁGUA',
  ]
};


const Components = [ Tecnologia, TratamentoAgua];
const breadCrumbs = [
  {
    title: 'Inicio',
    endpoint: '/'
  },
  {
    title: 'Suporte',
    endpoint: '/suporte'
  },
];

const Suporte = () => {
  const nav = useNavigate();
  return (
    <ArticlePage leftContent={leftContent} Components={Components} breadCrumbs={breadCrumbs} bg={Img}/>
  );
};

export default Suporte;