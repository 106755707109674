import React, {useState} from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import { Alert, CircularProgress } from '@mui/material';

function NovoColaborador({role, cliente_id}) {
    const [msg, setMsg] = useState(null);
    const [postIsLoading, setPostIsLoading] = useState(false);
    const roleMapping ={
      eng:'eng',
      lab:'lab',
      admin:'admin',
    }

    const [formData, setFormData] = useState({
        name: "",
        sobrenome: "",
        cpf: "",
        telefone: "",
        cargo: "",
        sigla_licenca: "",
        valor_licenca: "",
        formacao_academica: "",
        res_laudo: 0,
        email: "",
        cc_laudo: 0,
        cliente_id : cliente_id
    });
  
    const newUser = {
        name: "",
        sobrenome: "",
        cpf: "",
        telefone: "",
        cargo: "",
        sigla_licenca: "",
        valor_licenca: "",
        formacao_academica: "",
        res_laudo: 0,
        email: "",
        cc_laudo: 0,
        cliente_id : cliente_id
    }
  
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;

        if (type === 'checkbox') {
          setFormData({
            ...formData,
            [name]: checked ? 1 : 0
          });
        } else {

          setFormData({
            ...formData,
            [name]: value
          });
        }
      };
  
    const formatarTelefone = (valor) => {
      // Remove todos os caracteres não numéricos
      const cleaned = valor.replace(/\D/g, '');
      // Aplica a máscara para 10 ou 11 dígitos
      if (cleaned.length === 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
      } else if (cleaned.length === 11) {
        return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
      }
      return valor;
    };

    const formatarCpf = (valor) => {
      // Remove todos os caracteres não numéricos
      const cleaned = valor.replace(/\D/g, '');
      
      // Aplica a máscara de CPF (###.###.###-##) se o valor tiver 11 dígitos
      if (cleaned.length === 11) {
        return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }
      
      // Retorna o valor original se não houver 11 dígitos
      return valor;
    };

    const validateForm = () => {
      for (let key in formData) {
        if (formData[key] === "") {
          return false;
        }
      }
      return true;
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
    {/*
      if (!validateForm()) {
        setMsg("Preencha todos os campos");
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        return;
      }  
    
    */}
  
  
      async function PostForm() {

        setPostIsLoading(true);
        try {
          const response = await GenPost(`${roleMapping[role]}/new/employee`, formData);
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
          setFormData(newUser);
          setPostIsLoading(false);

        } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
        setPostIsLoading(false);  
        }
      }
      PostForm();
    };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
    <p className='text-[9pt] -mt-2'>Cadastre um novo colaborador no cadastro do cliente.</p>
    <hr />
    
    <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
        <h3 className='text-[12pt]'>Dados do Colaborador</h3>

        <label className='mt-2 text-[10pt]'  >Nome *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="name"
        name="name"
        placeholder="Nome"
        value={formData.name} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Sobrenome *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="sobrenome"
        name="sobrenome"
        placeholder="Sobrenome"
        value={formData.sobrenome} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >CPF *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="cpf"
        name="cpf"
        placeholder="CPF"
        value={formatarCpf(formData.cpf)} 
        onChange={handleChange}  
        />
        
        <label className='mt-2 text-[10pt]'  >Telefone *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="telefone"
        name="telefone"
        maxLength={14}
        placeholder="Telefone (com DDD) digite apenas números."
        value={formatarTelefone(formData.telefone)}
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Cargo *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="cargo"
        name="cargo"
        placeholder="Cargo"
        value={formData.cargo} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Orgão de Classe:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="sigla_licenca"
        name="sigla_licenca"
        placeholder="Sigla da Licença"
        value={formData.sigla_licenca} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >N° Registro:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="valor_licenca"
        name="valor_licenca"
        placeholder="Valor da Licença"
        value={formData.valor_licenca} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Formação Acadêmica:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="formacao_academica"
        name="formacao_academica"
        placeholder="Formação Acadêmica"
        value={formData.formacao_academica} 
        onChange={handleChange}  
        />

        <label className='mt-2 text-[10pt]'  >Email *:</label>
        <input 
        className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
        id="email"
        name="email"
        placeholder="Email"
        value={formData.email} 
        onChange={handleChange}  
        />

        <div className='flex flex-row gap-2 p-2 rounded-md border text-gray-400'>  
            <label className='mt-2 text-[10pt]'  >Destinatário Laudo?:</label>
            <input 
            className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
            id="res_laudo"
            name="res_laudo"
            type="checkbox"
            checked={formData.res_laudo === 1}
            onChange={handleChange}  
            />

        </div>
        <div className='flex flex-row gap-2 p-2 rounded-md border text-gray-400'> 
            <label className='mt-2 text-[10pt]'  >Recebe cópia?:</label> 
            <input 
            className='px-4 py-2 rounded border border-gray-300 focus:outline-none'                 
            id="cc_laudo"
            name="cc_laudo"
            type="checkbox"
            checked={formData.cc_laudo === 1}
            onChange={handleChange}  
            />
        </div>
    </div>

    <div className='w-full flex flex-row-reverse pt-2'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
        {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
        {msg ? 
        <div className='flex justify-end p-1'>
            <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
        </div>
        : null}
    </div>
    </div>

  )
}

export default NovoColaborador
