import React from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import Clientes from 'Admin/scenes/clientes/Index';
import Mensagens from 'Admin/scenes/mensagens/Index';
import Avisos from 'Admin/scenes/avisos/Index';
import LabPannel from 'Admin/scenes/newPannel/LabPannel';
import PainelClientes from 'Admin/scenes/clientes/PainelClientes';
import NovoCliente from 'Admin/scenes/clientes/NovoCliente';
import PainelAnalises from 'Admin/scenes/analises/PainelAnalises';
import NovaAnalise from 'Admin/scenes/analises/NovaAnalise';

function LabRoot() {

  return (
        <div>
          <Routes>
            <Route element={<LabPannel />}>
              <Route exact path="/" element={<Admin />} />              
              <Route path="/admin" element={<Navigate to="/admin" replace />} />              
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/mensagens" element={<Mensagens />} />
                              
              <Route path="/clientes" element={<Clientes role={'lab'}/>} />
              <Route path="/clientes/painel" element={<PainelClientes role={'lab'}/>} />
                <Route path="/cliente/novo" element={<NovoCliente role={'lab'} />} />  

              <Route path="/analises" element={<PainelAnalises role={'lab'} />} />
              <Route path="/analise/nova" element={<NovaAnalise role={'lab'} />} />

              <Route path="/*" element={<Navigate to="/admin" replace />} />
            </Route>
          </Routes>
    </div>
  );
}

export default LabRoot;