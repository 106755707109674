import React from 'react'
import Img1 from 'Loja/assets/img_doc_tratamento_de_agua.jpg'

function TratamentoAgua() {

  return (
    <div className='flex flex-col md:flex-row p-4 justify-center w-[100%] border rounded-md bg-gray-200 bg-opacity-45'>
    <img src={Img1} />
  </div>
  )
}

export default TratamentoAgua