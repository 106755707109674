import React,{ useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Typography, Drawer, useMediaQuery } from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ScienceIcon from '@mui/icons-material/Science';
import useLogout from "hooks/useLogout";
import {
    ExpandMore as ExpandMoreIcon,
    Person as PersonIcon,
    NotificationsNone as NotificationIcon,
    Email as EmailIcon
  } from "@mui/icons-material";

import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem 
} from "@material-ui/core";



const CustomerPannel = () => {
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isSmScreen = useMediaQuery('(max-width:900px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [title, setTitle] = useState('');
    const logout = useLogout()
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {

      }, [title]);

  return (
    <div className={'flex flex-row w-full h-[100vh] bg-[#1f1f1f] text-white'}>
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} style={{overflowY:'auto'}}  className={'no-scrollbar'}>
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            sx={{flexGrow: 1, maxWidth: 500, overflowY: 'auto', background:'#1f1f1f', color:'white' }}
            >
  
            <Typography m={1} ml={2}> <b>Painel de avisos</b></Typography>  

            <TreeItem  sx={{marginBottom:1}}
            onClick={() => {
            navigate('/customer/avisos');
            setTitle('Avisos');
            }}
            nodeId="2"
            label={
                <Box display={'flex'} gap={0.8}>
                <NotificationIcon />
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                AVISOS
                </Typography>
                </Box>
            }
            />

            <TreeItem  sx={{marginBottom:1}}
            nodeId="16"
            label={
                <Box display={'flex'} gap={0.8}>
                <ScienceIcon />   
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                OPERAÇÂO
                </Typography>
                </Box>
            }
            >
                <TreeItem nodeId="18"                
                label={
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                Laudos
                </Typography>
                } onClick={() => { navigate('/customer/laudos'); setTitle('Laudos')}} />
            </TreeItem>
        </TreeView>
        </Drawer>
        <div className={'h-[100vh] overflow-y-auto md:flex flex-col xs:hidden min-w-[200px] hidden'}>
            <Typography m={2} sx={{fontSize:'18px'}}> PAINEL ADMIN </Typography>
        <TreeView
            aria-label="multi-select"
            defaultCollapseIcon={<ExpandMoreIcon style={{color: 'white'}}/>}
            defaultExpandIcon={<ChevronRightIcon style={{color: 'white'}}/>}
            multiSelect
            className="no-scrollbar"
            sx={{flexGrow: 1, maxWidth: 500, overflowY: 'auto', background:'#1f1f1f' }}
            >

            
            <hr/>  
            <Typography m={1} ml={2}> <b>Painel de avisos</b></Typography>  

            <TreeItem  sx={{marginBottom:1}}
            onClick={() => {
            navigate('/customer/avisos');
            setTitle('Avisos');
            }}
            nodeId="2"
            label={
                <Box display={'flex'} gap={0.8}>
                <NotificationIcon />
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                AVISOS
                </Typography>
                </Box>
            }
            />

            <TreeItem  sx={{marginBottom:1}}
            nodeId="17"
            label={
                <Box display={'flex'} gap={0.8}>
                <ScienceIcon />   
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                OPERAÇÂO
                </Typography>
                </Box>
            }
            >
                <TreeItem nodeId="18"                
                label={
                <Typography variant="body1" sx={{ fontSize: '13px', fontWeight:'bold' }}>
                Laudos
                </Typography>
                } onClick={() => { navigate('/customer/laudos'); setTitle('Laudos')}} />
            </TreeItem>
        </TreeView>
        
        </div>

        <div className="flex flex-col h-[100vh] flex-grow text-white">
                <div className="h-[8vh] flex flex-row p-1">
                    <div className="flex w-full justify-between mt-[1px]">
                        <Box display={'flex'}>
                        {isSmScreen && (
                            <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                            {isDrawerOpen ? <CloseIcon style={{color: 'white'}}/> : <MenuIcon style={{color: 'white'}}/>}
                            </IconButton>
                            )} 
                        <Typography sx={{fontSize:'20px', marginLeft:2, marginTop:2}}><b>{title}</b></Typography>
                        </Box>
                            
                        <React.Fragment>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <Tooltip title="Account settings">
                                <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                >
                                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                                </IconButton>
                            </Tooltip>
                            </Box>
                            <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 1,
                                sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                                },
                            }}

                            >
                            <MenuItem onClick={handleClose}>
                            <Avatar /> Perfil
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={()=>{logout()}}>
                                <ListItemIcon>
                                <Logout fontSize="small" />
                                </ListItemIcon>
                                Sair
                            </MenuItem>
                            </Menu>
                        </React.Fragment>
                        {/* {renderAppBarUserMenu} */}

                    </div>
                </div>
                <div className="flex min-h-[92vh] overflow-y-scroll flex-col w-[100%] bg-white text-gray-900 p-2 rounded-lg">
                    <Outlet />
                </div>

        </div>
    </div> 
  );
};

export default CustomerPannel;