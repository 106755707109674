import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GenGet } from 'Admin/state/api';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Laudo from './Laudo';

function VisualizarRelatorio({role, id}) {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const roleMapping = {
        eng: 'eng',
        lab: 'lab',
        admin: 'admin',
      };
  
    useEffect(() => {
      async function getAnalise(id) {
        try {
          const response = await GenGet(`${roleMapping[role]}/completereport/${id}`);
          setData(response);
          console.log(response)
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
  
      if (role && id) {
          getAnalise(id);
      }
      
    }, [id]);

  
    {/* 
      Funçoes que Geram pdf   
    */}
    
    const enviarPDF = async (blob) => {
      const formData = new FormData();
      formData.append('file', blob, `relatorio-${id}.pdf`);

      try {
          const response = await fetch('SEU_ENDPOINT_AQUI', {
              method: 'POST',
              body: formData,
          });

          if (response.ok) {
              alert('PDF enviado com sucesso!');
          } else {
              alert('Erro ao enviar o PDF.');
          }
      } catch (error) {
          console.error('Erro ao enviar o PDF:', error);
      }
  };

    // Função para gerar e enviar o PDF
    const handleEnviarPDF = async () => {
        const pdfBlob = await new Promise((resolve) => {
            const pdfLink = PDFDownloadLink({
                document: <Laudo data={data} />,
                fileName: `relatorio-${id}.pdf`,
            });

            pdfLink.props.blob().then(resolve);
        });

        await enviarPDF(pdfBlob);
    };    
    
  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Visusalização de Laudo</h3>
        <p className='text-[9pt] -mt-2'>visualize o Laudo selecionado.</p>
        <hr/>
        {!isLoading && data && (
            <div>
                <PDFViewer width={'100%'} height={1200}>
                    <Laudo data={data} />
                </PDFViewer>
            </div>
        )}
    </div>
  )
}

export default VisualizarRelatorio