import React, { useState, useEffect } from 'react';
import { GenGet, GenPostMultiform } from 'Admin/state/api';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';
import { Alert, CircularProgress } from '@mui/material';
const token = localStorage.getItem('token');

function Analise({role, id, editMode}) {
    const appUrl = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState({});
    const [formData, setFormData] = useState({
        conclusao:'',
        laudo_id:'',
        analise_id:'',
        cliente_id:'', 
        anexo: 0,
        comentario: '',
        titulo_imagem_antes: '',
        titulo_imagem_depois: '',
        imagem_antes: '',
        imagem_depois: '',
    });

    const [antesImage, setAntesImage] = useState({imagem:null});
    const [depoisImage, setDepoisImage] = useState({imagem:null});
    const [inserirImagem, setinserirImagem] = useState(false);
    const [msg, setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const roleMapping = {
        eng: 'eng',
        lab: 'lab',
        admin: 'admin',
      };

    useEffect(() => {
        async function getAnalise(id) {
          try {
            const response = await GenGet(`${roleMapping[role]}/report/analyse/${id}`);

            setData(response);
            setFormData({

                conclusao: response.conclusao,
                laudo_id: response.laudo_id,
                analise_id: response.analise_id,
                cliente_id: response.cliente_id, 
                anexo: response.anexos ? 1 : 0,
                comentario:  response.anexos ? response.anexos.comentario : null,
                titulo_imagem_antes: response.anexos ? response.anexos.titulo_imagem_antes : null,
                titulo_imagem_depois: response.anexos ? response.anexos.titulo_imagem_depois : null,
                imagem_antes: null,
                imagem_depois: null,                
            });
            setinserirImagem( response.anexos ? true : false);
            setIsLoading(false);


          } catch (err) {
            console.error(err);
            setIsLoading(false)
          }
        }
    
        if (role && id) {
            getAnalise(id);
        }
        
      }, [id]);
      
      const handleField = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

      const handleInserirImagem = () => {

        if (!inserirImagem){
          setinserirImagem(true);
          setFormData({
            ...formData,
            anexo : 1
          });
  
        }  else {
          setinserirImagem(false);
          setFormData({
            ...formData,
            anexo : 0,
            comentario: '',
            titulo_imagem_antes: '',
            titulo_imagem_depois: '',
          });
          setAntesImage({imagem:null});
          setDepoisImage({imagem:null});
        }
        
      };

      const handleSubmit = () => {
    
        async function PostForm(id) {

          try {
            const response = await GenPostMultiform(`${roleMapping[role]}/report/update/analysis/${id}`, formData);

            setMsg(response.data.message);
            setTimeout(() => {
              setMsg(null);
            }, 3000);
    
          } catch (err) {

          setMsg(err.data.message);
          setTimeout(() => {
              setMsg(null);
            }, 3000);
          }
        }
        PostForm(id);
      };

      useEffect(() => {
        setFormData({
          ...formData,
          imagem_antes : antesImage.imagem
        });
      }, [antesImage]);
  
      useEffect(() => {
        setFormData({
          ...formData,
          imagem_depois : depoisImage.imagem
        });
      }, [depoisImage]);

      const format_date =(item, tipo) => {
        const obj = new Date(item);
        const dia = String(obj.getDate()).padStart(2, '0'); // Dia
        const mes = String(obj.getMonth() + 1).padStart(2, '0'); // Mês (0-11, por isso +1)
        const ano = String(obj.getFullYear()).slice(-2); 
        const dataFormatada = `${dia}/${mes}/${ano}`;
        
        const hora = obj.toTimeString().split(' ')[0];
        if (tipo === 'data' && item !== '---'){
          return dataFormatada;
        } else if (item == '---') {
          return item;
        } else {
          return hora;
        } 
      }

  return (
    <div className='flex flex-col gap-2 mt-1 p-2 overflow-x-scroll'>
        <p className='text-[9pt] -mt-2'>{editMode ? ('Editar analise de amostra.'):('Analise de amostra.')}</p>
        

        {!isLoading ? (     
        <div>
            {data && !isLoading && (
                <div className='max-w-[75vw] text-[9pt]'>
                            <hr/>
                            <p className='font-semibold'>Identificação da amostra: </p>
                            <p className=' text-sm m-0 p-0'><b>COD.ID: </b>{data.analise.cod_id} | <b>Nome:</b> {data.analise.nome} | <b>Data de inclusão:</b> {data.analise.data_inclusao}</p>
                            <hr/>
                            <div className='flex flex-row w-[100%] overflow-y-scroll'>
                            <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row gap-4 border-b-[1px] h-[160px] bg-slate-50'>
                                        <div className='flex flex-col min-w-[230px] border-r justify-center'>
                                            <p className='m-0 p-1 font-semibold  text-[12pt] self-center'>ITEM</p>
                                        </div>
                                        <div className='flex flex-col min-w-[60px] border-r justify-center'>
                                            <p className='m-0 p-1 font-semibold text-[12pt]'>UN</p>
                                        </div>
                                        <div className='flex flex-col min-w-[60px] border-r justify-center'>
                                            <p className='m-0 p-1 font-semibold text-[12pt]'>L.Q</p>
                                        </div>
                                        <div className='flex flex-col min-w-[240px] border-r justify-center'>
                                            <p className='text-sm m-0 p-1 font-semibold self-center text-[12pt]'>METODO</p>
                                        </div>
                                    </div>   
                                    
                                <div className='flex flex-col gap-2'>
                                {data.analise.amostras[0].amostra_items.map( item =>(
        
                                    <div className='flex flex-row gap-4 border-b-[1px] h-[60px] '>
                                        <div className='flex flex-col min-w-[230px] border-r'>
                                            <p className='m-0 pl-2 font-semibold'>{item.elemento.nome}</p>
                                            <p className='text-sm m-0  pl-2 '>{item.elemento.descricao}</p>
                                        </div>
                                        <div className='flex flex-col min-w-[60px] border-r'>
                                            <p className='m-0 p-0'>{item.elemento.un}</p>
                                        </div>
                                        <div className='flex flex-col min-w-[60px] border-r'>
                                            <p className='m-0 p-0'>{item.elemento.LQ}</p>
                                        </div>
                                        <div className='flex flex-col min-w-[240px] border-r'>
                                            <p className='m-0 p-0'>{item.elemento.metodo}</p>
                                        </div>
                                    </div>                    
                                ))}
                                </div>
                                </div>
                                <div className='flex flex-col gap-2 bg-yellow-50'>
                                    <div className='flex flex-row gap-4 border-b-[1px] h-[160px]'>
                                        <div className='flex flex-col min-w-[230px] border-r justify-center text-[12pt]'>
                                            <p className='m-0 pl-2 font-semibold self-center'>Controle /Parametro</p>
                                            <p className='m-0 pl-2 self-center'>{data.analise.sistema.nome}</p>
                                        </div>
                                    </div>   
                                <div className='flex flex-col gap-2'>
                                {data.analise.amostras[0].amostra_items.map( item =>(
                                    <div className='flex flex-row gap-4 border-b-[1px] h-[60px]'>     
                                        <div className='flex flex-col min-w-[230px] border-r'>
                                            <p className='text-sm m-0 pl-2 text-center'>{data.analise.indices.find(indice => indice.analise_amostra_item_id === item.elemento.id)?.valor || '-'}</p>
                                        </div>
                                    </div>                    
                                ))}
                                </div>
                            </div>        
                                {data.analise.amostras.map( amostra =>(
                                    <div className='flex flex-col gap-2 min-w-[100px] border-r'>
                                        <div className='flex flex-row gap-4 border-b-[1px] h-[160px] bg-slate-50'>
                                            <div className='flex flex-col w-full justify-center text-center'>
                                                <p className='m-0 p-0 font-semibold'>Data Amostra</p>
                                                <p className='m-0 p-0 border-b'>{format_date(amostra.data_amostra,'data')}</p>
                                                <p className='m-0 p-0 font-semibold'>Hora Amostra</p>
                                                <p className='m-0 p-0 border-b'>{format_date(amostra.data_amostra,'hora')}</p>
                                                <p className='m-0 p-0 font-semibold'>nº amostra</p>
                                                <p className='m-0 p-0 border-b'>{amostra.sequencia}</p>
                                                <p className='m-0 p-0 font-semibold'>nº amostra(LA)</p>
                                                <p className='m-0 p-0 border-b'>{amostra.n_amostra_la ? amostra.n_amostra_la : '----'}</p>
                                            </div>
                                        </div>    
                                        {amostra.amostra_items.map( item =>(
                                            <div className='flex flex-row gap-4 border-b-[1px] min-h-[60px]'>
                                                <div className='flex flex-col min-w-[60px]'>
                                                    <p className='m-0 pl-2 text-center'>{item.valor ? item.valor.toFixed(2) : '-'}</p>
                                                </div>
                                            </div>                    
                                        ))}
                                    </div> 
                                ))}                         
                
                            </div>
                </div>
            )}
            <div>
                <hr/>
                {editMode && (
                    <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleInserirImagem}>Analise de imagem</button>
                )}

                <p className='p-0 m-0 font-medium'>Conclusão:</p>
                
                {editMode ? (                              
                    <textarea
                        className={`px-4 py-2 rounded border border-gray-300 focus:outline-none w-full`}
                        id="conclusao"
                        name="conclusao"
                        onChange={handleField}
                        rows={8}
                        placeholder="Conclusões"
                        value={formData.conclusao}
                        />   
                ) : (
                    <p className='ml-4'>{formData.conclusao}</p>   
                )}
                
            </div>

            {data.anexos && inserirImagem && (
                <div>
                <hr/>
                <p className='font-semibold'> Análise Material</p>

                <div className='flex flex-row justify-between gap-2'>
                    <div className='flex flex-col w-[50%] gap-2'>
                        <div className='flex flex-col p-4 rounded-md border w-full '>
                            
                            {editMode ? (                              
                            <input
                                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                                id="titulo_imagem_antes"
                                name="titulo_imagem_antes"
                                onChange={handleField}
                                placeholder="Título Imagem Antes"
                                value={formData.titulo_imagem_antes}
                                />   
                            ) : (
                                <p className='ml-4'>{data.anexos.titulo_imagem_antes}</p>   
                            )}

                            {editMode ? (
                                <PhotoWidgetSingle setData={setAntesImage} data={antesImage} semcapa={true} title={'Imagem Antes'}/>
                            ) : (
                                <img src={`${appUrl}/api/files/private/amostras/${token}/${data.anexos.imagem_antes}`} className='max-h-[200px] max-w-[300px]' />
                            )}
                                
                        </div>                   
                    </div>
                    <div className='flex flex-col w-[50%]'>
                    <div className='flex p-4 flex-col rounded-md border w-full'>
                            {editMode ? (                              
                            <input
                                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                                id="titulo_imagem_depois"
                                name="titulo_imagem_depois"
                                onChange={handleField}
                                placeholder="Título Imagem Depois"
                                value={formData.titulo_imagem_depois}
                                />   
                            ) : (
                                <p className='ml-4'>{data.anexos.titulo_imagem_depois}</p>   
                            )}

                            {editMode ? (
                                <PhotoWidgetSingle setData={setDepoisImage} data={depoisImage} semcapa={true} title={'Imagem Depois'}/>
                            ) : (
                                <img src={`${appUrl}/api/files/private/amostras/${token}/${data.anexos.imagem_depois}`} className='max-h-[200px] max-w-[300px]' />
                            )}
                            
                    </div>
                    </div>


                    <hr/>

                </div>
                <div className='w-full p-4'>
                    <p className='p-0 m-0 font-medium'>Comentários considerações:</p>
                    {editMode ? (                              
                        <textarea
                            className={`px-4 py-2 w-full rounded border border-gray-300 focus:outline-none`}
                            id="comentario"
                            name="comentario"
                            onChange={handleField}
                            rows={8}
                            placeholder="Comentário"
                            value={formData.comentario}
                            />   
                    ) : (
                        <p className='ml-4'>{data.anexos.comentario}</p>    
                    )}

                    
                    </div>
                </div>
            )}
            {editMode && (
               <div className='w-full flex flex-row-reverse'>                     
                    <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Atualizar</button>  
                    {msg && (
                        <div className='flex justify-end p-1' >
                            <Alert severity="info" sx={{width:320}} > {msg} </Alert>
                        </div>
                    )}   
                </div>
            )}
        </div> 
        
        ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
        </div>
        )}

   
    </div>
  )
}

export default Analise

{/* 
  
    
    */}