import React, { useState, useEffect } from 'react';
import NovoParametroQuimico from './NovoParametroQuimico'
import { GenGet } from 'Admin/state/api';

function PainelSistema({role}) {
    const roleMapping = {
        eng: 'eng',
        lab: 'lab',
        admin: 'admin',
      };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <p className='text-[9pt] -mt-2 font-medium'>Gerencíe os parametros e indices químicos do sistema selecionado.</p>
      <NovoParametroQuimico role={role}/>
    </div>
  )
}

export default PainelSistema
