import React, { useState, useEffect } from 'react';
import { GenPost, GenGet } from 'Admin/state/api';
import { Alert, CircularProgress } from '@mui/material';

function EditarItem({role, id}) {
    const [msg ,setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const roleMapping = {
      eng: 'eng',
      lab: 'lab',
      admin: 'admin',
    };

    const [formData, setFormData] = useState({
        nome: '',
        descricao: '',
        un: '',
        LQ: '',
        metodo: '',
        custo: '',
      })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        async function PostForm() {
            try {
              const response = await GenPost(`${roleMapping[role]}/analysisitem/update/${id}`, formData);
              setMsg(response.message);
              setTimeout(() => {
                setMsg(null);
              }, 3000);
            
            } catch (err) {
              setMsg('Erro ao atualizar Item.');
              setTimeout(() => {
                setMsg(null);
              }, 3000);
            }
          }
          PostForm(); 
      };

      useEffect(() => {
        async function getRegistro(id) {
            try {
              const response = await GenGet(`${roleMapping[role]}/analysisitem/show/${id}`);
              console.log(response);
              setFormData({
                nome: response.nome,
                descricao: response.descricao,
                un: response.un,
                LQ: response.LQ,
                metodo: response.metodo,
                custo: response.custo,
              });

              setIsLoading(false);

              
            } catch (err) {
              console.error('Error ao consultar dados');

            }
          }

          getRegistro(id);
      }, []);

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'> 
      <p className='font-medium'>Atualize o item de análise - (elemento químico)</p>

      {!isLoading ? (
        <div  className='flex flex-col gap-2 mt-1 p-2'>        
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="nome"
            name="nome"
            placeholder="Nome"
            value={formData.nome}
            onChange={handleChange}
            />    
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="descricao"
            name="descricao"
            placeholder="Descrição"
            value={formData.descricao}
            onChange={handleChange}
            />
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="un"
            name="un"
            placeholder="Un"
            value={formData.un}
            onChange={handleChange}
            />    
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="LQ"
            name="LQ"
            placeholder="L.Q"
            value={formData.LQ}
            onChange={handleChange}
            />
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="metodo"
            name="metodo"
            placeholder="Método"
            value={formData.metodo}
            onChange={handleChange}
            />
          <input
            className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
            id="custo"
            name="custo"
            placeholder="Custo"
            type={'number'}
            value={formData.custo}
            onChange={handleChange}
            />

          <div className='w-full flex flex-row-reverse pt-2'>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Atualizar</button>
            {msg ? (
              <div className='flex justify-end p-1'>
                <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
              </div>
            ) : null}
          </div>
        </div>
       ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>          
      )}

      
    </div>
  )
}

export default EditarItem
