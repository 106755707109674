import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnvContext } from 'Site';
import { ChevronRight } from '@mui/icons-material';

function ArticlePage({ leftContent, breadCrumbs, Components, bg }) {
  const nav = useNavigate();
  const { appUrl } = useContext(EnvContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const StyledText = ({ text }) => {
    const hasBold = text.includes('!-t');
    const cleanedText = text.replace('!-t', '');
  
    return (
      <p className={`w-full text-wrap text-gray-600 text-[11pt] ${hasBold ? 'font-semibold' : ''}`}>
        {cleanedText}
      </p>
    );
  };

  const handleContent = (id) => {
    setActiveIndex(id);
  };

  const activeComponent = () => {
    const Component = Components[activeIndex]
    return <Component />
  }

  return (
    <div className='w-full flex flex-col text-gray-800 h-full bg-white min-h-[70vh]' 
    style={{
      backgroundImage: `url(${bg})`, // Usando a imagem de fundo
      backgroundSize: 'cover', // Ajustando a imagem para cobrir toda a área
      backgroundPosition: 'center', // Centralizando a imagem
      backgroundRepeat: 'no-repeat' // Não repetir a imagem
    }}>
      <div className='w-full flex flex-col md:flex-row border-gray-800 p-2'>
        <div className='flex flex-col min-w-[230px] w-full md:w-[230px] pr-2'>
          <h3 className='text-[22pt] font-extrabold text-gray-700 mb-2 p-2'>{leftContent.title}</h3>
          <hr />
          <div className='w-full flex flex-col gap-1 -ml-2'>
            {leftContent.links.map((item, index) => (
              <div
                className={`p-2 h-[60px] border rounded-e-full hover:bg-gradient-to-r from-[#3d507dff] to-[#6088e4] hover:text-gray-50 ${activeIndex === index ? 'shadow-md bg-gradient-to-r from-[#3d507dff] to-[#6088e4] text-gray-100 ' : ''}  cursor-pointer font-semibold`}
                key={index}
                onClick={() => handleContent(index)}
              >
                <p className='p-0 m-0'>{item}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col md:flex-row p-4 min-w-[300px]'>
        {activeComponent()}
        </div>
      </div>
    </div>
  );
}

export default ArticlePage;
