import React, { useState, useEffect } from 'react';
import Img from '../../assets/waterbg.jpeg';

function Apresentacao() {
  return (
    <div className="relative flex items-center justify-center w-full h-[250px] ">
      <div
        className="absolute inset-0 bg-cover bg-center-top bg-no-repeat"
        style={{
          backgroundImage: `url(${Img})`,
          backgroundPosition: 'center top',
        }}
      ></div>
      <div className="relative text-white text-center z-10">
        <p className="font-extrabold text-[12px] max-w-[600px] sm:text-[12px] md:text-[18px] lg:text-[22px]">
          Nosso compromisso inclui oferecer aos nossos clientes, um modo funcional com o melhor custo benefício,
          nos seguimentos Industrial e Institucional.
        </p>
      </div>
    </div>
  );
}

export default Apresentacao;