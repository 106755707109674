import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';
import { Alert, CircularProgress } from '@mui/material';

function NovoLaudo({role}) {
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [clienteLookup, setClienteLookup] = useState({});
  const [cliente, setCliente] = useState({});
  const [relatoriosAbertos, setRelatoriosAbertos] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const roleMapping = {
    eng: 'eng',
    lab: 'lab',
    admin: 'admin',
  };

  useEffect(() => {
    async function getRequisitosAnalise() {
      try {
        const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
        setClienteLookup(response.clientes_lookup);
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    getRequisitosAnalise();
  }, [role]);

  useEffect(() => {
    async function getRelatoriosAbertos(id) {

      try {
        const response = await GenGet(`${roleMapping[role]}/openreports/${id}`);
        console.log(response)
        setRelatoriosAbertos(response);
      } catch (err) {
        console.error(err);
      }
    }

    if (cliente.id) {
      getRelatoriosAbertos(cliente.id);
    }
    
  }, [cliente]);

  
  const handleChangeCliente = (id, nome) => {
    let registro = data.clientes.find(item => item.id === id);
    setCliente(registro ? registro : {});
  };

  
  const handlePost = () =>{
    async function postData() {
      try {
        const response = await GenPost(`${roleMapping[role]}/initiatereport`, {cliente_id: cliente.id})

        setMsg(response.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
        setRefresh(true);

      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
            setMsg(null);
          }, 3000);
      }
    }
    postData();
  }

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <p className='text-[9pt] -mt-2'>Inicialize um relatório</p>
      {!isLoading ? ( 
        <div>
          <div className='flex flex-row justify-between'>
          {clienteLookup && !isLoading && (
            <CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente} />
          )}
          {Number(relatoriosAbertos) > 0 && (
            <div className='flex justify-end p-1'>
              <Alert severity="warning" sx={{ width: 320 }}>
                Esse cliente possui {Number(relatoriosAbertos)} {Number(relatoriosAbertos) > 1 ? 'relatórios abertos' : 'relatório aberto'}
              </Alert>
            </div>
          )}
          </div>

          {cliente.id && (
            <div className='flex flex-col'>
              <div className='p-4 rounded-md border-[1px]'>
              <p className='p-0 m-0 font-semibold'>Identificação:</p>
                <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p>
                <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
                <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
                <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && (`ramal:${cliente.ramal}`)}</p>
                <br/>
                <p className='p-0 m-0 font-semibold'>Localização:</p>
                <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
                <p className='p-0 m-0'>Cidade: {cliente.cidade_matriz} - Estado:{cliente.estado_matriz} - País: {cliente.pais_matriz}</p>
              </div>
              <div className='w-full flex flex-row-reverse p-2'>
              <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Inicializar Relatório</button>
              {msg && (
                  <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
                      <p className='m-0 p-0 text-gray-800'>{msg}</p>
                  </div>
              )}
              </div>
            </div>
          )}   
        </div>
      ) : ( 
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      )}      



    </div>
  )
}

export default NovoLaudo
