import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import Relatorio from './Relatorio';
import { CircularProgress } from '@mui/material';

function Laudos({role}) {
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [clienteLookup, setClienteLookup] = useState({});
  const [cliente, setCliente] = useState(null);
  const [relatorios, setRelatorios] = useState(null);
  const [relatorio, setRelatorio] = useState(null);
  const [relatoriosLookup, setRelatoriosLookup] = useState({});
  const [formData, setFormData] = useState({
    
  });

  const roleMapping = {
    eng: 'eng',
    lab: 'lab',
    admin: 'admin',
    customer: 'customer',
  };

  useEffect(() => {
    async function getReports(id) {
      try {
        const response = await GenGet(`${roleMapping[role]}/closedreports/complete/${id}`);
        //console.log(response)
        setRelatorios(response.laudos);
        setRelatoriosLookup(response.laudo_lookup);

      } catch (err) {
        console.error(err);
      }
    }

    if (cliente && cliente.id) {
      getReports(cliente.id);
    }

  }, [cliente]);

  useEffect(() => {
    async function getRequisitosAnalise() {
      try {
        const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
        setClienteLookup(response.clientes_lookup);
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    getRequisitosAnalise();
  }, [role]);

  const handleChangeCliente = (id, nome) => {
    let registro = data.clientes.find(item => item.id === id);
    setCliente(registro ? registro : {});
    setFormData(prevFormData => ({
      ...prevFormData,
      cliente_id: registro ? id : '',
    }));
  };


  const handleChangeRelatorio = (id, nome) =>{
    let registro = relatorios.find(item => item.id === id);
    setRelatorio(registro);
  }

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
      <p className='text-[9pt] -mt-2'>Visualize os laudos emitidos</p>
      
      {!isLoading ? (      
        <div>
          {clienteLookup && !isLoading && (
              <CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente} />
            )}
            {cliente && cliente.id && (
              <div>
                <div className='p-4 rounded-md border-[1px]'>
                  <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p>
                  <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
                  <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
                  <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && (`ramal:${cliente.ramal}`)}</p>
                  <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
                  <p className='p-0 m-0'>Cidade: {cliente.matriz_cidade} - Estado:{cliente.matriz_estado} - País: {cliente.pais_matriz}</p>
                </div>
                
              </div>

            )}
            {cliente && relatoriosLookup && !isLoading && ( 
              <div className='flex flex-col w-full'>
                <p className='p-0 m-0 font-semibold'>Escolha um relatório:</p>
                <CustomSelect search={true} label={'Relatórios'} data={relatoriosLookup} onChange={handleChangeRelatorio}/> 
              </div>
            )}
            {relatorio && (
              <Relatorio role={role} id={relatorio.id}/>
            )}  
        </div>
      ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success"/>
        </div>
      )}    
    </div>
  )
}

export default Laudos
