import React, { useState, useEffect } from 'react';
import { GenGet } from 'Admin/state/api';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import EditarItem from './EditarItem';
import GenericDataGrid from 'Admin/components/DataGrids/GenericDataGrid';
import EditarSistema from './EditarSistema';
import AutorenewIcon from '@mui/icons-material/Autorenew';

function SistemasParametros({role}) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [itemPointer, setItemPointer] = useState(null);
    const [modalItemOpen, setModalItemOpen] = useState(false);
    const [sistemaPointer, setSistemaPointer] = useState(null);
    const [modalSistemaOpen, setModalSistemaOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);    
    
    const roleMapping ={
        eng:'eng',
        lab:'lab',
        admin:'admin',
      }

      const handleRefresh = () =>{
        setRefresh(true);
      }

      async function getParametros() {
        try {
          const response = await GenGet(`${roleMapping[role]}/sistemsparams`);
          console.log(response);
          setData(response);
          setIsLoading(false);
          setRefresh(false)
          
        } catch (err) {
          console.error('Error ao consultar dos parametros');
          setRefresh(false)
        }
      }
    useEffect(() => {
        getParametros();
      }, []);

      useEffect(() => {
        if(refresh){
            getParametros();
        }
      }, [refresh]);

      const handleItemModal = (id) =>{  
        const registro = data.itens.find(item => item.id === id);
        if (registro) {
            setItemPointer(registro.id);
            setModalItemOpen(true);
        }
      }
    
      const handleCloseItemModal = () => {
        setModalItemOpen(false);
        setItemPointer(null);
      };

      const itensColumns = ['ID','Nome','Descrição', 'Un', 'L.Q', 'Método','Custo'];
      const itensActions = [{ action: 'Editar', function: handleItemModal }];

      const handleSystemModal = (id) =>{  
        const registro = data.itens.find(item => item.id === id);
        if (registro) {
            setSistemaPointer(registro.id);
            setModalSistemaOpen(true);
        }
      }
    
      const handleCloseSystemModal = () => {
        setModalSistemaOpen(false);
        setSistemaPointer(null);
      };

      const sistemasColumns = ['ID','Nome','Descrição'];
      const sistemasActions = [{ action: 'Editar', function: handleSystemModal }];

  return (
    <div className='flex flex-col gap-2 mt-1 p-2 w-[100%]'>
      
      <div className='flex flex-row justify-between'> 
      <p className='font-medium'>Sistemas e Parametros cadastrados</p>
      <div className='rounded-full p-2 bg-slate-200 cursor-pointer hover:bg-slate-300 shadow-sm w-[40px]' onClick={()=> handleRefresh()}>
        <AutorenewIcon size={'small'} />
      </div>
      </div>
      
      
      <p className='font-semibold'>Sistemas cadastrados</p>
      <Dialog open={modalSistemaOpen} onClose={handleCloseSystemModal} PaperProps={{
        sx: {
          width: '70%', 
          maxWidth: 'none',
        },
      }}>
        <DialogContent>
          <EditarSistema role={role} id={sistemaPointer} />
        </DialogContent>
      </Dialog>
      {!isLoading ? (
            <div className='flex flex-col w-[70vw] overflow-x-scroll'>
                <GenericDataGrid rows={data.sistemas} columns={sistemasColumns} per_page={15} actions={sistemasActions} title="Registros"/>
            </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>          
        )}

      <p className='font-semibold'>Parametros cadastrados</p>
      <Dialog open={modalItemOpen} onClose={handleCloseItemModal} PaperProps={{
        sx: {
          width: '70%', 
          maxWidth: 'none',
        },
      }}>
        <DialogContent>
          <EditarItem role={role} id={itemPointer} />
        </DialogContent>
      </Dialog>
        {!isLoading ? (
            <div className='flex flex-col w-[70vw] overflow-x-scroll'>
                <GenericDataGrid rows={data.itens} columns={itensColumns} per_page={15} actions={itensActions} title="Registros"/>
            </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>          
        )}
    </div>
  )
}

export default SistemasParametros
