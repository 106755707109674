import React, {useState, useEffect} from 'react'
import { Typography, Box, Button,Accordion,AccordionDetails,AccordionSummary, Grid, IconButton, Alert, CircularProgress  } from '@mui/material';
import { useNavigate } from 'react-router-dom';


import {makeStyles} from '@material-ui/core/styles';
import Img from '../../assets/logo.png';
import { GenGet, GenPost } from 'Loja/state/api';
import MTECH from '../../assets/medeiros_technology.png';

const useStyles = makeStyles({
    botaoFooter: {
      "&:hover": {
        bgcolor:"#08BEFBD",
        color: "white",
      }
    }
  });

function IguaFooter() {
    const [loading, setLoading] = useState(true);
    const [leadmsg, setLeadMsg] = useState(false);
    const [links, setLinks] = useState([]);
    const [email, setEmail] = useState('');


    async function getUser() {
      try {
        const response = await GenGet(`api/params/footer`);
        setLinks(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    useEffect(()=>{
      getUser();
    },[])

    const handleRedirect = (url) => {
        var link = url;
        window.location.href = link;
      };


    const HandleLead = async (email) => {
        try {
            var res = await GenPost('api/leads', { email : email});
            const { message } = res;
            setLeadMsg(message);
            setTimeout(() => {
             setLeadMsg(null);
           }, 5000);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                setLeadMsg(errorMessage);
              } else {
                setLeadMsg('Erro ao cadastrar Email.');
              }
              setTimeout(() => {
                setLeadMsg(null);
              }, 5000);

          }
      };

  return (
    <div className='flex flex-col bg-dark-blue'>
      <div className="flex flex-col md:flex-row flex-wrap w-full">
        <div className="flex flex-col min-w-[300px] w-full md:w-auto md:flex-none p-4 relative min-h-[400px]">
          <img src={Img} className="w-[120px] md:w-[120px] sm:w-[100px]" />
          <p className='text-[11pt] text-dark-white p-0 m-0 mt-2 px-2'>SAC: +55 47 3035-9716</p>
          <p className='text-[11pt] text-dark-white p-0 m-0 px-2'>Fax: +55 47 3330-4547</p>
          <p className='text-[11pt] text-dark-white p-0 m-0 px-2'>sac@iguacontrol.com.br</p>
          <br/>
          <p className='text-[11pt] text-dark-white p-0 m-0 px-2'>Rua Lagoa Vermelha, 385 A</p>
          <p className='text-[11pt] text-dark-white p-0 m-0 px-2'>Margem Esquerda - Gaspar/SC</p>
          <p className='text-[11pt] text-dark-white p-0 m-0 px-2'>Cep: 89.116-580</p>
          <p className='text-[12pt] text-dark-white mt-2 px-2 cursor-pointer font-extrabold hover:text-white'>CONHEÇA A EMPRESA</p>
          <hr/>
          <div className='absolute bottom-2 left-4 p-2'>
            <p className='text-dark-white font-semibold p-0 m-0 text-[11pt]'>sistema mantido por</p>
            <img src={MTECH} className='w-[140px]'/>
          </div>

        </div>
        <div className="flex flex-col flex-grow p-2">
          <div className="">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.7943823355613!2d-48.94854382466074!3d-26.910019993091183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94df24918d2c76ed%3A0x5c938b11a9c7b09!2sR.%20Lagoa%20Vermelha%2C%20385%20-%20Margem%20Esquerda%2C%20Gaspar%20-%20SC%2C%2089116-580!5e0!3m2!1spt-BR!2sbr!4v1681869714733!5m2!1spt-BR!2sbr"
              className="h-[400px] w-[100%]"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
      <div className='h-[30px] bg-dark-black flex justify-center align-middle items-center'>
        <p className='text-main-white text-[10pt] font-semibold p-0 m-0'>Igua Control 2024 | todos os direitos reservados.</p>
      </div>
    </div>
  )
}

export default IguaFooter