import React, { useEffect, useState } from 'react';
import { GenGet, GenPostMultiform } from 'Admin/state/api';
import PhotoWidgetSingle from 'Admin/components/PhotoWidgetSingle';
import { CircularProgress } from '@mui/material';
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

function ParametrosEmail() {
    const [footerImage, setFooterImage] = useState({imagem:null})
    const [headerImage, setHeaderImage] = useState({imagem:null})
    const [msg, setMsg] = useState(null)
    const [noReg, setNoReg] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const [formData, setFormData] = useState({
        email_footer_image: footerImage.imagem,
        email_header_image: headerImage.imagem,
        email_footer : '',
        email_header: '',
        email_teste: '',
        destino_email_copia:'', 
      });

      const handleField = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
      

      const handlePost = () =>{
        if (noReg){
            if (formData.email_teste === '' || formData.destino_email_copia === '' ) {
                setMsg("Os campos 'Email para teste' e 'Email para avisos' são obrigatórios ");
                setTimeout(() => {
                    setMsg(null);
                }, 3000);
                return;
            }
        }

        async function postData() {
          try {
            const form = {...formData,
                    email_footer_image: footerImage.imagem,
                    email_header_image: headerImage.imagem }
            const response = await GenPostMultiform("/email/params", form)

            setMsg(response.message);
            setTimeout(() => {
                setMsg(null);
              }, 3000);
            setRefresh(true);

          } catch (err) {
            setMsg(err.message);
            setTimeout(() => {
                setMsg(null);
              }, 3000);
          }
        }
        postData();
      }

      const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await GenGet('/email/params');

            if (response != []){
                setNoReg(false)
            }
            if (response) {
                setFormData({
                    ...formData,
                    ...response,
                    email_footer_image: null,
                    email_header_image: null,
                });
            }
            setIsLoading(false);
            setRefresh(false);
        } catch (err) {
          setIsLoading(false);
            setRefresh(false);
        }
    };

      useEffect(() => {
        fetchData();
      }, []);

      useEffect(() => {
        if (refresh){
            fetchData();
        }
        
      }, [refresh]);

  return (
    <div className='flex flex-col w-full p-2 text-[10pt]'>
        <h3 className='text-[14pt]'>Parametros de email</h3>
        <p className='text-[9pt] -mt-2'>Configurações para envio de emails</p>
        {!isLoading ? (        
        <div>
        <div className='flex flex-row gap-2 my-2'>
            <div className='flex flex-col gap-1'>
            <p className='p-0 m-0 font-semibold'>Email para teste</p>
              <input
                name="email_teste"
                onChange={handleField}
                id="email_teste"
                value={formData.email_teste}
                placeholder='Email para teste.'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
              />
            </div>
            <div className='flex flex-col gap-1'>
              <p className='p-0 m-0 font-semibold'>Email para aviso</p>
              <input
                name="destino_email_copia"
                onChange={handleField}
                id="destino_email_copia"
                value={formData.destino_email_copia}
                placeholder='Email para avisos.'
                className="px-4 py-2 rounded border border-gray-300 focus:outline-none"
              />
            </div>
        </div>
        <div className='flex flex-row gap-1'>
            <div className='flex flex-col w-1/2'>
            {formData.email_header ? (<img src={`${GLOBAL_URL}/api/files/public/email/${formData.email_header}`} className='h-[200px] rounded-md' alt=''/>) : <div className='border-[1px] border-gray-400 rounded-md h-[200px] flex justify-center align-middle items-center'><p className='m-0 p-0'> nenhuma imagem cadastrada</p></div>}
                <PhotoWidgetSingle setData={setHeaderImage} data={headerImage} semcapa={true} title={'Cabeçalho do email'} />
            </div>
            <div className='flex flex-col w-1/2'>
                {formData.email_footer ? (<img src={`${GLOBAL_URL}/api/files/public/email/${formData.email_footer}`} className='h-[200px] rounded-md' alt=''/>) : <div className='border-[1px] border-gray-400 rounded-md h-[200px] flex justify-center align-middle items-center'><p className='m-0 p-0'> nenhuma imagem cadastrada</p></div>}
                
                <PhotoWidgetSingle setData={setFooterImage} data={footerImage} semcapa={true} title={'Rodapé do email'}/>
            </div>
        </div>
        <div className='w-full flex flex-row-reverse'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
        {msg && (
            <div className='w-full flex flex-row-reverse p-2 justify-center align-middle items-center'>
                <p className='m-0 p-0 text-gray-800'>{msg}</p>
            </div>
        )}
        </div>

        </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}
    </div>
  )
}

export default ParametrosEmail