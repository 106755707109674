import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'Loja/state/axios';
import{ Alert, CircularProgress } from "@mui/material";
import { GenPost } from 'Loja/state/api';
import { UserIsAuth } from 'context/Auth';


const LOGIN_URL = 'api/login';


const CustomerLogin = ({handleLoginForm, loginForm, handleSubmit, errMsg, scssMsg, postIsLoading}) =>{
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return(
    <div className='flex flex-col justify-center align-middle items-center'
      >
        <div className='flex flex-col'>
          <h3 className='text-[16pt] text-slate-800 font-semibold'>
            Painel de Cliente
          </h3>
          <p className='pl-4 pt-0 -mt-3'>acesse o painel do cliente Igua Control</p>
        </div>

        <div className='flex flex-col gap-2 mt-1 min-w-[300px] min-h-[300px] p-4 border rounded-md'>
        <div className='flex flex-col justify-between'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-100 border rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={loginForm.email} 
            onChange={handleLoginForm}  
            />
          </div>
          <div className='flex flex-col justify-between'>
          <label className=''>Senha:</label>
          <div className='relative'>
            <input
              className='min-w-[98%] bg-slate-100 border rounded flex pl-1.5 pt-1.5 pb-1.5'
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={loginForm.password}
              onChange={handleLoginForm}
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
            >
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
          <div className='w-full flex flex-row justify-end'>
            {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
            <button
              className=' w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
            >
              <p className='text-[12pt]' onClick={handleSubmit}>Entrar</p>
            </button>
          </div>

          {errMsg && <Alert severity="error" sx={{ width: 250 }}>{errMsg}</Alert>}
          {scssMsg && <Alert severity="success" sx={{ width: 250 }}>{scssMsg}</Alert>}
        </div>
    </div>
  )
}

const EngineerLogin = ({handleLoginForm, loginForm, handleSubmit, errMsg, scssMsg, postIsLoading}) =>{
  const [showPassword, setShowPassword] = useState(false);

  // Função para alternar a visibilidade da senha
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return(
    <div className='flex flex-col justify-center align-middle items-center'
      >
        <div className='flex flex-col'>
          <h3 className='text-[16pt] text-slate-800 font-semibold'>
            Painel do Engenheiro
          </h3>
          <p className='pl-4 pt-0 -mt-3'>acesse o painel de engenharía</p>
        </div>

        <div className='flex flex-col gap-2 mt-1 min-w-[300px] min-h-[300px] p-4 border rounded-md'>
        <div className='flex flex-col justify-between'>
          <label className=''>email:</label>
          <input className={`min-w-[98%] bg-slate-100 border rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={loginForm.email} 
            onChange={handleLoginForm}  
            />
          </div>
          <div className='flex flex-col justify-between'>
          <label className=''>Senha:</label>
          <div className='relative'>
            <input
              className='min-w-[98%] bg-slate-100 border rounded flex pl-1.5 pt-1.5 pb-1.5'
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={loginForm.password}
              onChange={handleLoginForm}
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
            >
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
          <div className='w-full flex flex-row justify-end'>
            {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
            <button
              className=' w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
            >
              <p className='text-[12pt]' onClick={handleSubmit}>Entrar</p>
            </button>
          </div>

          {errMsg && <Alert severity="error" sx={{ width: 250 }}>{errMsg}</Alert>}
          {scssMsg && <Alert severity="success" sx={{ width: 250 }}>{scssMsg}</Alert>}
        </div>
    </div>
  )
}

const LabLogin = ({handleLoginForm, loginForm, handleSubmit, errMsg, scssMsg, postIsLoading}) =>{
  const [showPassword, setShowPassword] = useState(false);

  // Função para alternar a visibilidade da senha
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return(
    <div className='flex flex-col justify-center align-middle items-center'
      >
        <div className='flex flex-col'>
          <h3 className='text-[16pt] text-slate-800 font-semibold'>
            Painel de Laboratório
          </h3>
          <p className='pl-4 pt-0 -mt-3'>acesse o painel do laboratório</p>
        </div>

        <div className='flex flex-col gap-2 mt-1 min-w-[300px] min-h-[300px] p-4 border rounded-md'>
        <div className='flex flex-col justify-between'>
          <label className=''>Email:</label>
          <input className={`min-w-[98%] bg-slate-100 border rounded flex p-1.5`}                 
            id="email"
            name="email"
            value={loginForm.email} 
            onChange={handleLoginForm}  
            />
          </div>
          <div className='flex flex-col justify-between'>
          <label className=''>Senha:</label>
          <div className='relative'>
            <input
              className='min-w-[98%] bg-slate-100 border rounded flex pl-1.5 pt-1.5 pb-1.5'
              id="password"
              name="password"
              type={showPassword ? "text" : "password"} // Alterna entre "text" e "password"
              value={loginForm.password}
              onChange={handleLoginForm}
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
            >
              {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
            </button>
          </div>
        </div>
          <div className='w-full flex flex-row justify-end'>
            {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
            <button
              className=' w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
            >
              <p className='text-[12pt]' onClick={handleSubmit}>Entrar</p>
            </button>
          </div>

          {errMsg && <Alert severity="error" sx={{ width: 250 }}>{errMsg}</Alert>}
          {scssMsg && <Alert severity="success" sx={{ width: 250 }}>{scssMsg}</Alert>}
        </div>
    </div>
  )
}


const AdminLogin = ({ handleLoginForm, loginForm, handleSubmit, errMsg, scssMsg, postIsLoading }) => {
  const [showPassword, setShowPassword] = useState(false);

  // Função para alternar a visibilidade da senha
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='flex flex-col justify-center align-middle items-center'>
      <div className='flex flex-col'>
        <h3 className='text-[16pt] text-slate-800 font-semibold'>
          Painel de Administração
        </h3>
        <p className='pl-4 pt-0 -mt-3'>Acesse o painel de administração.</p>
      </div>

      <div className='flex flex-col gap-2 mt-1 min-w-[300px] min-h-[300px] p-4 border rounded-md'>
        <div className='flex flex-col justify-between'>
          <label className=''>Email:</label>
          <input
            className='min-w-[98%] bg-slate-100 border rounded flex p-1.5'
            id="email"
            name="email"
            value={loginForm.email}
            onChange={handleLoginForm}
          />
        </div>

        <div className='flex flex-col justify-between'>
          <label className=''>Senha:</label>
          <div className='relative'>
            <input
              className='min-w-[98%] bg-slate-100 border rounded flex pl-1.5 pt-1.5 pb-1.5'
              id="password"
              name="password"
              type={showPassword ? "text" : "password"} // Alterna entre "text" e "password"
              value={loginForm.password}
              onChange={handleLoginForm}
            />
            <button
              type="button"
              onClick={toggleShowPassword}
              className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
            >
              {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
            </button>
          </div>
        </div>

        <div className='w-full flex flex-row justify-end'>
          {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
          <button
            className='w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md'
            onClick={handleSubmit}
          >
            <p className='text-[12pt]'>Entrar</p>
          </button>
        </div>

        {errMsg && <Alert severity="error" sx={{ width: 250 }}>{errMsg}</Alert>}
        {scssMsg && <Alert severity="success" sx={{ width: 250 }}>{scssMsg}</Alert>}
      </div>
    </div>
  );
};

const RecuperarSenha = () =>{
  const [errMsg, setErrMsg] = useState(null);
  const [scssMsg, setScssMsg] = useState(null);
  const [postIsLoading, setPostIsLoading] = useEffect(false);
  const [solicitarForm, setSolicitarForm] = useState({
    email: '',
  });


  const handleSolicitarForm = (e) => {
    setSolicitarForm({
      ...solicitarForm,
      [e.target.name]: e.target.value
    });
  };


  async function handleSubmit() {
          setPostIsLoading(true);
          try {
            const response = await GenPost(`api/recuperar-senha`, solicitarForm);
            setScssMsg(response.message);
              setTimeout(() => {
                setScssMsg(null) 
              }, 3000);
              setPostIsLoading(false);

          } catch (err) {
              setErrMsg('Ops, Algo deu errado!');
              setPostIsLoading(false);
              setTimeout(() => {
                setErrMsg(null);
              }, 3000);
          }
  };

  return(
    <div className='flex flex-col justify-center align-middle items-center'> 
         <div className='flex flex-col gap-2 mt-1 p-4 border rounded-md'>
          <div className='flex flex-col'>
            <h3 className='text-[16pt] text-slate-800 font-semibold'>
              Esqueceu a senha?
            </h3>
            <p className='pl-4 pt-0 -mt-3'>digite seu email.</p>
          </div>
          <div className='flex flex-col justify-between'>
            <label className=''>email:</label>
            <input className={`min-w-[98%] bg-slate-100 border rounded flex p-1.5`}                 
              id="email"
              name="email"
              value={solicitarForm.email} 
              onChange={handleSolicitarForm}  
              />
           </div>
           <div className='w-full flex flex-row justify-end'>
           {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
            <button
              className=' w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md '
              onClick={handleSubmit}
              >
              <p className='text-[12pt]' onClick={handleSubmit}>Solicitar</p>
            </button>
          </div>
          </div>


      {errMsg && <Alert severity="error" sx={{ width: 320 }}>{errMsg}</Alert>}
      {scssMsg && <Alert severity="info" sx={{ width: 320 }}>{scssMsg}</Alert>}
    </div>

  )
}

const LOGIN_COMPONENTS = {
  customer: CustomerLogin,
  engineer: EngineerLogin,
  lab: LabLogin,
  admin: AdminLogin
};

const Login = ({ loginType }) => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(null);
  const [scssMsg, setScssMsg] = useState(null);
  const token = localStorage.getItem('token');
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [formType, setFormType] = useState('Login')
  const [verifyAuth, setVerifyAuth] = useState(true);

  const handleFormTypes = (type) =>{
    setFormType(type)
  }
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  });

  // Escolher o componente de login baseado no loginType
  const SelectedLoginComponent = LOGIN_COMPONENTS[loginType];

  const handleLoginForm = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (token) {
        try {
          // Aguarda 2 segundos antes de verificar a autenticação
          const timer = setTimeout(async () => {
            const isAuth = await UserIsAuth(); // Aguarda o resultado da promessa
            
            if (isAuth) {
              navigate(`/${loginType}`);
              setVerifyAuth(false);
            } else {
              // Remove o token se a autenticação falhar
              localStorage.removeItem('token');
              setVerifyAuth(false);
            }
          }, 2000);
  
          // Limpa o timeout se o componente for desmontado
          return () => clearTimeout(timer);
  
        } catch (error) {
          localStorage.removeItem('token'); // Remove o token em caso de erro
          setVerifyAuth(false);
        }
      }
    };
  
    checkAuth(); // Chama a função que verifica a autenticação
  
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setPostIsLoading(true);
    try {
      const response = await axios({
        url: LOGIN_URL,
        method: 'POST',
        data: JSON.stringify(loginForm),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        withCredentials: true,
      });

      setVerifyAuth(false);
      localStorage.setItem('token', response?.data?.token);

      setScssMsg('Login realizado com sucesso');
      setTimeout(() => {
        setScssMsg(null);
      }, 2000);

      const timer = setTimeout(() => {
        window.location.reload();
      }, 1000); 
      setPostIsLoading(false);
      return () => clearTimeout(timer);

      

    } catch (err) {
      setPostIsLoading(false);
      setErrMsg('Falha no login.');
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  };

  return (
    <div className='flex flex-col justify-center align-middle items-center  min-h-[85vh]'>  
      {/* Renderiza o componente de login baseado no loginType */}
      {formType === 'Login' ? (
        <div>
          <SelectedLoginComponent handleLoginForm={handleLoginForm} loginForm={loginForm} handleSubmit={handleSubmit} errMsg={errMsg} scssMsg={scssMsg} postIsLoading={postIsLoading} />
          <div className='flex flex-col w-full justify-center align-middle items-center text-center'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Recuperar')}>esqueceu a senha?</p>
          </div>        
        </div>
        
      ) : (
        <div>
          <RecuperarSenha handleFormTypes={handleFormTypes}/>
          <div className='flex flex-col w-full justify-center align-middle items-center text-center'>
            <p className='p-0 m-0 text-[10pt] font-semibold text-blue-500 hover:text-blue-400 cursor-pointer' onClick={() => handleFormTypes('Login')}>acessar conta</p>
          </div>  
        </div>
        
      )}
    {token && verifyAuth &&  (<Alert severity="info" >Verificando sua sessão aguarde.</Alert>)}
    </div>
  );
};

export default Login;

