import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArticlePage from 'Loja/components/compLoja/ArticlePage';
import ServicosPage from '../sobre/Servicos';
import Img from 'Loja/assets/fundo_analise.png'

const leftContent = {
  title: 'Serviços',
  links: [ 
    'NOSSOS SERVIÇOS',
  ]
};

const Components = [ ServicosPage ];
const breadCrumbs = [
  {
    title: 'Inicio',
    endpoint: '/'
  },
  {
    title: 'A Empresa',
    endpoint: '/sobre'
  },
];

const Servicos = () => {
  const nav = useNavigate();
  return (
    <ArticlePage leftContent={leftContent} Components={Components} breadCrumbs={breadCrumbs} bg={Img}/>
  );
};

export default Servicos;
