import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArticlePage from 'Loja/components/compLoja/ArticlePage';
import Empresa from '../sobre/Empresa';
import Atuacao from '../sobre/Atuacao';
import Img from 'Loja/assets/fundo_engenheiro.png'

const leftContent = {
  title: 'Apresentação',
  links: [ // Corrigir aqui para 'links'
    'APRESENTAÇÃO',
    'ÁREA DE ATUAÇÃO'
  ]
};

const Components = [ Empresa, Atuacao];
const breadCrumbs = [
  {
    title: 'Inicio',
    endpoint: '/'
  },
  {
    title: 'A Empresa',
    endpoint: '/sobre'
  },
];

const Sobre = () => {
  const nav = useNavigate();
  return (
    <ArticlePage leftContent={leftContent} Components={Components} breadCrumbs={breadCrumbs} bg={Img}/>
  );
};

export default Sobre;
