import React, { useState, useEffect } from 'react';
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';
import NovoColaborador from './NovoColaborador';
import GenericModal from 'Admin/components/GenericModal';
import { Dialog, DialogContent } from '@mui/material';
import NovoEndereco from './NovoEndereco';
import NovoClienteUsuario from '../forms/usuarios/NovoClienteUsuario';

const PersonDrawer = ({ data, type }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="drawer-container">
        <div className="drawer-header" onClick={handleToggle}>
          <div className="drawer-title">
            {data.name} {data.sobrenome} - {data.cargo}
          </div>
          <div className="drawer-toggle">{isOpen ? '▲' : '▼'}</div>
        </div>
        <div className={`drawer-content ${isOpen ? 'open' : 'closed'}`}>
          <p><strong>CPF:</strong> {data.cpf}</p>
          <p><strong>Telefone:</strong> {data.telefone}</p>
          <p><strong>Sigla Licença:</strong> {data.sigla_licenca}</p>
          <p><strong>Valor Licença:</strong> {data.valor_licenca}</p>
          <p><strong>Formação Acadêmica:</strong> {data.formacao_academica}</p>
          <p><strong>Email:</strong> {data.email}</p>
          <p><strong>Cliente ID:</strong> {data.cliente_id}</p>
          {type !== 'user' && (
            <div>
              <p><strong>Res Laudo:</strong> {data.res_laudo ? 'Sim' : 'Não'}</p>
              <p><strong>CC Laudo:</strong> {data.cc_laudo ? 'Sim' : 'Não'}</p>
            </div>
          )}

        </div>
        <style>{`
          .drawer-container {
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #fff;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
          .drawer-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ddd;
          }
          .drawer-title {
            font-size: 16px;
            font-weight: bold;
          }
          .drawer-toggle {
            font-size: 16px;
            color: #888;
          }
          .drawer-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
            opacity: 0;
            padding: 0 10px;
          }
          .drawer-content.open {
            max-height: 500px; /* Ajuste o valor conforme necessário */
            opacity: 1;
            padding: 10px;
          }
          .drawer-content.closed {
            max-height: 0;
            opacity: 0;
          }
        `}</style>
      </div>
    );
  };
const EnderecosDrawer = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="drawer-container">
        <div className="drawer-header" onClick={handleToggle}>
          <div className="drawer-title">
            {data.nome_unidade} - {data.cidade} - {data.estado}
          </div>
          <div className="drawer-toggle">{isOpen ? '▲' : '▼'}</div>
        </div>
        <div className={`drawer-content ${isOpen ? 'open' : 'closed'}`}>
          <p><strong>CPF:</strong> {data.email}</p>
          <p><strong>Telefone:</strong> {data.telefone}</p>
          <p><strong>Ramal:</strong> {data.ramal}</p>
          <p><strong>Endereço:</strong> {data.endereco}</p>
          <p><strong>Número:</strong> {data.numero}</p>

          <p><strong>Bairro:</strong> {data.bairro}</p>
          <p><strong>CEP:</strong> {data.cep}</p>
          <p><strong>Cidade:</strong> {data.cidade}</p>
          <p><strong>Estado:</strong> {data.estado}</p>
          <p><strong>País:</strong> {data.pais}</p>
        </div>
        <style>{`
          .drawer-container {
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #fff;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }
          .drawer-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            background-color: #f7f7f7;
            border-bottom: 1px solid #ddd;
          }
          .drawer-title {
            font-size: 16px;
            font-weight: bold;
          }
          .drawer-toggle {
            font-size: 16px;
            color: #888;
          }
          .drawer-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
            opacity: 0;
            padding: 0 10px;
          }
          .drawer-content.open {
            max-height: 500px; /* Ajuste o valor conforme necessário */
            opacity: 1;
            padding: 10px;
          }
          .drawer-content.closed {
            max-height: 0;
            opacity: 0;
          }
        `}</style>
      </div>
    );
  };

function PainelCliente({role}) {
    const [novoColaborador, setNovoColaborador] = useState(false);
    const [novoUsuário, setNovoUsuario] = useState(false);
    const [novoEndereco, setNovoEndereco] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [clienteLookup, setClienteLookup] = useState({});
    const [clientes, setClientes] = useState(null);
    const [cliente, setCliente] = useState({});
    const [colaboradores, setColaboradores] = useState(null);
    const [usuarios, setUsuarios] = useState(null);
    const [enderecos, setEnderecos] = useState(null);

    
    const roleMapping = {
        eng: 'eng',
        lab: 'lab',
        admin: 'admin',
      };

      useEffect(() => {
        async function getClientes() {
          try {
            const response = await GenGet(`${roleMapping[role]}/analysisrequirements`);
            console.log(response)
            setClienteLookup(response.clientes_lookup);
            setClientes(response.clientes);
            setIsLoading(false);
          } catch (err) {
            console.error(err);
          }
        }
    
        getClientes();
      }, [role]);

      useEffect(() => {
        async function getDadosCliente(id) {
          try {
            const response = await GenGet(`${roleMapping[role]}/clienteinfo/${id}`);
            console.log(response);
            setColaboradores(response.colaboradores);
            setEnderecos(response.enderecos);
            setUsuarios(response.usuarios);
            setIsLoading(false);
          } catch (err) {
            console.error(err);
          }
        }
        if (cliente && cliente.id){
            getDadosCliente(cliente.id);
        }
        
      }, [cliente]);

      const handleChangeCliente = (id, nome) => {
        let registro = clientes.find(item => item.id === id);
        setCliente(registro ? registro : {});
      };
      
      const handleCloseMenu = () => {
        setNovoEndereco(false);
        setNovoColaborador(false);
        setNovoUsuario(false);
      };

      const handleOpenColaborador = () => {
        setNovoColaborador(true);
      };
      const handleOpenUsuario = () => {
        setNovoUsuario(true);
      };
      const handleOpenEndereco = () => {
        setNovoEndereco(true);
      };

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <p className='text-[9pt] -mt-2'>Gerencíe os dados do seu cliente.</p>
        <div className='flex flex-row justify-between'>
        <div>
        {clienteLookup && !isLoading && (
          <CustomSelect search={true} label={'Cliente'} data={clienteLookup} onChange={handleChangeCliente} />
        )}
        </div>
        <div>
        {cliente.id && (
            <div className='flex flex-row-reverse gap-1'>

                <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleOpenUsuario}>Novo Usuário</button>
                <Dialog open={novoUsuário} onClose={() => handleCloseMenu()} PaperProps={{
                  sx: {
                    width: '70%', 
                    maxWidth: 'none',
                  },
                }}>
                    <DialogContent >
                        <NovoClienteUsuario role={role} cliente_id={cliente.id} />
                    </DialogContent>
                </Dialog>
                
                <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleOpenColaborador}>Novo Colaborador</button>
                <Dialog open={novoColaborador} onClose={() => handleCloseMenu()} PaperProps={{
                  sx: {
                    width: '70%', 
                    maxWidth: 'none',
                  },
                }}>
                    <DialogContent>
                        <NovoColaborador role={role} cliente_id={cliente.id} />
                    </DialogContent>
                </Dialog>

                <button className='bg-blue-400 hover:bg-blue-500 admin-button'  onClick={handleOpenEndereco}>Novo Endereço</button>
                <Dialog open={novoEndereco} onClose={() => handleCloseMenu()} PaperProps={{
                  sx: {
                    width: '70%', 
                    maxWidth: 'none',
                  },
                }}>
                    <DialogContent>
                        <NovoEndereco role={role} cliente_id={cliente.id} />
                    </DialogContent>
                </Dialog>
            </div>
        )}
        </div>

        </div>

        {cliente.id && (
          <div className='flex flex-col'>
            <div className='p-4 rounded-md border-[1px]'>
            <p className='p-0 m-0 font-semibold'>Identificação:</p>
              <p className='p-0 m-0'><b>id:</b> {cliente.id} - <b>Cod. Referencia: </b>{cliente.codigo_ref} - <b>Sigla: </b>{cliente.nome}</p>
              <p className='p-0 m-0'><b>Razão social:</b> {cliente.razao_social}</p>
              <p className='p-0 m-0'><b>CNPJ: </b>{cliente.CNPJ}</p>
              <p className='p-0 m-0'><b>Email: </b>{cliente.email} - telefone:{cliente.telefone} - {cliente.ramal && (`ramal:${cliente.ramal}`)}</p>
              <br/>
              <p className='p-0 m-0 font-semibold'>Localização:</p>
              <p className='p-0 m-0'>Endereço: {cliente.endereco_matriz} - CEP:{cliente.cep_matriz}</p>
              <p className='p-0 m-0'>Cidade: {cliente.cidade_matriz} - Estado:{cliente.estado_matriz} - País: {cliente.pais_matriz}</p>
            </div>
            <br/>
            
            <div>
                <hr/>
                {colaboradores && (
                    <div>
                        <p className='font-semibold'>Colaboradores:</p>
                        {colaboradores.map(item => (
                            <PersonDrawer key={item.id} data={item} />   
                        ))}
                    </div>
                )}
            </div>
            <div>
                <hr/>
                {usuarios && (
                    <div>
                        <p className='font-semibold'>Usuários:</p>
                        {usuarios.map(item => (
                            <PersonDrawer key={item.id} type={'user'} data={item} />   
                        ))}
                    </div>
                )}
            </div>
            <div>
                <hr/>
                {enderecos && (
                    <div>
                        <p className='font-semibold'>Endereços:</p>
                        {enderecos.map(item => (
                            <EnderecosDrawer key={item.id} data={item} />   
                        ))}
                    </div>
                )}
            </div>

          </div>
        )}
    </div>
  )
}

export default PainelCliente
