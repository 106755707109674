import { React} from "react";
import { Outlet } from "react-router-dom";
import NavBar from '../../components/compLoja/NavBar';
import { useNavigate } from 'react-router-dom';
import PoliticaAviso from "Loja/components/compLoja/PoliticaAviso";
import IguaFooter from "Loja/components/compLoja/IguaFooter";

const Layout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="h-[30px] bg-dark-black flex justify-between text-main-white align-middle items-center px-2">
        <p className="text-[10pt] p-0 m-0">Conheça os serviços da Igua Control</p>
        <div className="flex flex-row-reverse gap-2">
        </div>
      </div>
      <NavBar/>
        <div className="flex-grow mt-[10px]">
          <Outlet />
        </div>
      <IguaFooter/>
      <PoliticaAviso/>
    </div>
          
  );
};

export default Layout;