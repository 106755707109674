import React from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import NovoUsuario from 'Admin/scenes/forms/usuarios/NovoUsuario';
import Usuarios from 'Admin/scenes/usuarios/Index';
import Mensagens from 'Admin/scenes/mensagens/Index';
import Avisos from 'Admin/scenes/avisos/Index';
import EmailTemplates from 'Admin/scenes/painel-email/EmailTemplates';
import EmailsEnviados from 'Admin/scenes/painel-email/EmailsEnviados';
import EngineerPannel from 'Admin/scenes/newPannel/EngineerPannel';
import PainelSistemas from 'Admin/scenes/parametros-quimicos/PainelSistemas';
import PainelLaudos from 'Admin/scenes/laudos/PainelLaudos';
import PainelClientes from 'Admin/scenes/clientes/PainelClientes';
import NovoCliente from 'Admin/scenes/clientes/NovoCliente';
import Clientes from 'Admin/scenes/clientes/Index';

function EngineerRoot() {

  return (
        <div>
          <Routes>
            <Route element={<EngineerPannel />}>
              <Route exact path="/" element={<Admin />} />              
              <Route path="/admin" element={<Navigate to="/admin" replace />} />              
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/mensagens" element={<Mensagens />} />
              <Route path="/perfil" element={<Mensagens />} />

              <Route path="/clientes" element={<Clientes role={'eng'}/>} />
              <Route path="/clientes/painel" element={<PainelClientes role={'eng'}/>} />
                <Route path="/cliente/novo" element={<NovoCliente role={'eng'} />} />  

              <Route path="/laudos" element={<PainelLaudos role={'eng'}/>} />
              <Route path="/parametrosquimicos" element={<PainelSistemas role={'eng'} />} />

              <Route path="/emails" element={<EmailTemplates />} />
              <Route path="/email/:id" element={<EmailTemplates />} />
              <Route path="/email/avulso" element={<EmailsEnviados />} />
              
              <Route path="/*" element={<Navigate to="/admin" replace />} />
            </Route>
          </Routes>
    </div>
  );
}

export default EngineerRoot;