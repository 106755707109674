import React from 'react';
import TiposSistemas from './TiposSistemas'
import SistemasParametros from './SistemasParametros';

function ParametrosQuimicos({role}) {
  
  return (
    <div className='flex flex-col gap-2 mt-1 p-2 w-[100%]'>
      <p className='text-[9pt] -mt-2 font-medium'>Gerencíe os parâmetros e indices químicos globais.</p>
      
      <TiposSistemas role={role}/>
      <hr/>
      <SistemasParametros role={role} />
    </div>
  )
}

export default ParametrosQuimicos
