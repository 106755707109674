import React, {useState, useEffect} from 'react';
import { Alert, CircularProgress} from "@mui/material";
import { GenPost } from 'Admin/state/api';

const NovoClienteUsuario = ({role, cliente_id}) => {
  const [msg, setMsg] = useState(null);
  const [postIsLoading, setPostIsLoading] = useState(false);
  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }
  
  const [formData, setFormData] = useState({
    name: "",
    sobrenome: "",
    cargo: "",
    sigla_licenca: "",
    valor_licenca: "",
    formacao_academica: "",
    email: "",
    password: "",
    password_confirmation: "",
    nome: "",
    telefone: "",
    cpf:"",
    cliente_id: cliente_id
  });

  const newUser = {
    name: "",
    sobrenome: "",
    cargo: "",
    sigla_licenca: "",
    valor_licenca: "",
    formacao_academica: "",
    email: "",
    password: "",
    password_confirmation: "",
    nome: "",
    telefone: "",
    cpf:"",
    cliente_id: cliente_id
  }

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    // Aplica a máscara para 10 ou 11 dígitos
    if (cleaned.length === 10) {
      return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
    } else if (cleaned.length === 11) {
      return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
    }
    return valor;
  };

  const formatarCpf = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    
    // Aplica a máscara de CPF (###.###.###-##) se o valor tiver 11 dígitos
    if (cleaned.length === 11) {
      return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    // Retorna o valor original se não houver 11 dígitos
    return valor;
  };

  const camposObrigatorios = [
    'name',
    'sobrenome',
    'email',
    'telefone',
    'cpf',
    'password',
    'password_confirmation',
    'cargo'
  ];

  const validateForm = () => {
    return camposObrigatorios.every(key => formData[key] !== "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setMsg("Preencha todos os campos");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }      

    if(formData.password !== formData.password_confirmation){
      setMsg("As senhas não conferem.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }
    
    async function PostForm() {
      setPostIsLoading(true);
      try {
        const response = await GenPost(`${roleMapping[role]}/client/newuser`, formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setFormData(newUser);
        setPostIsLoading(false);

      } catch (err) {
      setMsg('Erro ao incluir usuário.');
      setTimeout(() => {
          setMsg(null);
        }, 3000);
      setPostIsLoading(false);  
      }
    }
    PostForm();
  };

  return ( 
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Novo usuário para clientes</h3>
        <p className='text-[9pt] -mt-2'>cadastre um novo usuário para o cliente</p>
        <hr/>
          <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
            <h3 className='text-[12pt]'> Dados de Usuário</h3>
            <label className='mt-2 text-[10pt]'  >Nome de usuário *:</label>
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="name"
              name="name"
              placeholder="Nome de usuário *"
              value={formData.name} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Sobrenome *:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="sobrenome"
              name="sobrenome"
              placeholder="Sobrenome *"
              value={formData.sobrenome} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >CPF *:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cpf"
              name="cpf"
              placeholder="CPF *"
              value={formatarCpf(formData.cpf)} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Email Corporativo *:</label>            
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="email"
              name="email"
              placeholder="Email Corporativo *"
              value={formData.email} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Telefone Corporativo *:</label>
            <input
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                id="telefone"
                name="telefone"
                maxLength={14}
                placeholder="Telefone (com DDD) digite apenas numeros. *"
                value={formatarTelefone(formData.telefone)}
                onChange={handleChange}
              />

            <label className='mt-2 text-[10pt]'  >Senha do usuário *:</label>
            <div className='flex relative'>
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none w-full`}                 
                id="password"
                name="password"
                placeholder="Senha do usuário *"
                type={showPassword ? "text" : "password"} 
                value={formData.password} 
                onChange={handleChange}  
                />
              <button
                type="button"
                onClick={toggleShowPassword}
                className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
              >
                {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
              </button>
            </div>

            <label className='mt-2 text-[10pt]'  >Confirme a Senha *:</label>
            <div className='flex relative'>
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none w-full`}                 
                id="password_confirmation"
                name="password_confirmation"
                type={showConfirmPassword ? "text" : "password"} 
                placeholder="Confirme a Senha *"
                value={formData.password_confirmation} 
                onChange={handleChange}  
                />
                <button
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  className='absolute right-0 top-1/2 transform p-1.5 bg-white border rounded-md -translate-y-1/2 text-slate-600'
                >
                  {showPassword ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
                </button>
            </div>

            <hr/>

            <h3 className='text-[12pt]'> Dados Profissional</h3>
            <label className='mt-2 text-[10pt]'  >Cargo do Usuário *:</label>            
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cargo"
              name="cargo"
              placeholder="Cargo do Usuário"
              value={formData.cargo} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Formação Academica:</label>
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="formacao_academica"
              name="formacao_academica"
              placeholder="Formação Academica"
              value={formData.formacao_academica} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Setor de Resp.Técnica:</label>                
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="setor_resp_tec"
                  name="setor_resp_tec"
                  placeholder="Setor de Resp.Técnica"
                  value={formData.setor_resp_tec} 
                  onChange={handleChange}  
                  />

            <label className='mt-2 text-[10pt]'>Orgão de Classe:</label>                
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="sigla_licenca"
                  name="sigla_licenca"
                  placeholder="Orgão de Classe"
                  value={formData.sigla_licenca} 
                  onChange={handleChange}  
                  />

            <label className='mt-2 text-[10pt]'  >N° Registro:</label>                
            <input className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                  id="valor_licenca"
                  name="valor_licenca"
                  placeholder="N° Registro"
                  value={formData.valor_licenca} 
                  onChange={handleChange}  
                  />
          </div>

          <div className='w-full flex flex-row-reverse pt-2'>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
          {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
          {msg ? 
            <div className='flex justify-end p-1' >
              <Alert severity="info" sx={{width:320}} > {msg} </Alert>
            </div>
          : null}
          </div>
    </div> 
  )
}

export default NovoClienteUsuario;