import React , { useState} from "react";
import { useParams } from 'react-router-dom';
import { GenPost } from 'Loja/state/api';
import { useNavigate } from 'react-router-dom';
import { Alert, CircularProgress } from "@mui/material";


const NovaSenha = () => {
  const { token } = useParams();
  const [scssMsg, setScssMsg] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setisLoading ] = useState(false);

  const navigate = useNavigate()
  
  const [formData, setFormData] = useState({
    password: "",
    confirm_password:""
  });

  const setPassword = (e) => {
    setFormData({
      ...formData,
      password: e.target.value,
    });
  };

  const setConfirm = (e) => {
    setFormData({
      ...formData,
      confirm_password: e.target.value,
    });
  };
  
  const validate = (confirmPass, formData) =>{
    if (confirmPass.confirm_password === formData.password){
      return true
    }
    else
    {
        return false}
  };

  async function handleSubmit() {
    if (validate(formData.confirm_password, formData.password)) {
        setisLoading(true);
            
        try {
          const response = await GenPost(`api/nova-senha/${token}`, formData);
            setScssMsg(response.message);
            setisLoading(false);
            setTimeout(() => {
              navigate('/'); 
            }, 3000);

        } catch (err) {
            setErrMsg('Ops, Algo deu errado!');
            setTimeout(() => {
              setErrMsg(null);
            }, 3000);

            setisLoading(false);
        }

    } else {
        setErrMsg("senhas não conferem");
    }
  }

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmation = () => {
    showConfirmation(!showConfirmation);
  };
  
  return (
    <div className='flex flex-col justify-center align-middle items-center  min-h-[75vh]'>  
    <div className='flex flex-col justify-center align-middle items-center'>
      {!isLoading ? (
        <div className='flex flex-col gap-2 mt-1 p-4 border rounded-md'>
            <div className='flex flex-col'>
              <h3 className='text-[16pt] text-slate-800 font-semibold'>
                Altere sua senha
              </h3>
              <p className='pl-4 pt-0 -mt-3'>insira sua nova senha.</p>
            </div>
            <div className='relative'>
              <input
                className='min-w-[98%] bg-slate-100 border rounded flex p-1.5'
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={setPassword}
              />
              <button
                type="button"
                onClick={toggleShowPassword}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 text-slate-600'
              >
                {showPassword ? 'Ocultar' : 'Mostrar'}
              </button>
            </div>

            <div className='relative'>
              <input
                className='min-w-[98%] bg-slate-100 border rounded flex p-1.5'
                id="password_confimration"
                name="password_confimration"
                type={showPassword ? "text" : "password"}
                value={formData.password_confimration}
                onChange={setConfirm}
              />
              <button
                type="button"
                onClick={toggleShowConfirmation}
                className='absolute right-2 top-1/2 transform -translate-y-1/2 text-slate-600'
              >
                {showConfirmation ? 'Ocultar' : 'Mostrar'} {/* Alterna o texto do botão */}
              </button>
            </div>
            <div className='w-full flex flex-row justify-end'>
            <button
              className='w-[80px] h-[32px] p-1 bg-blue-500 hover:bg-blue-400 text-slate-100 font-semibold rounded-md'
              onClick={handleSubmit}
            >
              <p className='text-[12pt]'>Entrar</p>
            </button>
          </div>
          {errMsg && <Alert severity="error" sx={{ width: 320 }}>{errMsg}</Alert>}
          {scssMsg && <Alert severity="success" sx={{ width: 320 }}>{scssMsg}</Alert>}
        </div>
      ) : (
        <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
          <CircularProgress color="success" />
        </div>

      )} 


    </div>    
    </div>

  )
};
export default NovaSenha;