import React, {useEffect, useState}from 'react';
import Carrocel from '../../components/compLoja/Carrocel';
import Apresentacao from '../../components/compLoja/Apresentacao';
import Artigos from '../artigos/Index';




function LojaIndex() {

  return (
    <div className='w-[100%]'>
    <Carrocel/>
    <div className='w-full flex flex-col justify-center items-center text-center p-4 mt-4 -mb-5'>
      <h3 className='font-semibold text-gray-700'>Conheça a Igua Control</h3>
      <div className='border-b border-gray-400 w-[50%] max-w-[200px]'></div>
    </div>
    <Artigos/>
    <Apresentacao/>
    <br/>    
    </div>
  )
}

export default LojaIndex