import React from 'react';
import ImgArtigo1 from '../../assets/artigo1.jpg';
import ImgArtigo2 from '../../assets/artigo2.jpg';
import ImgArtigo3 from '../../assets/artigo3.jpg';
import LoginHubCard from 'Loja/components/compLoja/LoginHubCard';

function LoginHub() {
  return (
    <div className='flex flex-col gap-2 p-4'>
        <h2 className='font-extrabold m-0 p-0 text-slate-800'>ICBR Sistemas integrados</h2>
        <p className='pl-10 text-slate-800 m-0'>acesse um dos sistemas integrados com o yara</p>
       <div className="flex flex-wrap gap-1 justify-center w-full z-10 py-2">
        <div className='flex flex-grow justify-center'>
        <LoginHubCard 
        titulo={'Painel Laboratório'}
        conteudo={'Nosso modulo destinado ao laboratório.'} 
        endpoint={'/login/lab'} 
        imagem={ImgArtigo1}
        />
        </div>
        <div className='flex flex-grow justify-center'>
        <LoginHubCard 
        titulo={'Painel de Engenharía'}
        conteudo={'Nosso modulo destinado a engenharía.'} 
        endpoint={'/login/engineer'} 
        imagem={ImgArtigo2}
        />
        </div>
        <div className='flex flex-grow justify-center'>
        <LoginHubCard 
        titulo={'Painel de Administração'}
        conteudo={'Nosso modulo destinado a administração.'} 
        endpoint={'/login/admin-pannel'} 
        imagem={ImgArtigo3}
        />
        </div>
        </div>         
    </div>

  );
}

export default LoginHub;