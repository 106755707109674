import React, { useState, useEffect } from 'react';
import { Alert, CircularProgress} from "@mui/material";
import { GenGet, GenPost } from 'Admin/state/api';
import CustomSelect from 'Admin/components/CustomSelect';

const EditarCliente = ({role, id}) => {

  const [ usuariosLookup, setUsuariosLookup] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [postIsLoading, setPostIsLoading] = useState(false);  
  const [msg, setMsg] = useState(null);

  const roleMapping ={
    eng:'eng',
    lab:'lab',
    admin:'admin',
  }

  const [formData, setFormData] = useState({
    nome: "",
    razao_social: "",
    CNPJ: "",
    ramal:"",
    endereco_matriz: "",
    numero_matriz: "",
    bairro_matriz: "",
    codigo_ref: "",
    dep_funcao: "",
    contatos: "",
    cep_matriz: "",
    adm_conta: "",
    resp_tec:"",
    cidade_matriz: "",
    estado_matriz: "",
    pais_matriz: "",
    status: "ativo",
    email: "",
    telefone:"",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    if (valor){
      const cleaned = valor.replace(/\D/g, '');
      // Aplica a máscara para 10 ou 11 dígitos
      if (cleaned.length === 10) {
        return cleaned.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
      } else if (cleaned.length === 11) {
        return cleaned.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
      }
      return valor;
    }

  };

  const formatarCnpj = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    
    // Aplica a máscara de CNPJ (##.###.###/####-##) se o valor tiver 14 dígitos
    if (cleaned.length === 14) {
      return cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
    
    // Retorna o valor original se não houver 14 dígitos
    return valor;
  };

  const formatarCep = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
  
    // Aplica a máscara se o valor tiver 8 dígitos
    if (cleaned.length === 8) {
      return cleaned.replace(/(\d{5})(\d{3})/, '$1-$2');
    }
  
    // Retorna o valor original se não houver 8 dígitos
    return valor;
  };

  const camposObrigatorios = [
    'name',
    'razao_social',
    'email',
    'telefone',
    'dep_funcao',
    'codigo_ref',
    'adm_conta',
    'resp_tec'
  ];

  const validateForm = () => {
    return camposObrigatorios.every(key => formData[key] !== "");
  };

  useEffect(() => {
    async function getCliente(id) {
      try {
        const response = await GenGet(`${roleMapping[role]}/clienteinfo/${id}`);

        const lookup = await GenGet(`${roleMapping[role]}/userlookup`);
        setUsuariosLookup(lookup);
        setFormData({
          nome: response.cliente.nome,
          razao_social: response.cliente.razao_social,
          CNPJ: response.cliente.CNPJ,
          ramal: response.ramal,
          endereco_matriz: response.cliente.endereco_matriz,
          numero_matriz: response.cliente.numero_matriz,
          bairro_matriz: response.cliente.bairro_matriz,
          codigo_ref: response.cliente.codigo_ref,
          dep_funcao: response.cliente.dep_funcao,
          contatos: response.cliente.contatos,
          cep_matriz: response.cliente.cep_matriz,
          adm_conta: response.cliente.adm_conta,
          resp_tec: response.cliente.resp_tec,
          cidade_matriz: response.cliente.cidade_matriz,
          estado_matriz: response.cliente.estado_matriz,
          pais_matriz: response.cliente.pais_matriz,
          status: response.cliente.status,
          email: response.cliente.email,
          telefone:response.cliente.telefone,
        });
        setIsLoading(false);

      } catch (err) {
        console.error('Error ao consultar usuários');
      }
    }
    getCliente(id);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setMsg("Preencha todos os campos");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }  
  
    async function PostForm(id) {
      setPostIsLoading(true);
      try {
        const response = await GenPost(`${roleMapping[role]}/updateclient/${id}`, formData);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        setPostIsLoading(false);

      } catch (err) {
      setMsg(err.message);
      setTimeout(() => {
          setMsg(null);
        }, 3000);
      setPostIsLoading(false);         
      }
    }
    PostForm(id);
  };

  const handleUsuario = (id, nome) => {
    setFormData({
      ...formData, 
      adm_conta: id
    });
  };

  const handleResponsavel = (id, nome) => {
    setFormData({
      ...formData, 
      resp_tec: id
    });
  };


  return ( 
    <div className='flex flex-col gap-2 mt-1 p-2 min-w-[50vw]'>
        <p className='text-[9pt] -mt-2'>Atualize um cliente no sistema</p>
        <hr/>
        {!isLoading ? (
          <div>
            <div className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
              <h3 className='text-[12pt]'> Dados de Cliente</h3>
              
              <label className='mt-2 text-[10pt]'  >Sigla *:</label>              
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="nome"
                name="nome"
                placeholder="Sigla"
                value={formData.nome} 
                onChange={handleChange}  
                />

              <label className='mt-2 text-[10pt]'>Atuação *:</label>                
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="dep_funcao"
                name="dep_funcao"
                placeholder="Atuação"
                value={formData.dep_funcao} 
                onChange={handleChange}  
                />

              <label className='mt-2 text-[10pt]'  >CÓD.REF *:</label>                
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="codigo_ref"
                name="codigo_ref"
                placeholder="CÓD.REF"
                value={formData.codigo_ref} 
                onChange={handleChange}  
                />

              <label className='mt-2 text-[10pt]'  >Status *:</label>
              <select
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </select>

              <div className='flex flex-row gap-4'>
                <div className={'flex flex-col gap-1'}>
                <label className='mt-2 text-[10pt]'  >Administrador da conta *:</label> 
                {usuariosLookup && !isLoading && (
                    <CustomSelect search={true} prevalue={formData.adm_conta} label={'Administrador da conta'} data={usuariosLookup} onChange={handleUsuario} />
                  )}
                </div>

                <div className={'flex flex-col gap-1'}>
                  <label className='mt-2 text-[10pt]'  >Responsável técnico *:</label>              
                  {usuariosLookup && !isLoading && (
                      <CustomSelect search={true} prevalue={formData.resp_tec} label={'Responsável técnico'} data={usuariosLookup} onChange={handleResponsavel} />
                    )}
                </div>  
              </div>
              <hr/>

              <h3 className='text-[12pt]'> Dados da Empresa</h3>

              <label className='mt-2 text-[10pt]'>Razao Social *:</label>               
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="razao_social"
                name="razao_social"
                placeholder="Razao Social"
                value={formData.razao_social} 
                onChange={handleChange}  
                />

              <label className='mt-2 text-[10pt]'  >CNPJ:</label> 
              <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="CNPJ"
                name="CNPJ"
                placeholder="CNPJ"
                value={formatarCnpj(formData.CNPJ)} 
                onChange={handleChange}  
                />


            <label className='mt-2 text-[10pt]'  >Endereço Matriz:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="endereco_matriz"
              name="endereco_matriz"
              placeholder="Endereço Matriz"
              value={formData.endereco_matriz} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Número:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="numero_matriz"
              name="numero_matriz"
              placeholder="Número"
              value={formData.numero_matriz} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Bairro Matriz:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="bairro_matriz"
              name="bairro_matriz"
              placeholder="Bairro Matriz"
              value={formData.bairro_matriz} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >CEP Matriz:</label> 
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cep_matriz"
              name="cep_matriz"
              placeholder="CEP Matriz"
              value={formatarCep(formData.cep_matriz)} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Cidade Matriz:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="cidade_matriz"
              name="cidade_matriz"
              placeholder="Cidade Matriz"
              value={formData.cidade_matriz} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >Estado Matriz:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="estado_matriz"
              name="estado_matriz"
              placeholder="Estado Matriz"
              value={formData.estado_matriz} 
              onChange={handleChange}  
              />

            <label className='mt-2 text-[10pt]'  >País Matriz:</label>               
            <input 
              className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
              id="pais_matriz"
              name="pais_matriz"
              placeholder="País Matriz"
              value={formData.pais_matriz} 
              onChange={handleChange}  
              />

            </div>

            <h3 className='text-[12pt]'>Contato:</h3>  
            <div  className='flex flex-col gap-1 w-[100%] flex-wrap p-2'>
            <label className='mt-2 text-[10pt]'  >Email *:</label>             
            <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email} 
                onChange={handleChange}  
                />

            <label className='mt-2 text-[10pt]'  >Telefone:</label> 
            <input
                  className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
                  id="telefone"
                  name="telefone"
                  maxLength={14}
                  placeholder="Telefone (com DDD) digite apenas numeros."
                  value={formatarTelefone(formData.telefone)}
                  onChange={handleChange}
                />

            <label className='mt-2 text-[10pt]'  >Ramal:</label> 
            <input 
                className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}                 
                id="ramal"
                name="ramal"
                placeholder="Ramal"
                value={formData.ramal} 
                onChange={handleChange}  
                />
            </div>

            <div className='w-full flex flex-row-reverse pt-2'>
            <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Cadastrar</button>
            {postIsLoading && (<CircularProgress color="info" size={22} style={{padding:2}}/>) }
            {msg ? 
              <div className='flex justify-end p-1' >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
              </div>
            : null}
            </div>
          </div>
         ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
         )}

    </div> 
  )
}

export default EditarCliente;