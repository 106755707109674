import React from 'react';
import AnaliseAr from 'Loja/assets/servicos_imagens/img_servico01.jpg';
import AnaliseFisicoQuimica from 'Loja/assets/servicos_imagens/img_servico02.jpg';
import AssistenciaTecnica from 'Loja/assets/servicos_imagens/img_servico03.jpg';
import LimpezaQuimica from 'Loja/assets/servicos_imagens/img_servico04.jpg';
import ProgramaDeTratamentosAgua from 'Loja/assets/servicos_imagens/img_servico05.jpg';
import RelatoriosInspecs from 'Loja/assets/servicos_imagens/img_servico06.jpg';
import ResfriamentoSistemas from 'Loja/assets/servicos_imagens/img_servico07.jpg';
import ControleContaminacao from 'Loja/assets/servicos_imagens/img_servico08.jpg';
import ContoleCorrosao from 'Loja/assets/servicos_imagens/img_servico09.jpg';
import ControleIncrustacao from 'Loja/assets/servicos_imagens/img_servico10.jpg';
import TreinamentoOperadores from 'Loja/assets/servicos_imagens/img_servico11.jpg';
import Vistorias from 'Loja/assets/servicos_imagens/img_servico12.jpg';

const data = [
  {
    titulo: 'Análise do Ar',
    imagem: AnaliseAr
  },
  {
    titulo: 'Análises físico-químicas da água',
    imagem: AnaliseFisicoQuimica
  },
  {
    titulo: 'Assistência técnica permanente',
    imagem: AssistenciaTecnica
  },
  {
    titulo: 'Limpeza química corretiva e pré-operacional em sistemas geradores de vapor',
    imagem: LimpezaQuimica
  },
  {
    titulo: 'Programas de tratamento de águas indústrias',
    imagem: ProgramaDeTratamentosAgua
  },
  {
    titulo: 'Relatórios das inspeções',
    imagem: RelatoriosInspecs
  },
  {
    titulo: 'Resfriamento e sistemas gelados',
    imagem: ResfriamentoSistemas
  },
  {
    titulo: 'Tratamento para controle da contaminação microbiológica',
    imagem: ControleContaminacao
  },
  {
    titulo: 'Tratamento para controle de corrosão',
    imagem: ContoleCorrosao,
  },
  {
    titulo: 'Tratamento para controle de incrustação',
    imagem: ControleIncrustacao
  },
  {
    titulo: 'Treinamento de operadores: nível de usuários',
    imagem: TreinamentoOperadores
  },
  {
    titulo: 'Vistoria e avaliação',
    imagem: Vistorias
  },
];

function Card({ data }) {
  return (
    <div className="flex flex-col p-2 rounded-md shadow-md bg-slate-50">
      <img src={data.imagem} alt={data.titulo} className="w-full rounded-b-md" />
      <p className="font-semibold mt-2 text-[9pt]">{data.titulo}</p>
    </div>
  );
}

function Servicos() {
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {data.map((item, index) => (
        <Card data={item} key={index} />
      ))}
    </div>
  );
}

export default Servicos;
