import React, { useState, useEffect } from 'react';
import { GenPost, GenGet } from 'Admin/state/api';
import { Alert, CircularProgress } from '@mui/material';


function EditarSistema({role, id}) {
    const [msg ,setMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const roleMapping = {
      eng: 'eng',
      lab: 'lab',
      admin: 'admin',
    };

    const [formData, setFormData] = useState({
        nome: '',
        descricao: '',
      })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        async function PostForm() {
          try {
            const response = await GenPost(`${roleMapping[role]}/analysistype/update/${id}`, formData);
            setMsg(response.message);
            setTimeout(() => {
                setMsg(null);
            }, 3000);

    
          } catch (err) {
            setMsg('Erro ao incluir análise.');
            setTimeout(() => {
              setMsg(null);
            }, 3000);
          }
        }
        PostForm();  
      };

    useEffect(() => {
        async function getRegistro(id) {
            try {
              const response = await GenGet(`${roleMapping[role]}/analysistype/show/${id}`);
              console.log(response);
              setFormData({
                nome: response.nome,
                descricao: response.descricao,
              });
              setIsLoading(false);

              
            } catch (err) {
              console.error('Error ao consultar dados');

            }
          }

          getRegistro(id);
      }, []);

  return (
    <div className='flex flex-col gap-2 mt-1 p-2'> 
    <p className='font-medium'>Atualize o sistema cadastrado</p>
    
    {!isLoading ? (    
      <div className='flex flex-col gap-2 mt-1 p-2'>
        <input
          className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
          id="nome"
          name="nome"
          placeholder="Tipo do Sistema"
          value={formData.nome}
          onChange={handleChange}
          />    
        <input
          className={`px-4 py-2 rounded border border-gray-300 focus:outline-none`}
          id="descricao"
          name="descricao"
          placeholder="Descrição"
          value={formData.descricao}
          onChange={handleChange}
          />
        <div className='w-full flex flex-row-reverse pt-2'>
          <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleSubmit}>Atualizar</button>
          {msg ? (
            <div className='flex justify-end p-1'>
              <Alert severity="info" sx={{ width: 320 }}>{msg}</Alert>
            </div>
          ) : null}
        </div>      
      </div>
        ) : (
          <div className='p-4 flex justify-center align-middle items-center h-[100px] w-full'>
            <CircularProgress color="success"/>
          </div>
        )}

    </div>
  )
}

export default EditarSistema
