import React, { useState, useEffect } from 'react';
import { GenGet } from 'Admin/state/api';
import { PDFViewer } from '@react-pdf/renderer';
import Laudo from './Laudo';

function Relatorio({role, id}) {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const roleMapping = {
        eng: 'eng',
        lab: 'lab',
        admin: 'admin',
        customer : 'customer'
      };
  
    useEffect(() => {
      async function getAnalise(id) {
        try {
          const response = await GenGet(`${roleMapping[role]}/completereport/${id}`);
          setData(response);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
  
      if (role && id) {
          getAnalise(id);
      }
      
    }, [id]);
  return (
    <div className='flex flex-col gap-2 mt-1 p-2'>
        <h3 className='text-[14pt]'>Visusalização de Laudo</h3>
        <p className='text-[9pt] -mt-2'>visualize o Laudo selecionado.</p>
        <hr/>
        {!isLoading && data && (
            <div>
                {/* Visualização do PDF na Tela */}
                <h2>Visualizar PDF:</h2>
                <PDFViewer width={'100%'} height={1200}>
                    <Laudo data={data} />
                </PDFViewer>
            </div>
        )}
    </div>
  )
}

export default Relatorio
