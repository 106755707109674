import React from 'react';
import Artigo from 'Loja/components/compLoja/Artigo';
import ImgArtigo1 from '../../assets/artigo1.jpg';
import ImgArtigo2 from '../../assets/artigo2.jpg';
import ImgArtigo3 from '../../assets/artigo3.jpg';

function Artigos() {
  return (
    <div className="flex flex-wrap gap-1 justify-center w-full z-10 py-5">
      <div className='flex flex-grow justify-center'>
      <Artigo 
        titulo={'Nossa Estrutura'}
        conteudo={'Nosso moderno laboratório, somado à nossa rede de laboratórios credenciados, garante maior confiabilidade e metodologia adequada para resultados precisos.'} 
        endpoint={'/sobre'} 
        imagem={ImgArtigo1}
      />
      </div>
      <div className='flex flex-grow justify-center'>
      <Artigo 
        titulo={'Suporte e Tecnologia'}
        conteudo={'Profissionais equipados e preparados para correções, se necessário, dispõem de equipamentos para monitoramento local, visando maior aproveitamento e eficiência da visita técnica.'} 
        endpoint={'/servicos'} 
        imagem={ImgArtigo2}
      />
      </div>
      <div className='flex flex-grow justify-center'>
      <Artigo 
        titulo={'Gerenciamento'}
        conteudo={'Software excepcional, para atualização de balanço material que combinado a tecnologia dos produtos de última geração propicia operação com ciclos mais elevados, obtendo a relação do melhor custo-benefício.'} 
        endpoint={'/suporte'} 
        imagem={ImgArtigo3}
      />
      </div>



    </div>
  );
}

export default Artigos;
