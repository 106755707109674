import React, {useState, useEffect, createContext} from 'react'
import { CssBaseline} from "@mui/material";
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import Layout from "./Loja/scenes/layout/Index"
import LojaIndex from './Loja/scenes/lojaIndex/Index';
import NovaSenha from 'Loja/scenes/novaSenha/Index';
import EsqueceuSenha from 'Loja/scenes/esqueceuSenha/Index';
import Login from 'Loja/components/compLoja/LoginForms';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Artigos from 'Loja/scenes/artigos/Index';
import Verify from 'Loja/scenes/verify/Index';
import TermosDeUso from 'Loja/scenes/Politicas/TermosDeUso';
import Sobre from 'Loja/scenes/institucional/Sobre';
import Contato from 'Loja/scenes/institucional/Contato';
import ArticlePage from 'Loja/components/compLoja/ArticlePage';
import Suporte from 'Loja/scenes/suporte/Suporte';
import Servicos from 'Loja/scenes/institucional/Servicos';
import LoginHub from 'Loja/scenes/login/LoginHub';



const theme = createTheme({
  typography: {
    fontFamily: [
      'Raleway',
      'sans-serif',
    ].join(','),
  },
});

export const EnvContext = createContext();

const token = localStorage.getItem('token');

function SiteRoot() {
  const appUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
    
  return (
    <div className="app">
<ThemeProvider theme={theme}>
        <EnvContext.Provider value={{appUrl}}> 
        <CssBaseline />
        <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<LojaIndex/>} />
                <Route path="/article" element={<ArticlePage />} />
                <Route path='/painelcliente' element={<Login loginType={'customer'}/>}/>
                <Route path='/icbr' element={<LoginHub />}/>    
                <Route path='/login/admin-pannel' element={<Login loginType={'admin'}/>}/>   
                <Route path='/login/engineer' element={<Login loginType={'engineer'}/>}/>   
                <Route path='/login/lab' element={<Login loginType={'lab'}/>}/>           
                <Route path="/home" element={<Navigate to="/" replace />} />
                <Route path="/termos" element={<TermosDeUso/>} /> 
                <Route path='/artigos' element={<Artigos/>} /> 
                <Route path="/novasenha" element={<NovaSenha/>} />
                <Route path="/recuperarsenha" element={<EsqueceuSenha/>} />
                <Route path="/novasenha/:token" element={<NovaSenha/>} />
                <Route path='/confirmar-conta/:token' element={<Verify/>}/>
                <Route path="/*" element={<Navigate to="/" replace />} />
                <Route path="/sobre" element={<Sobre/>} />
                <Route path="/servicos" element={<Servicos/>} />
                <Route path="/suporte" element={<Suporte/>} />
                <Route path="/contato" element={<Contato/>} />
            </Route>    
        </Routes>
        </EnvContext.Provider>
        </ThemeProvider>
    </div>
  );
}

export default SiteRoot;

/* 





*/