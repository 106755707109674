import React, { useContext } from 'react';
import { EnvContext } from 'Site';
import { useNavigate } from 'react-router-dom';

export default function Artigo({
  titulo,
  conteudo,
  endpoint,
  imagem,
}) {
  const { appUrl } = useContext(EnvContext);
  const nav = useNavigate();

  return (
    <div className="flex flex-col items-start p-3 rounded-md min-h-[470px] relative border-[1px] shadow-lg bg-white">
      <img src={imagem} className="w-full rounded-md h-auto max-w-[350px] object-cover mb-4 text-[#0b152fff]" alt={titulo} />
      <h3 className="max-w-[350px] text-left font-semibold mb-2 text-main-blue">{titulo}</h3>
      <p className="max-w-[350px] text-left mb-4 text-main-black">{conteudo}</p>
      <button 
        className="text-blue-500 hover:underline text-left absolute bottom-0"
        onClick={() => nav(endpoint)}
      >
        <p className='p-0 m-0 font-extrabold text-[16pt]'>SAIBA MAIS</p>
      </button>
    </div>
  );
};
