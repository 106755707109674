import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginHubCard({
  titulo,
  conteudo,
  endpoint,
  imagem,
}) {

  const nav = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center align-middle aspect-square rounded-full p-10 min-h-[280px] min-w-[280px] relative border-[1px] shadow-lg bg-white">
      <h3 className="max-w-[350px] text-left font-semibold mb-2 text-main-blue">{titulo}</h3>
      <p className="max-w-[350px] text-left mb-4 text-main-black">{conteudo}</p>
      <button 
        className="text-blue-500 hover:underline text-left absolute bottom-0 m-4"
        onClick={() => nav(endpoint)}
      >
        <p className='p-0 m-0 font-extrabold text-[16pt]'>Entrar</p>
      </button>
    </div>
  );
};