import React, { useState, useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import LoginForm from './LoginForm';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useLogout from 'hooks/useLogout';
import {
  Box,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Popover // Import Popover
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import axios from "Loja/state/axios";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from "Site";

import Img from '../../assets/logo.png';
const token = localStorage.getItem('token');
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  icon: {
    "&:hover": {
      color: "#feb236",
      fontSize: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function NavBar() {
  const isSmScreen = useMediaQuery('(max-width:780px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const token = localStorage.getItem('token');
  const logout = useLogout();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorCartEl, setAnchorCartEl] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  
  // References for detecting clicks outside
  const menuRef = useRef(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const handleClickLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseNav = (link) => {
    setOpenLogin(false);
    navigate(link);
  };

  const handleLink = (link) => {
    setAnchorEl(null);
    navigate(link);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
        handleCloseMenu();
      }
    };
  
    document.addEventListener('click', handleClickOutside); // Change to 'click'
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl, anchorCartEl]);
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDropdownOpen(false);
  };

  return (
    <Box className='sticky-top' sx={{ width: "100%", height: "50px", boxSizing: "border-box" }}>
      <Dialog open={openLogin} onClose={handleCloseLogin}>
        <DialogContent>
          <LoginForm onClose={handleCloseNav} />
        </DialogContent>
      </Dialog>
      <Navbar className='bg-dark-blue text-light-white' expand="md">
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Inicio" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/sobre'); setIsDrawerOpen(false) }}>
                <ListItemText primary="A Empresa" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/servicos'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Serviços" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/suporte'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Suporte" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/contato'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Contato" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <div className='w-full p-1 px-4'>
          <div className='flex flex-row justify-between'>
            <div className='flex gap-2'>
              {isSmScreen && (
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                {isDrawerOpen ? <CloseIcon className='text-main-white'/> : <MenuIcon className='text-main-white'/>}
              </IconButton>
              )}
              <img 
                  src={Img} 
                  width={100} 
                  style={{ height: 'auto', objectFit: 'contain' }} 
                  onClick={() => { navigate('/') }} 
                  className='cursor-pointer' 
                />
              </div>
            <div className='flex flex-row justify-center flex-grow'>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ width: "100%", justifyContent:"center" }}>
                  <Nav.Link onClick={() => { navigate('/sobre') }}><p className='text-[12pt] p-0 m-0 font-semibold text-main-white hover:text-white hover:underline'>A Empresa</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/servicos') }}><p className='text-[12pt] p-0 m-0 font-semibold text-main-white hover:text-white hover:underline'>Serviços</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/suporte') }}><p className='text-[12pt] p-0 m-0 font-semibold text-main-white hover:text-white hover:underline'>Suporte</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/contato') }}><p className='text-[12pt] p-0 m-0 font-semibold text-main-white hover:text-white hover:underline'>Contato</p></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
            <div className="relative">
              <button
                className="text-[10pt] font-semibold shadow-md m-0 cursor-pointer bg-slate-50 p-2 rounded-full hover:text-gray-400"
                onClick={toggleDropdown}
              >
                YARA XXI
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-[180px] bg-white shadow-lg rounded-lg" >
                  <ul className="text-black">
                    <li
                      className="px-1 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleNavigation('/painelcliente')}
                    >
                      Área de cliente
                    </li>
                    <li
                      className="px-1 py-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleNavigation('/icbr')}
                    >
                      ICBR
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </Navbar>
    </Box>
  );
}

export default NavBar;
