import React from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import Clientes from 'Admin/scenes/clientes/Index';
import Mensagens from 'Admin/scenes/mensagens/Index';
import Avisos from 'Admin/scenes/avisos/Index';
import CustomerPannel from 'Admin/scenes/newPannel/CustomerPannel';
import Laudos from 'Admin/scenes/laudos/Laudos';
import LaudosClientes from 'Admin/scenes/customer_components/LaudosClientes';


function CustomerRoot() {

  return (
        <div>
          <Routes>
            <Route element={<CustomerPannel />}>
              <Route exact path="/" element={<Admin />} />                        
              <Route path="/avisos" element={<Avisos />} />
              <Route path="/mensagens" element={<Mensagens />} />
                
              <Route path="/perfil" element={<Clientes />} />            
              <Route path="/laudos" element={<LaudosClientes role={'customer'} />} />  
              <Route path="/*" element={<Navigate to="/customer" replace />} />
            </Route>
          </Routes>
    </div>
  );
}

export default CustomerRoot;